import { useToast } from "primevue/usetoast";

export const useFeedbackToast = () => {
  const toast = useToast();
  const useSuccessToast = (summary?: string, detail?: string) => {
    toast.add({
      severity: "success",
      summary,
      detail,
      life: 5000,
    });
  };
  const useErrorToast = (summary?: string, detail?: string) => {
    toast.add({
      severity: "error",
      summary,
      detail,
    });
  };
  return { useSuccessToast, useErrorToast };
};
