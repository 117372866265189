<script setup lang="ts">
import { useMealAttributesStore } from "@/stores/MealAttributesStore";
import { computed, ref, inject } from "vue";
import { useConfirm } from "primevue/useconfirm";
import { useFeedbackToast } from "@/composables/useFeedbackToast";
import MealAttributesForm from "../MealAttributesForm.vue";
import type { MealAttributeDto } from "@/services/MealAttributesService";
import { useI18n } from "vue-i18n";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import { useRouter } from "vue-router";

const props = defineProps<{
  mealAttribute: MealAttributeDto;
}>();

const mealAttributesStore = useMealAttributesStore();

const { t } = useI18n();
const confirm = useConfirm();
const router = useRouter();
const { useSuccessToast, useErrorToast } = useFeedbackToast();
const resetSelectedMealAttribute = inject(
  "resetSelectedMealAttribute",
  () => {},
);
const showDialog = ref(false);

const MEAL_ATTRIBUTE = computed(() => props.mealAttribute);

const UPDATE_MEAL_ATTRIBUTE_LOADING = computed(
  () => mealAttributesStore.$state.loading.UPDATE_MEAL_ATTRIBUTE,
);

const UPDATE_MEAL_ATTRIBUTE = async () => {
  try {
    await mealAttributesStore.UPDATE_MEAL_ATTRIBUTE(MEAL_ATTRIBUTE.value);
    useSuccessToast(t("update_", { item: t("meal_attribute") }));
  } catch (error) {
    useErrorToast(t("update_", { item: t("meal_attribute") }));
  }
};

const confirmDeleteMealAttribute = () => {
  confirm.require({
    message: t("delete_confirm_"),
    header: t("delete_", { item: t("meal_attribute") }),
    rejectLabel: t("cancel"),
    acceptLabel: t("delete"),
    acceptClass: "bg-red-700 hover:bg-red-600 ring-red-700 focus:ring-red-700",
    accept: () => {
      DELETE_MEAL_ATTRIBUTE(props.mealAttribute.id as string);
    },
    reject: () => {},
  });
};

const DELETE_MEAL_ATTRIBUTE = async (id: string) => {
  if (!props.mealAttribute) return;
  try {
    await mealAttributesStore.DELETE_MEAL_ATTRIBUTE(id);
    useSuccessToast(t("delete_", { item: t("meal_attribute") }));
    router.push({ name: "MealAttributesView" });
    resetSelectedMealAttribute();
  } catch (error) {
    useErrorToast(t("delete_", { item: t("meal_attribute") }));
  }
};
</script>

<template>
  <div>
    <meal-attributes-form
      :meal-attribute="MEAL_ATTRIBUTE"
      @update:name="MEAL_ATTRIBUTE.name = $event"
      @update:en="MEAL_ATTRIBUTE.en = $event"
      @update:sv="MEAL_ATTRIBUTE.sv = $event"
    />
    <div class="mt-5 flex justify-end font-semibold">
      <Button
        class="mr-5 flex justify-center bg-red-700"
        severity="danger"
        @click="confirmDeleteMealAttribute"
      >
        <div class="flex justify-center">
          <span>{{ t("delete") }}</span>
        </div>
      </Button>

      <Button
        class="flex justify-center"
        :loading="UPDATE_MEAL_ATTRIBUTE_LOADING"
        @click="UPDATE_MEAL_ATTRIBUTE"
      >
        <div class="flex justify-center">
          <span>{{ t("save") }}</span>
        </div>
      </Button>
    </div>
    <Dialog v-model:visible="showDialog" modal closable dismissable-mask>
      <template #header>
        <h1 class="font-bold text-slate-800">
          {{ $t("delete_", { item: $t("meal_attribute") }) }}
        </h1>
      </template>
      <div>
        <span class="block text-sm font-semibold text-slate-600">
          {{ $t("delete_confirm_") }}
        </span>
      </div>
      <div class="mt-5 flex justify-end">
        <Button
          class="mr-5 flex justify-center"
          severity="secondary"
          outlined
          @click="showDialog = false"
        >
          <div class="flex justify-center font-semibold">
            <span>{{ $t("cancel") }}</span>
          </div>
        </Button>
        <Button
          class="flex justify-center bg-red-700"
          severity="danger"
          @click="
            () => {
              DELETE_MEAL_ATTRIBUTE(props.mealAttribute.id as string);
              showDialog = false;
            }
          "
        >
          <div class="flex justify-center font-semibold">
            <span>{{ $t("delete") }}</span>
          </div>
        </Button>
      </div>
    </Dialog>
  </div>
</template>
