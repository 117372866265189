<script setup lang="ts">
import { reactive, onMounted, computed, ref, watch, provide } from "vue";
import { useRoute, useRouter } from "vue-router";
import SchoolDetail from "./SchoolDetail.vue";
import SchoolForm from "./SchoolForm.vue";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import Column from "primevue/column";
import Button from "primevue/button";
import { Role, type District, type School } from "@/api/prisma-interfaces";
import { useSchoolStore } from "@/stores/SchoolStore";
import type { SchoolDto } from "@/services/SchoolService";
import { useDistrictStore } from "@/stores/DistrictStore";
import SearchField from "@/components/shared/SearchField.vue";
import SelectSearchField from "@/components/shared/SelectSearchField.vue";
import { useFeedbackToast } from "@/composables/useFeedbackToast";
import { useUserStore } from "@/stores/UserStore";
import { useI18n } from "vue-i18n";

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const schoolStore = useSchoolStore();
const districtStore = useDistrictStore();
const userStore = useUserStore();

const { useSuccessToast, useErrorToast } = useFeedbackToast();

onMounted(async () => {
  await schoolStore.FETCH_SCHOOLS();
  schools.value = schoolStore.SCHOOLS;

  if (route.params.id) {
    SELECTED_SCHOOL.value =
      (await schoolStore.GET_SCHOOL(route.params.id as string)) || undefined;
  } else {
    SELECTED_SCHOOL.value = undefined;
  }
  districtStore.FETCH_DISTRICTS();
});

const state = reactive({
  showForm: false,
});

const SELECTED_SCHOOL = ref<School>();
const SELECTED_DISTRICT = ref<District>();
const usePaginator = ref(true);

watch(
  () => route.params.id as string,
  async (id) => {
    if (route.name !== "SchoolDetail") return;
    if (id) {
      SELECTED_SCHOOL.value = (await schoolStore.GET_SCHOOL(id)) || undefined;
    } else {
      SELECTED_SCHOOL.value = undefined;
    }
  },
);

watch(SELECTED_DISTRICT, async (newValue) => {
  const district = newValue;
  if (district?.id) {
    usePaginator.value = false;
    const newSchools = await schoolStore.GET_SCHOOLS_BY_DISTRICT_ID(
      district.id,
    );
    if (newSchools) {
      schools.value = newSchools;
    }
  } else {
    usePaginator.value = true;
    schools.value = schoolStore.SCHOOLS;
  }
});

const SCHOOLS = computed(() => {
  return schoolStore.SCHOOLS;
});

const LOADING_SCHOOLS = computed(() => {
  return schoolStore.$state.loading.FETCH_SCHOOLS;
});

function toggleModal() {
  state.showForm = !state.showForm;
}

const NEW_SCHOOL = reactive<SchoolDto>({
  name: "",
  districtId: "",
  menuId: "",
  visible: true,
  urlName: "",
  imageUrl: null,
  image: null,
  customerId: null,
  istSchoolId: null,
});

function resetNewSchool() {
  Object.assign(NEW_SCHOOL, {
    name: "",
    districtId: "",
    menuId: "",
    visible: true,
    urlName: "",
    imageUrl: null,
    image: null,
    customerId: null,
    istSchoolId: null,
  });
}

const CREATE_SCHOOL = async () => {
  const trimmedName = NEW_SCHOOL.name.trim();
  if (!trimmedName || !NEW_SCHOOL.districtId || !NEW_SCHOOL.menuId) {
    const detail = [];
    if (!trimmedName) {
      detail.push(t("name"));
    }
    if (!NEW_SCHOOL.districtId) {
      detail.push(t("district"));
    }
    if (!NEW_SCHOOL.menuId) {
      detail.push(t("menu"));
    }
    useErrorToast(
      t("create_", { item: t("school") }),
      t("empty_field_", { item: detail.join(", ") }),
    );
    return;
  }
  try {
    const s = await schoolStore.CREATE_SCHOOL({
      ...NEW_SCHOOL,
      name: trimmedName,
    });
    if (s) {
      toggleModal();
      resetNewSchool();
      useSuccessToast(t("create_", { item: t("school") }));
      router.push(`/admin/school/${s.id}`);
    }
  } catch (error) {
    useErrorToast(t("create_", { item: t("school") }));
  }
};

const CREATE_SCHOOL_LOADING = computed(
  () => schoolStore.$state.loading.CREATE_SCHOOL,
);

const first = ref(0);
const schools = ref(SCHOOLS.value);

const onPage = async (event: { first: number; rows: number }) => {
  await schoolStore.FETCH_SCHOOLS(event.rows, event.first);
  first.value = event.first;
  schools.value = schoolStore.SCHOOLS;
};

const resetSelectedSchool = () => (SELECTED_SCHOOL.value = undefined);
provide("resetSelectedSchool", resetSelectedSchool);
</script>

<template>
  <div class="flex flex-col">
    <div
      class="flex flex-row flex-wrap items-end justify-between gap-2 rounded-t-lg border border-slate-200 bg-gray-50 p-5"
    >
      <div class="flex flex-col gap-2 sm:flex-row">
        <div class="flex flex-col">
          <span class="pl-2 text-sm font-semibold">{{ t("school") }}</span>
          <search-field
            item-type="school"
            class="rounded-md border border-surface-300"
          />
        </div>
        <Divider class="hidden sm:inline-block" layout="vertical" />
        <div class="flex flex-col">
          <span class="pl-2 text-sm font-semibold">{{ t("district") }}</span>
          <select-search-field
            v-model="SELECTED_DISTRICT"
            :search-items="districtStore.districts"
            item-type="district"
          />
        </div>
      </div>
      <div class="flex justify-end">
        <div class="flex items-end">
          <Button
            v-if="
              (userStore.CURRENT_USER?.canEditSchoolsAndMenus &&
                userStore.CURRENT_USER?.Districts &&
                userStore.CURRENT_USER.Districts.length > 0) ||
              userStore.CURRENT_USER?.role === Role.superAdmin
            "
            class="flex justify-center font-semibold"
            @click="state.showForm = true"
          >
            <div class="flex justify-center">
              <span>{{ t("new_school") }}</span>
            </div>
          </Button>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-row">
    <div class="w-1/3 rounded-bl-lg border border-t-0 border-slate-200">
      <data-table
        :value="schools"
        lazy
        data-key="id"
        :paginator="usePaginator"
        paginator-template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        scrollable
        :scroll-height="'calc(100vh - 220px)'"
        :loading="LOADING_SCHOOLS"
        :selection="SELECTED_SCHOOL"
        :rows="usePaginator ? 20 : undefined"
        :first="usePaginator ? first : 0"
        :total-records="schoolStore.count"
        :row-class="
          ({ id }) => [
            'cursor-pointer',
            'hover:bg-gray-100',
            id === SELECTED_SCHOOL?.id
              ? ' border-l-[3px] border-l-slate-800 flex flex-col bg-gray-50'
              : '',
          ]
        "
        :row-hover="true"
        @page="onPage($event)"
        @row-click="(e) => $router.push(`/admin/school/${e.data.id}`)"
      >
        <column
          field="name"
          :header="t('school', 2)"
          :header-style="{ fontWeight: 'bold', paddingTop: '18px' }"
          class="px-6"
        />
      </data-table>
    </div>
    <div
      class="flex w-full rounded-br-lg border border-l-0 border-t-0 border-slate-200"
    >
      <school-detail v-if="SELECTED_SCHOOL" :school="SELECTED_SCHOOL" />
      <div v-else class="mt-5 flex h-full w-full justify-center">
        <span class="text-gray-500">{{ t("no_school_selected") }}</span>
      </div>
    </div>
  </div>

  <Dialog v-model:visible="state.showForm" modal closable dismissable-mask>
    <template #header>
      <h1 class="font-bold text-slate-800">{{ t("new_school") }}</h1></template
    >
    <div>
      <span class="block text-sm text-slate-600">{{ t("fill_in_form") }}</span>
      <school-form
        :school="NEW_SCHOOL"
        create
        class="mt-5"
        @update:name="NEW_SCHOOL.name = $event"
        @update:selected-district-id="NEW_SCHOOL.districtId = $event"
        @update:selected-menu-id="NEW_SCHOOL.menuId = $event"
        @update:visible="NEW_SCHOOL.visible = $event"
        @update:customer-id="NEW_SCHOOL.customerId = $event"
        @update:ist-school-id="NEW_SCHOOL.istSchoolId = $event"
      />

      <div class="mt-5 flex justify-end">
        <Button
          class="mr-5 flex justify-center font-semibold"
          severity="secondary"
          outlined
          @click="toggleModal"
        >
          <div class="flex justify-center">
            <span>{{ t("cancel") }}</span>
          </div>
        </Button>

        <Button
          class="flex justify-center font-semibold"
          :loading="CREATE_SCHOOL_LOADING"
          @click="CREATE_SCHOOL"
        >
          <div class="flex justify-center">
            <span>{{ t("save") }}</span>
          </div>
        </Button>
      </div>
    </div>
  </Dialog>
</template>
