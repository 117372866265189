<script setup lang="ts">
import Button from "primevue/button";
import Divider from "primevue/divider";
import { useConfirm } from "primevue/useconfirm";
import { CloudArrowUpIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { PencilIcon } from "@heroicons/vue/24/solid";
import type { District } from "@/api/prisma-interfaces";
import { computed, ref } from "vue";
import { useDistrictStore } from "@/stores/DistrictStore";
import { buffToBase64 } from "@/utils/ImageUtils";
import { useFeedbackToast } from "@/composables/useFeedbackToast";
import { useI18n } from "vue-i18n";
import { watch } from "vue";

const props = defineProps<{
  district?: District;
}>();
const { t } = useI18n();
const imgBase64 = ref();
watch(
  () => props.district,
  () => {
    imgBase64.value = props.district?.image
      ? buffToBase64(props.district.image.data)
      : undefined;
  },
  { immediate: true },
);
const imgFile = ref();
const disableSave = ref(true);

const { useSuccessToast, useErrorToast } = useFeedbackToast();

const districtStore = useDistrictStore();
const confirm = useConfirm();

const IMAGE_LOADING = computed(
  () => districtStore.$state.loading.UPDATE_DISTRICT_IMAGE,
);

const setRefImg = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const reader = new FileReader();
  reader.onload = (e) => {
    if (e.target) {
      imgBase64.value = e.target.result as string;
    }
  };
  if (target.files) {
    imgFile.value = target.files[0];
    reader.readAsDataURL(target.files[0]);
  }
};

const handleDragOver = (event: DragEvent) => {
  event.preventDefault();
};

const handleImageDrop = (event: DragEvent) => {
  event.preventDefault();
  if (event.dataTransfer) {
    const file = event.dataTransfer.files[0];
    imgFile.value = file;
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target) {
        imgBase64.value = e.target.result as string;
      }
    };
    reader.readAsDataURL(file);
  }
};

const SAVE_IMAGE = async () => {
  if (props.district) {
    const formData = new FormData();
    if (imgBase64.value) {
      formData.append("image", imgFile.value);
    }
    try {
      const newImage = await districtStore.UPDATE_DISTRICT_IMAGE(
        props.district.id,
        formData,
      );
      if (newImage) {
        imgBase64.value = buffToBase64(newImage.data);
        disableSave.value = true;
        useSuccessToast(
          t("update_", { item: t("district") + " " + t("image") }),
        );
      }
    } catch (error) {
      useErrorToast(t("update_", { item: t("district") + " " + t("image") }));
    }
  }
};

const REMOVE_IMAGE = async () => {
  if (props.district) {
    const formData = new FormData();
    formData.append("image", "");
    try {
      await districtStore.UPDATE_DISTRICT_IMAGE(props.district.id, formData);
      disableSave.value = true;
      imgBase64.value = undefined;
      useSuccessToast(t("delete_", { item: t("district") + " " + t("image") }));
    } catch (error) {
      useErrorToast(t("delete_", { item: t("district") + " " + t("image") }));
    }
  }
};

const confirmDeleteImage = () => {
  confirm.require({
    message: t("delete_confirm_", { item: t("this_image") }),
    header: t("delete_", { item: t("image") }),
    rejectLabel: t("cancel"),
    acceptLabel: t("delete"),
    acceptClass: "bg-red-700 hover:bg-red-600 ring-red-700 focus:ring-red-700",
    accept: () => {
      REMOVE_IMAGE();
    },
    reject: () => {},
  });
};
</script>

<template>
  <div class="pb-2">
    <div v-if="imgBase64" class="grid justify-items-center">
      <div>
        <div class="mb-2 flex justify-end">
          <Button
            class="mr-2 flex h-9 justify-center"
            rounded
            @click="confirmDeleteImage"
          >
            <template #icon>
              <XMarkIcon class="w-4" />
            </template>
          </Button>
          <Button
            class="flex h-9 justify-center"
            rounded
            @click="($refs.fileInput as HTMLInputElement).click()"
          >
            <template #icon>
              <PencilIcon class="w-4" />
            </template>
          </Button>
          <input
            ref="fileInput"
            type="file"
            accept="image/jpeg, image/png, image/webp"
            class="hidden"
            @change="setRefImg"
          />
        </div>
        <img
          class="h-auto max-w-prose rounded-lg"
          :src="imgBase64"
          alt="district"
        />
      </div>
    </div>
    <div v-else class="grid justify-items-center">
      <div
        class="relative flex w-full justify-center rounded-lg border border-dashed border-gray-400 bg-gray-50 py-9"
        @dragover="handleDragOver"
        @drop="handleImageDrop"
      >
        <div class="flex flex-col items-center self-center">
          <CloudArrowUpIcon class="h-14 w-14 text-slate-300" />
          <span class="text-sm font-semibold text-slate-800">
            <a
              class="cursor-pointer text-blue-600 underline"
              @click="($refs.fileInput as HTMLInputElement).click()"
              >{{ t("click_to_upload") }}</a
            >
            {{ t("or") }} {{ t("drag_image_here") }}.</span
          >
        </div>
        <input
          ref="fileInput"
          type="file"
          accept="image/jpeg, image/png, image/webp"
          class="hidden"
          @change="setRefImg"
        />
      </div>
    </div>
  </div>
  <Divider />
  <div class="flex justify-end">
    <Button
      class="flex justify-center"
      :loading="IMAGE_LOADING"
      :disabled="!imgBase64 && disableSave"
      @click="SAVE_IMAGE"
    >
      <div class="flex justify-center font-semibold">
        <span>{{ t("save") }}</span>
      </div>
    </Button>
  </div>
</template>
