<script setup lang="ts">
import { useProvinceStore } from "@/stores/ProvinceStore";
import { computed, ref, watch, inject } from "vue";
import ProvinceForm from "../ProvinceForm.vue";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import { useConfirm } from "primevue/useconfirm";
import type { Province } from "@/api/prisma-interfaces";
import { useFeedbackToast } from "@/composables/useFeedbackToast";
import { useRouter } from "vue-router";

const props = defineProps<{
  province: Province;
}>();

const confirm = useConfirm();
const router = useRouter();
const { useSuccessToast, useErrorToast } = useFeedbackToast();
const showDialog = ref(false);

const provinceStore = useProvinceStore();
const resetSelectedProvince = inject("resetSelectedProvince", () => {});

let provinceName = ref("");

watch(
  () => props.province.id,
  () => {
    provinceName.value = props.province.name;
  },
  {
    immediate: true,
  },
);

const UPDATE_PROVINCE = async () => {
  try {
    await provinceStore.UPDATE_PROVINCE({
      ...props.province,
      name: provinceName.value,
    });
    useSuccessToast("Update province");
  } catch (error) {
    useErrorToast("Update province");
  }
};

const DELETE_PROVINCE = async () => {
  if (!props.province.id) return;
  try {
    await provinceStore.DELETE_PROVINCE(props.province.id);
    useSuccessToast("Delete province");
    router.push({ name: "ProvinceView" });
    resetSelectedProvince();
  } catch (error) {
    useErrorToast("Delete province");
  }
};

const UPDATE_PROVINCE_LOADING = computed(
  () => provinceStore.$state.loading.UPDATE_PROVINCE,
);

const DELETE_PROVINCE_LOADING = computed(
  () => provinceStore.$state.loading.DELETE_PROVINCE,
);

const confirmDeleteProvince = () => {
  confirm.require({
    message: "Are you sure you want to delete this province?",
    header: "Delete district",
    rejectLabel: "Cancel",
    acceptLabel: "Delete",
    acceptClass: "bg-red-700 ring-red-700",
    accept: () => {
      DELETE_PROVINCE();
    },
    reject: () => {},
  });
};
</script>
<template>
  <province-form v-model:name="provinceName" />

  <Divider />
  <div class="mt-2 flex justify-end">
    <div class="flex gap-5">
      <Button
        class="bg-red-700"
        severity="danger"
        :loading="DELETE_PROVINCE_LOADING"
        @click="
          () => {
            if (province.Districts && province.Districts.length > 0) {
              showDialog = true;
            } else {
              confirmDeleteProvince();
            }
          }
        "
      >
        <div class="flex justify-center font-semibold">
          <span>{{ $t("delete") }}</span>
        </div>
      </Button>

      <Button :loading="UPDATE_PROVINCE_LOADING" @click="UPDATE_PROVINCE">
        <div class="flex justify-center font-semibold">
          <span>{{ $t("save") }}</span>
        </div>
      </Button>
    </div>
  </div>

  <Dialog v-model:visible="showDialog" modal closable dismissable-mask>
    <template #header>
      <h1 class="font-bold text-slate-800">
        {{ $t("delete_", { item: $t("province") }) }}
      </h1>
    </template>
    <div>
      <span class="block text-sm font-semibold text-slate-600">
        {{
          $t("_contains_", {
            item: province.name,
            amount: province.Districts?.length,
          }) +
          " " +
          $t("district", province.Districts?.length === 1 ? 1 : 2)
        }}
      </span>
      <span class="mt-2 block text-sm text-slate-600">
        {{
          $t("warn_delete_connected_data", {
            item: province.name,
            connectedItem: $t("district", 2),
          })
        }}
      </span>

      <div class="mt-5 flex justify-end">
        <Button
          class="mr-5 flex justify-center"
          severity="secondary"
          outlined
          @click="showDialog = false"
        >
          <div class="flex justify-center font-semibold">
            <span>{{ $t("cancel") }}</span>
          </div>
        </Button>

        <Button
          class="flex justify-center bg-red-700"
          severity="danger"
          @click="
            () => {
              DELETE_PROVINCE();
              showDialog = false;
            }
          "
        >
          <div class="flex justify-center font-semibold">
            <span>{{ $t("delete") }}</span>
          </div>
        </Button>
      </div>
    </div>
  </Dialog>
</template>
