<script lang="ts" setup>
import { ref, watch } from "vue";
import type { ConnectedApp } from "@/api/prisma-interfaces";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import { useRoute } from "vue-router";
import { useConnectedAppStore } from "@/stores/ConnectedAppStore";
import ConnectedAppDetailGeneral from "./Tabs/ConnectedAppDetailGeneral.vue";

const route = useRoute();
const connectedAppStore = useConnectedAppStore();

defineProps<{
  connectedApp: ConnectedApp;
}>();

const CONNECTED_APP = ref<ConnectedApp | null>(null);

watch(
  route,
  async (to) => {
    if (to.name !== "ConnectedAppDetail") return;
    const connectedApp = await connectedAppStore.GET_CONNECTED_APP(
      to.params.id as string,
    );
    CONNECTED_APP.value = connectedApp || null;
  },
  { immediate: true },
);
</script>

<template>
  <div v-if="CONNECTED_APP" class="w-full">
    <TabView class="w-full">
      <TabPanel>
        <template #header>
          <span class="text-sm font-semibold text-slate-800">{{
            $t("general")
          }}</span>
        </template>
        <connected-app-detail-general :connected-app="CONNECTED_APP" />
      </TabPanel>
    </TabView>
  </div>
</template>
