<script setup lang="ts">
import DataTable, { type DataTableRowClickEvent } from "primevue/datatable";
import Column from "primevue/column";
import type { User } from "@/api/prisma-interfaces";
import { computed } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const props = defineProps<{
  user: User;
}>();

const DISTRICTS = computed(() => props.user.Districts);
const SCHOOLS = computed(() => props.user.Schools);
const MENUS = computed(() => props.user.Menus);

const hasConnections = computed(() => {
  return (
    DISTRICTS.value?.length || SCHOOLS.value?.length || MENUS.value?.length
  );
});

function routeToDistrict(event: DataTableRowClickEvent) {
  router.push("/admin/district/" + event.data.id);
}

function routeToSchool(event: DataTableRowClickEvent) {
  router.push("/admin/school/" + event.data.id);
}

function routeToMenu(event: DataTableRowClickEvent) {
  router.push("/admin/menu/" + event.data.id);
}
</script>

<template>
  <div v-if="hasConnections">
    <data-table
      v-if="DISTRICTS && DISTRICTS.length > 0"
      :value="DISTRICTS"
      :row-class="() => 'cursor-pointer'"
      @row-click="routeToDistrict"
    >
      <Column
        field="name"
        :header="$t('district', 2)"
        sortable
        header-style="background-color: #F9FAFB"
        class="border-b-slate-200"
      />
    </data-table>

    <data-table
      v-if="SCHOOLS && SCHOOLS.length > 0"
      :value="SCHOOLS"
      :row-class="() => 'cursor-pointer'"
      @row-click="routeToSchool"
    >
      <Column
        field="name"
        :header="$t('school', 2)"
        sortable
        header-style="background-color: #F9FAFB"
        class="border-b-slate-200"
      />
    </data-table>

    <data-table
      v-if="MENUS && MENUS.length > 0"
      :value="MENUS"
      :row-class="() => 'cursor-pointer'"
      @row-click="routeToMenu"
    >
      <Column
        field="name"
        :header="$t('menu', 2)"
        sortable
        header-style="background-color: #F9FAFB"
        class="border-b-slate-200"
      />
    </data-table>
  </div>
  <div v-else>
    <p class="block font-medium leading-6 text-gray-900">
      {{ $t("no_connections_found") }}
    </p>
  </div>
</template>
