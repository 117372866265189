<script setup lang="ts">
import OverlayPanel from "primevue/overlaypanel";
import { EllipsisVerticalIcon } from "@heroicons/vue/20/solid";
import { computed, ref, toRef } from "vue";
import { useMealAttributesStore } from "@/stores/MealAttributesStore";
import MealAttributeBubble from "./MealAttributeBubble.vue";
import type { MealAttribute } from "@/api/prisma-interfaces";
import { useI18n } from "vue-i18n";

const { locale, t } = useI18n();

const props = defineProps<{
  mealAttributes: MealAttribute[];
}>();

const emit = defineEmits<{
  (event: "update:mealAttributes", value: MealAttribute[]): void;
}>();

const attributeIdsRef = toRef(props, "mealAttributes");

const op = ref<OverlayPanel>();

const toggle = (event: MouseEvent) => {
  op.value?.toggle(event);
};

const mealAttributesStore = useMealAttributesStore();

const MEAL_ATTRIBUTES = computed(() => {
  return mealAttributesStore.mealAttributes;
});

const selectFromOverlay = (attribute: MealAttribute) => {
  if (attributeIdsRef.value.some((attr) => attr.id === attribute.id)) {
    attributeIdsRef.value.splice(attributeIdsRef.value.indexOf(attribute), 1);
  } else {
    attributeIdsRef.value.push(attribute);
  }
  emit("update:mealAttributes", attributeIdsRef.value);
};
</script>

<template>
  <div :key="attributeIdsRef.join(',')">
    <div class="min-w-18 flex flex-row-reverse flex-wrap">
      <button
        v-tooltip.top="t('pick_attribute')"
        style="min-height: 30px"
        @click="toggle"
      >
        <EllipsisVerticalIcon
          class="mx-2 h-5 w-5 place-self-end text-slate-800"
        />
      </button>
    </div>
    <OverlayPanel ref="op">
      <div class="grid grid-cols-7 gap-2">
        <div v-for="attribute in MEAL_ATTRIBUTES" :key="attribute.id">
          <MealAttributeBubble
            v-tooltip.top="
              locale === 'sv'
                ? attribute.sv || attribute.name
                : attribute.en || attribute.name
            "
            small
            :class="{
              grayscale: !attributeIdsRef.some(
                (attr) => attr.id === attribute.id,
              ),
            }"
            class="hover:grayscale-0"
            :name="
              locale === 'sv'
                ? attribute.sv || attribute.name
                : attribute.en || attribute.name
            "
            :img-buf="attribute.image?.data as Buffer"
            @click="selectFromOverlay(attribute)"
          />
        </div>
      </div>
    </OverlayPanel>
  </div>
</template>
