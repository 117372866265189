<script setup lang="ts">
import { useUserStore } from "@/stores/UserStore";
import { reactive, computed, ref } from "vue";
import { useRouter, RouterLink } from "vue-router";
import { useConfirm } from "primevue/useconfirm";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Message from "primevue/message";
import InlineMessage from "primevue/inlinemessage";
import * as config from "@/config";
import { Role } from "@/api/prisma-interfaces";

const confirm = useConfirm();
const router = useRouter();

defineEmits(["loginDone", "signupDone"]);

const userStore = useUserStore();

const state = reactive({
  email: "",
  password: "",
  valid: false,
});

const showForgotPassDialog = ref(false);

const login = async () => {
  try {
    const user = await userStore.LOGIN_USER(state.email, state.password);
    if (user?.role === Role.superAdmin) {
      router.replace("/admin/province");
    } else {
      router.replace("/admin/menu");
    }
  } catch (error: any) {
    errorDialog(error);
  }
};

const LOGIN_USER_LOADING = computed(() => userStore.$state.loading.LOGIN_USER);

const errorDialog = (error: any) => {
  confirm.require({
    message: error.message
      ? error.message
      : error.map((e: any) => e.message).join("\n"),
    header: "Error",
    icon: "pi pi-exclamation-triangle",
    rejectClass: "p-button-secondary p-button-outlined",
    rejectLabel: "Cancel",
    acceptLabel: "Ok",
    accept: () => {},
    reject: () => {},
  });
};

const FORGOT_PASSWORD = async () => {
  try {
    await userStore.FORGOT_PASSWORD(state.email);
    showForgotPassDialog.value = false;
  } catch (error: any) {
    errorDialog(error);
  }
};
</script>

<template>
  <div
    class="flex min-h-screen flex-col justify-start bg-gradient-to-r from-[#0b374a] to-[#020618] px-6 lg:px-8"
  >
    <Message class="text-slate-800"> {{ $t("cookie_popup") }}</Message>
    <div class="py-20">
      <div class="flex flex-col items-center justify-center">
        <img
          class="h-24 w-24"
          src="../assets/skolmaten_transparent_background.svg"
          alt="IST Logo"
        />
        <h2
          class="text-center text-2xl font-bold leading-9 tracking-tight text-white"
        >
          {{ $t("log_in_to_your_account") }}
        </h2>
      </div>

      <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form class="space-y-6" @submit.prevent="login">
          <div>
            <label
              for="email"
              class="ml-2 block text-sm font-semibold leading-6 text-white"
              >{{ $t("email") }} *</label
            >
            <div class="align-items-center mb-3 flex flex-wrap gap-2">
              <input
                id="email"
                v-model="state.email"
                name="email"
                type="email"
                autocomplete="email"
                required="true"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-focus sm:text-sm sm:leading-6"
              />
            </div>
            <inline-message
              v-if="state.email && !/.+@.+\..+/.test(state.email)"
              severity="error"
              class="bg-red-600 bg-opacity-20 text-white ring-red-800"
              >{{ $t("invalid_email") }}</inline-message
            >
          </div>

          <div>
            <div class="flex items-center justify-between">
              <label
                for="password"
                class="ml-2 block text-sm font-semibold leading-6 text-white"
                >{{ $t("password") }} *</label
              >
              <div class="text-sm">
                <p
                  class="cursor-pointer font-semibold text-amber-400 hover:text-amber-300"
                  @click="showForgotPassDialog = true"
                >
                  {{ $t("forgot_password") }}?
                </p>
              </div>
            </div>
            <div>
              <input
                id="password"
                v-model="state.password"
                name="password"
                type="password"
                autocomplete="current-password"
                required="true"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-focus sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <Button
              :label="$t('log_in')"
              severity="primary"
              type="submit"
              :loading="LOGIN_USER_LOADING"
              class="flex w-full justify-center rounded-md bg-cyan-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-focus"
              @click="login"
            >
              {{ $t("log_in") }}
            </Button>
          </div>
        </form>
        <p
          v-if="config.nodeEnv !== 'production'"
          class="mt-10 text-center text-sm text-gray-200"
        >
          {{ $t("no_account") }}?
          {{ " " }}
          <router-link
            to="/register"
            replace
            class="font-semibold leading-6 text-amber-400 hover:text-amber-300"
            >{{ $t("register") }}</router-link
          >
        </p>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="showForgotPassDialog" modal dismissable-mask>
    <template #header>
      <h1 class="font-bold text-slate-800">{{ $t("forgot_password") }}</h1>
    </template>
    <span class="text-slate-600">{{ $t("reset_password_intro") }}</span>
    <div class="mt-4">
      <label
        for="email"
        class="ml-2 block text-sm font-semibold leading-6 text-slate-800"
        >{{ $t("email") }} *</label
      >
      <div class="align-items-center mb-3 flex flex-wrap gap-2">
        <input
          id="email"
          v-model="state.email"
          name="email"
          type="email"
          autocomplete="email"
          required="true"
          class="block w-full rounded-md border-0 py-1.5 text-slate-800 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-focus sm:text-sm sm:leading-6"
        />
        <inline-message
          v-if="state.email && !/.+@.+\..+/.test(state.email)"
          severity="error"
          class="bg-red-600 bg-opacity-20 text-red-700 ring-red-800"
          >{{ $t("invalid_email") }}</inline-message
        >
      </div>
    </div>
    <footer class="mt-4 flex justify-end">
      <Button
        :label="$t('cancel')"
        class="mr-2 flex justify-center px-2 py-2 text-slate-800 ring-slate-800 hover:bg-white focus:ring-focus"
        outlined
        @click="showForgotPassDialog = false"
      />
      <Button
        :label="$t('send_reset_link')"
        class="flex justify-center bg-slate-800 px-2 py-2 text-white ring-slate-800 hover:bg-slate-700 hover:ring-slate-700 focus:ring-focus"
        @click="FORGOT_PASSWORD"
      />
    </footer>
  </Dialog>
</template>
