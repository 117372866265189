<script setup lang="ts">
import { useMenuStore } from "@/stores/MenuStore";
import { computed, watch, ref } from "vue";
import MenuDetailsMenu from "./Tabs/MenuDetailsMenu.vue";
import { useRoute } from "vue-router";
import type { Menu } from "@/api/prisma-interfaces";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";

const route = useRoute();
const menuStore = useMenuStore();

defineProps<{
  menu: Menu;
}>();

watch(
  route,
  async (to) => {
    if (to.name !== "MenuDetail") return;
    const menu = await menuStore.GET_MENU(to.params.id as string);
    MENU.value = menu || null;
  },
  { immediate: true },
);

const MENU = ref<Menu | null>(null);

const GET_MENU_LOADING = computed(() => menuStore.loading["GET_MENU"]);
</script>

<template>
  <div v-if="GET_MENU_LOADING">
    <LoadingSpinner />
  </div>
  <div v-else-if="MENU">
    <menu-details-menu :menu="MENU" />
  </div>
</template>
