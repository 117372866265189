import type {
  District,
  Province,
  School,
  Menu,
  User,
  Bulletin,
  MealAttribute,
  UrlName,
} from "@/api/prisma-interfaces";
import HttpClient from "../utils/HttpClient";

const SEARCH_URL = "/search";

export enum SearchResultType {
  District = "district",
  Province = "province",
  School = "school",
  Menu = "menu",
  User = "user",
  Bulletin = "bulletin",
}

export interface SearchResult {
  id: string;
  name: string;
  type: SearchResultType;
  menuId?: string;
  parentName?: string;
}

export interface PublicSearchResult {
  id: string;
  name: string;
  type: SearchResultType;
  urlName?: string;
  urlNames?: UrlName[];
  menuId?: string;
  parentName?: string;
}

class SearchService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient = new HttpClient()) {
    this.httpClient = httpClient;
  }

  async publicSearch(query: string): Promise<PublicSearchResult[]> {
    const response = await this.httpClient.get<PublicSearchResult[]>(
      `${SEARCH_URL}?q=${encodeURIComponent(query)}&take=20`,
    );
    return response;
  }

  async search(query: string): Promise<SearchResult[]> {
    const response = await this.httpClient.get<SearchResult[]>(
      `/admin${SEARCH_URL}?q=${encodeURIComponent(query)}&take=20`,
    );
    return response;
  }

  async searchDistricts(query: string): Promise<District[]> {
    const response = await this.httpClient.get<District[]>(
      `${SEARCH_URL}/district?q=${encodeURIComponent(query)}&take=20`,
    );
    return response;
  }

  async searchProvinces(query: string): Promise<Province[]> {
    const response = await this.httpClient.get<Province[]>(
      `${SEARCH_URL}/province?q=${encodeURIComponent(query)}&take=20`,
    );
    return response;
  }

  async searchSchools(
    query: string,
    districtIds?: string[],
  ): Promise<School[]> {
    const response = await this.httpClient.get<School[]>(
      `${SEARCH_URL}/school?q=${encodeURIComponent(query)}&take=20${
        districtIds ? `&ids=${districtIds.join(",")}` : ""
      }`,
    );
    return response;
  }
  async searchMenus(query: string, schoolIds?: string[]): Promise<Menu[]> {
    const response = await this.httpClient.get<Menu[]>(
      `${SEARCH_URL}/menu?q=${encodeURIComponent(query)}&take=20${
        schoolIds ? `&ids=${schoolIds.join(",")}` : ""
      }`,
    );
    return response;
  }

  async searchUsers(query: string): Promise<User[]> {
    const response = await this.httpClient.get<User[]>(
      `${SEARCH_URL}/user?q=${encodeURIComponent(query)}&take=20`,
    );
    return response;
  }

  async searchBulletins(query: string): Promise<Bulletin[]> {
    const response = await this.httpClient.get<Bulletin[]>(
      `${SEARCH_URL}/bulletin?q=${encodeURIComponent(query)}&take=20`,
    );
    return response;
  }

  async searchConnectedApps(query: string): Promise<SearchResult[]> {
    const response = await this.httpClient.get<SearchResult[]>(
      `${SEARCH_URL}/connected-app?q=${encodeURIComponent(query)}&take=20`,
    );
    return response;
  }

  async searchMealAttributes(query: string): Promise<MealAttribute[]> {
    const response = await this.httpClient.get<MealAttribute[]>(
      `${SEARCH_URL}/meal-attribute?q=${encodeURIComponent(query)}&take=20`,
    );
    return response;
  }
}

export default new SearchService();
