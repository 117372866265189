<script setup lang="ts">
import type { BulletinDto } from "@/services/BulletinService";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import Calendar from "primevue/calendar";
import MultiSelectSearchField from "@/components/shared/MultiSelectSearchField.vue";
import { onMounted } from "vue";
import { useDistrictStore } from "@/stores/DistrictStore";
import { useMenuStore } from "@/stores/MenuStore";
import { useSchoolStore } from "@/stores/SchoolStore";
import type { District, Menu, School } from "@/api/prisma-interfaces";
import { InformationCircleIcon } from "@heroicons/vue/24/outline";

interface BulletinsForm {
  bulletin: BulletinDto;
}

const schoolStore = useSchoolStore();
const menuStore = useMenuStore();
const districtStore = useDistrictStore();

onMounted(() => {
  schoolStore.FETCH_SCHOOLS();
  menuStore.FETCH_MENUS();
  districtStore.FETCH_DISTRICTS();
});

defineProps<BulletinsForm>();

const emit = defineEmits<{
  (event: "update:text", value: string | null): void;
  (event: "update:link", value: string | null): void;
  (event: "update:activate", value: Date | null): void;
  (event: "update:deactivate", value: Date | null): void;
  (event: "update:menus", value: Menu[]): void;
  (event: "update:districts", value: District[]): void;
  (event: "update:schools", value: School[]): void;
}>();
</script>

<template>
  <form>
    <div class="rounded-md bg-slate-100 p-2">
      <span class="block font-semibold text-slate-800"
        >{{ $t("content") }} *</span
      >
      <div class="flex flex-row items-center justify-start gap-1">
        <InformationCircleIcon class="h-4 w-4" />
        <span class="block text-xs text-slate-800">{{
          $t("at_least_one_field")
        }}</span>
      </div>

      <div class="ml-2 mt-2">
        <span class="ml-1 block text-sm font-medium text-slate-800">{{
          $t("text")
        }}</span>
        <Textarea
          :model-value="bulletin.text"
          rows="7"
          :placeholder="$t('text')"
          class="w-full border-0 ring-slate-300"
          @update:model-value="$emit('update:text', $event || null)"
        />
      </div>

      <div class="ml-2 mt-5">
        <span class="ml-1 block text-sm font-medium text-slate-800">{{
          $t("link")
        }}</span>
        <input-text
          :model-value="bulletin.link"
          :placeholder="$t('link')"
          class="w-full border-0 ring-slate-300"
          @update:model-value="$emit('update:link', $event || null)"
        />
      </div>
    </div>

    <div class="mt-5 rounded-md bg-slate-100 p-2">
      <span class="block text-sm font-semibold text-slate-800">{{
        $t("date")
      }}</span>
      <div class="flex flex-row items-center justify-start gap-1">
        <InformationCircleIcon class="h-4 w-4" />
        <span class="block text-xs text-slate-800">
          {{ $t("bulletin_always_active_if_leave_the_dates_empty") }}
        </span>
      </div>
      <div class="mt-2">
        <span class="block pl-2 text-sm font-semibold">{{
          $t("activation_date")
        }}</span>
        <Calendar
          :model-value="bulletin.activate ? new Date(bulletin.activate) : null"
          class="w-full"
          input-class="border-0 ring-slate-300"
          show-icon
          show-button-bar
          :manual-input="false"
          placeholder="yyyy-mm-dd"
          date-format="yy-mm-dd"
          @update:model-value="
            $emit('update:activate', ($event as Date) || null)
          "
        />
      </div>

      <div class="mt-5">
        <span class="block pl-2 text-sm font-semibold">{{
          $t("deactivation_date")
        }}</span>
        <Calendar
          :model-value="
            bulletin.deactivate ? new Date(bulletin.deactivate) : null
          "
          class="w-full"
          input-class="border-0 ring-slate-300"
          show-icon
          show-button-bar
          :manual-input="false"
          placeholder="yyyy-mm-dd"
          date-format="yy-mm-dd"
          @update:model-value="
            $emit('update:deactivate', ($event as Date) || null)
          "
        />
      </div>
    </div>

    <div class="mt-5 rounded-md bg-slate-100 p-2">
      <span class="block text-sm font-semibold text-slate-800"
        >{{ $t("show_for") }}: *</span
      >
      <div class="flex flex-row items-center justify-start gap-1">
        <InformationCircleIcon class="h-4 w-4" />
        <span class="block text-xs text-slate-800">{{
          $t("at_least_one_field")
        }}</span>
      </div>
      <div class="ml-2 mt-2">
        <span class="ml-1 block text-sm font-medium text-slate-800">
          {{ $t("menu", 2) }}
        </span>

        <multi-select-search-field
          :model-value="bulletin.Menus"
          :search-items="menuStore.MENUS"
          item-type="menu"
          @update:model-value="emit('update:menus', $event as Menu[])"
        />
      </div>

      <div class="ml-2 mt-5">
        <span class="ml-1 block text-sm font-medium text-slate-800">
          {{ $t("district", 2) }}
        </span>

        <multi-select-search-field
          :model-value="bulletin.Districts"
          :search-items="districtStore.DISTRICTS"
          item-type="district"
          @update:model-value="emit('update:districts', $event as District[])"
        />
      </div>

      <div class="ml-2 mt-5">
        <span class="ml-1 block text-sm font-medium text-slate-800">
          {{ $t("school", 2) }}
        </span>

        <multi-select-search-field
          :model-value="bulletin.Schools"
          :search-items="schoolStore.SCHOOLS"
          item-type="school"
          @update:model-value="emit('update:schools', $event as School[])"
        />
      </div>
    </div>
  </form>
</template>
