<script lang="ts" setup>
import { useDistrictStore } from "@/stores/DistrictStore";
import { watch, ref } from "vue";
import { useRoute } from "vue-router";
import { Role, type District } from "@/api/prisma-interfaces";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import DistrictDetailGeneral from "./Tabs/DistrictDetailGeneral.vue";
import DistrictDetailConnections from "./Tabs/DistrictDetailConnections.vue";
import DistrictDetailPicture from "./Tabs/DistrictDetailPicture.vue";
import { useUserStore } from "@/stores/UserStore";

defineEmits(["districtUpdated", "districtDeleted"]);

const route = useRoute();
const districtStore = useDistrictStore();
const userStore = useUserStore();

const DISTRICT = ref<District | null>(null);

watch(
  route,
  async (to) => {
    if (to.name !== "DistrictDetail") return;
    const district = await districtStore.GET_DISTRICT(to.params.id as string);
    DISTRICT.value = district || null;
  },
  { immediate: true },
);

defineProps<{
  district: District;
}>();
</script>

<template>
  <div v-if="DISTRICT" class="w-full">
    <TabView>
      <TabPanel v-if="userStore.CURRENT_USER?.role === Role.superAdmin">
        <template #header>
          <span class="text-sm font-semibold text-slate-800">{{
            $t("general")
          }}</span>
        </template>
        <district-detail-general :district="DISTRICT" />
      </TabPanel>
      <TabPanel v-if="userStore.CURRENT_USER?.role === Role.superAdmin">
        <template #header>
          <span class="text-sm font-semibold text-slate-800">{{
            $t("image")
          }}</span>
        </template>
        <district-detail-picture :district="DISTRICT" />
      </TabPanel>
      <TabPanel>
        <template #header>
          <span class="text-sm font-semibold text-slate-800">{{
            $t("connections")
          }}</span>
        </template>
        <district-detail-connections
          :district="DISTRICT"
          class="overflow-auto"
          style="height: calc(100vh - 275px)"
        />
      </TabPanel>
    </TabView>
  </div>
</template>
