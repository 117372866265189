<script setup lang="ts">
import { useMealAttributesStore } from "@/stores/MealAttributesStore";
import { computed, reactive, ref, watch, provide, onMounted } from "vue";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Column from "primevue/column";
import type { MealAttributeDto } from "@/services/MealAttributesService";
import MealAttributesForm from "./MealAttributesForm.vue";
import InputText from "primevue/inputtext";
import { useFeedbackToast } from "@/composables/useFeedbackToast";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import SearchField from "@/components/shared/SearchField.vue";
import MealAttributesDetail from "./MealAttributesDetail.vue";
const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const mealAttributesStore = useMealAttributesStore();

onMounted(async () => {
  await mealAttributesStore.FETCH_MEAL_ATTRIBUTES();
  if (route.params.id) {
    SELECTED_ATTRIBUTE.value =
      (await mealAttributesStore.FETCH_MEAL_ATTRIBUTE(
        route.params.id as string,
      )) || undefined;
  } else {
    SELECTED_ATTRIBUTE.value = undefined;
  }
});

const state = reactive({
  showForm: false,
  selectedMealAttributeId: 0,
});

const { useSuccessToast, useErrorToast } = useFeedbackToast();

const MEAL_ATTRIBUTES = computed(() => {
  return mealAttributesStore.MEAL_ATTRIBUTES;
});

const LOADING_MEAL_ATTRIBUTES = computed(() => {
  return mealAttributesStore.$state.loading.FETCH_MEAL_ATTRIBUTES;
});
const NEW_MEAL_ATTRIBUTE = reactive<MealAttributeDto>({
  name: "",
  en: null,
  sv: null,
  image: null,
});

const resetMealAttribute = () => {
  Object.assign(NEW_MEAL_ATTRIBUTE, {
    name: "",
    en: null,
    sv: null,
  });
};

const SELECTED_ATTRIBUTE = ref<MealAttributeDto>();

watch(
  () => route.params.id,
  async (id) => {
    if (route.name !== "MealAttributesDetail") return;
    if (id) {
      SELECTED_ATTRIBUTE.value =
        (await mealAttributesStore.FETCH_MEAL_ATTRIBUTE(id as string)) ||
        undefined;
    }
  },
);

const CREATE_MEAL_ATTRIBUTE = async () => {
  const trimmedName = NEW_MEAL_ATTRIBUTE.name.trim();
  if (!trimmedName) {
    useErrorToast(
      t("create_", { item: t("meal_attribute") }),
      t("empty_field_", { item: t("name") }),
    );
    return;
  }
  try {
    const m = await mealAttributesStore.CREATE_MEAL_ATTRIBUTE({
      ...NEW_MEAL_ATTRIBUTE,
      name: trimmedName,
      en: NEW_MEAL_ATTRIBUTE.en?.trim() || null,
      sv: NEW_MEAL_ATTRIBUTE.sv?.trim() || null,
    });
    if (m) {
      state.showForm = false;
      resetMealAttribute();
      useSuccessToast(t("create_", { item: t("meal_attribute") }));
      router.push(`/admin/meal-attributes/${m?.id}`);
    }
  } catch (error) {
    useErrorToast(t("create_", { item: t("meal_attribute") }));
  }
};

const CREATE_MEAL_ATTRIBUTE_LOADING = computed(
  () => mealAttributesStore.$state.loading.CREATE_MEAL_ATTRIBUTE,
);

const resetSelectedMealAttribute = () => {
  SELECTED_ATTRIBUTE.value = undefined;
};
provide("resetSelectedMealAttribute", resetSelectedMealAttribute);
</script>

<template>
  <div
    class="flex flex-row flex-wrap items-end justify-between gap-2 rounded-t-lg border border-slate-200 bg-gray-50 p-5"
  >
    <div class="flex flex-col">
      <span class="pl-2 text-sm font-semibold">{{
        $t("meal_attribute", 2)
      }}</span>
      <search-field
        item-type="meal_attribute"
        class="rounded-md border border-surface-300"
      />
    </div>
    <Button
      class="flex justify-center font-semibold"
      :loading="CREATE_MEAL_ATTRIBUTE_LOADING"
      @click="() => (state.showForm = true)"
    >
      {{ t("new_attribute") }}
    </Button>
  </div>
  <div class="flex flex-row">
    <div class="w-1/3 rounded-bl-lg border border-t-0 border-slate-200">
      <data-table
        :value="MEAL_ATTRIBUTES"
        data-key="id"
        scrollable
        :scroll-height="'calc(100vh - 220px)'"
        :loading="LOADING_MEAL_ATTRIBUTES"
        :row-class="
          ({ id }) => [
            'cursor-pointer',
            'hover:bg-gray-100',
            id === SELECTED_ATTRIBUTE?.id
              ? ' border-l-[3px] border-l-slate-800 flex flex-col bg-gray-50'
              : '',
          ]
        "
        :row-hover="true"
        @row-click="(e) => $router.push(`/admin/meal-attributes/${e.data.id}`)"
      >
        <Column
          field="name"
          :header="$t('meal_attribute')"
          sortable
          :header-style="{ fontWeight: 'bold', paddingTop: '18px' }"
          class="px-6"
        >
          <template #editor="{ data, field }">
            <input-text v-model="data[field]" class="w-full" />
          </template>
        </Column>
      </data-table>
    </div>

    <div
      class="w-full rounded-br-lg border border-l-0 border-t-0 border-slate-200"
    >
      <meal-attributes-detail
        v-if="!!SELECTED_ATTRIBUTE"
        :meal-attribute="{ ...SELECTED_ATTRIBUTE }"
      />
      <div v-else class="mt-5 flex h-full w-full justify-center">
        <span class="text-gray-500">{{ $t("no_attributes_selected") }}</span>
      </div>
    </div>
  </div>

  <Dialog v-model:visible="state.showForm" modal closable dismissable-mask>
    <template #header>
      <h1 class="font-bold text-slate-800">
        {{ t("new_attribute") }}
      </h1></template
    >
    <div>
      <span class="block text-sm text-slate-600">{{ t("fill_in_form") }}</span>
      <meal-attributes-form
        class="mt-5"
        :meal-attribute="NEW_MEAL_ATTRIBUTE"
        @update:name="NEW_MEAL_ATTRIBUTE.name = $event"
        @update:en="NEW_MEAL_ATTRIBUTE.en = $event"
        @update:sv="NEW_MEAL_ATTRIBUTE.sv = $event"
      />
    </div>
    <div class="mt-5 flex flex-row justify-end gap-2">
      <Button severity="secondary" outlined @click="state.showForm = false"
        >{{ t("cancel") }}
      </Button>
      <Button
        :loading="CREATE_MEAL_ATTRIBUTE_LOADING"
        @click="CREATE_MEAL_ATTRIBUTE"
      >
        {{ t("save") }}
      </Button>
    </div>
  </Dialog>
</template>
