<script setup lang="ts">
import { useSchoolStore } from "@/stores/SchoolStore";
import { ref, watchEffect } from "vue";
import type { School } from "@/api/prisma-interfaces";
import DataTable, { type DataTableRowClickEvent } from "primevue/datatable";
import Column from "primevue/column";
import { useRouter } from "vue-router";

const props = defineProps<{ school: School }>();

const router = useRouter();
const schoolStore = useSchoolStore();

const SCHOOL_CONNECTIONS = ref();

async function fetchSchoolConnections() {
  SCHOOL_CONNECTIONS.value = await schoolStore.CONNECTIONS(props.school);
}

watchEffect(() => {
  fetchSchoolConnections();
});

function routeToDistrict(event: DataTableRowClickEvent) {
  router.push("/admin/district/" + event.data.id);
}

function routeToMenu(event: DataTableRowClickEvent) {
  router.push("/admin/menu/" + event.data.id);
}

function routeToUser(event: DataTableRowClickEvent) {
  router.push("/admin/user/" + event.data.id);
}
</script>

<template>
  <div v-if="SCHOOL_CONNECTIONS">
    <data-table
      v-if="SCHOOL_CONNECTIONS.district"
      :value="[SCHOOL_CONNECTIONS.district]"
      :row-class="() => 'cursor-pointer'"
      @row-click="routeToDistrict"
    >
      <Column
        field="name"
        :header="$t('district', 2)"
        sortable
        header-style="background-color: #F9FAFB"
        class="border-b-slate-200"
      />
    </data-table>

    <data-table
      v-if="SCHOOL_CONNECTIONS.menu"
      :value="[SCHOOL_CONNECTIONS.menu]"
      :row-class="() => 'cursor-pointer'"
      @row-click="routeToMenu"
    >
      <Column
        field="name"
        :header="$t('menu', 2)"
        sortable
        header-style="background-color: #F9FAFB"
        class="border-b-slate-200"
      />
    </data-table>

    <data-table
      v-if="SCHOOL_CONNECTIONS.users && SCHOOL_CONNECTIONS.users.length > 0"
      :value="SCHOOL_CONNECTIONS.users"
      :row-class="() => 'cursor-pointer'"
      @row-click="routeToUser"
    >
      <Column
        field="fullName"
        :header="$t('user', 2)"
        sortable
        header-style="background-color: #F9FAFB"
        class="border-b-slate-200"
      />
    </data-table>
  </div>
  <div v-else>
    <p class="block font-medium leading-6 text-gray-900">
      {{ $t("no_connections_found") }}
    </p>
  </div>
</template>
