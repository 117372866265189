<script setup lang="ts">
import { reactive, onMounted, computed, ref, watch, provide } from "vue";
import MenuDetail from "./MenuDetail.vue";
import MenuForm from "./MenuForm.vue";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import Divider from "primevue/divider";
import Button from "primevue/button";
import MenuDetailsImport from "./Tabs/MenuDetailsImport.vue";
import MenuDetailsExport from "./Tabs/MenuDetailsExport.vue";
import MenuDetailsSettings from "./Tabs/MenuDetailsSettings.vue";
import MenuDetailsConnections from "./Tabs/MenuDetailsConnections.vue";
import SelectSearchField from "@/components/shared/SelectSearchField.vue";
import { useMenuStore } from "@/stores/MenuStore";
import { useRoute, useRouter } from "vue-router";
import { Role, type District, type Menu } from "@/api/prisma-interfaces";
import type { MenuDto } from "@/services/MenuService";
import { useDistrictStore } from "@/stores/DistrictStore";
import { useFeedbackToast } from "@/composables/useFeedbackToast";
import { useUserStore } from "@/stores/UserStore";
import { useI18n } from "vue-i18n";
import SearchField from "@/components/shared/SearchField.vue";

const menuStore = useMenuStore();
const districtStore = useDistrictStore();
const userStore = useUserStore();

const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const { useSuccessToast, useErrorToast } = useFeedbackToast();

onMounted(async () => {
  await menuStore.FETCH_MENUS();
  await districtStore.FETCH_DISTRICTS();

  if (menuStore.MENUS.length === 1) {
    router.replace(`/admin/menu/${menuStore.MENUS[0].id}`);
  }

  if (route.params.id) {
    SELECTED_MENU.value = await menuStore.GET_MENU(route.params.id as string);
  }
  menus.value = menuStore.MENUS;
});

const state = reactive<{
  showForm: boolean;
}>({
  showForm: false,
});

const showImportDialog = ref(false);
const showExportDialog = ref(false);
const showSettingsDialog = ref(false);
const showConnectionsDialog = ref(false);

const SELECTED_MENU = ref<Menu>();
const SELECTED_DISTRICT = ref<District>();

const DISTRICTS = computed(() => {
  return districtStore.DISTRICTS;
});

const menus = ref(menuStore.MENUS);

const LOADING_MENUS = computed(() => {
  return menuStore.$state.loading.FETCH_MENUS;
});

const CREATE_MENU = async () => {
  const trimmedName = NEW_MENU.name.trim();
  if (!trimmedName) {
    useErrorToast(
      t("create_", { item: t("menu") }),
      t("empty_field_", { item: t("name") }),
    );
    return;
  }
  try {
    const m = await menuStore.CREATE_MENU({ ...NEW_MENU, name: trimmedName });
    if (m) {
      toggleModal();
      resetMenu();
      useSuccessToast(t("create_", { item: t("menu") }));
      router.push(`/admin/menu/${m.id}`);
    }
  } catch (error) {
    useErrorToast(t("create_", { item: t("menu") }));
  }
};

const CREATE_MENU_LOADING = computed(() => {
  return menuStore.$state.loading.CREATE_MENU;
});

const NEW_MENU = reactive<MenuDto>({
  name: "",
  numberOfItems: 2,
  bulletinId: null,
});

const resetMenu = () => {
  Object.assign(NEW_MENU, {
    name: "",
    numberOfItems: 2,
    bulletinId: null,
  });
};

function toggleModal() {
  state.showForm = !state.showForm;
}

watch(
  () => route.params.id,
  async (id) => {
    if (route.name !== "MenuDetail") return;
    const menuId = Array.isArray(id) ? id[0] : id;
    SELECTED_MENU.value = await menuStore.GET_MENU(menuId);
  },
);

watch(SELECTED_DISTRICT, async (newValue) => {
  const district = newValue;
  if (district?.id) {
    const newMenus = await menuStore.GET_MENUS_BY_DISTRICT_ID(district.id);
    if (newMenus) {
      menus.value = newMenus;
    }
  } else {
    menus.value = menuStore.MENUS;
  }
});

const refetchMenu = () => {
  if (SELECTED_MENU.value) {
    menuStore.GET_MENU(SELECTED_MENU.value.id);
  }
};
provide("refetchMenu", refetchMenu);

watch(
  () => menuStore.MENUS.length,
  () => {
    if (menuStore.MENUS.length === 1) {
      router.replace(`/admin/menu/${menuStore.MENUS[0].id}`);
    }
  },
);

const resetSelectedMenu = () => (SELECTED_MENU.value = undefined);
provide("resetSelectedMenu", resetSelectedMenu);

const handleMenuDeleted = () => {
  showSettingsDialog.value = false;
};
const closeImportDialog = () => {
  showImportDialog.value = false;
};

provide("closeImportDialog", closeImportDialog);
</script>

<template>
  <div class="flex flex-col">
    <div
      class="flex flex-row flex-wrap items-end justify-between gap-2 gap-y-2 rounded-t-lg border border-slate-200 bg-gray-50 p-5"
    >
      <div class="flex flex-col gap-2 self-start sm:flex-row">
        <div class="flex flex-col">
          <span class="pl-2 text-sm font-semibold">{{ t("menu") }}</span>
          <Dropdown
            v-if="SELECTED_DISTRICT"
            v-model="SELECTED_MENU"
            :options="menus"
            filter
            class="text-ellipsize max-w-sm"
            option-label="name"
            :placeholder="t('select') + ' ' + t('menu')"
            :empty-message="t('no_available_options')"
            :empty-filter-message="t('no_results_found')"
            show-clear
            :loading="LOADING_MENUS"
            @change="
              () => {
                if (SELECTED_MENU) {
                  $router.push(`/admin/menu/${SELECTED_MENU.id}`);
                } else {
                  $router.push(`/admin/menu`);
                }
              }
            "
          >
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <span class="block overflow-hidden text-ellipsis">{{
                  slotProps.value?.name
                }}</span>
              </div>

              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
          </Dropdown>
          <search-field
            v-else
            v-model="SELECTED_MENU"
            :search-items="menus"
            item-type="menu"
            dropdown
            class="rounded-md border border-surface-300"
          />
        </div>
        <Divider class="hidden sm:inline-block" layout="vertical" />
        <div class="flex flex-col">
          <span class="pl-2 text-sm font-semibold">{{ t("district") }}</span>
          <select-search-field
            v-model="SELECTED_DISTRICT"
            :search-items="DISTRICTS"
            item-type="district"
          />
        </div>
      </div>

      <div
        class="flex flex-row flex-wrap items-end gap-2 font-semibold sm:justify-between"
      >
        <Button
          class="mr-2 flex justify-center bg-white"
          severity="secondary"
          outlined
          :disabled="!SELECTED_MENU"
          @click="showImportDialog = true"
        >
          <div class="flex justify-center text-slate-800">
            <span>{{ t("import") }}</span>
          </div>
        </Button>

        <Button
          class="mr-2 flex justify-center bg-white"
          severity="secondary"
          outlined
          :disabled="!SELECTED_MENU"
          @click="showExportDialog = true"
        >
          <div class="flex justify-center text-slate-800">
            <span>{{ t("export") }}</span>
          </div>
        </Button>

        <Button
          v-if="
            userStore.CURRENT_USER?.canEditSchoolsAndMenus ||
            userStore.CURRENT_USER?.role === Role.superAdmin
          "
          class="mr-2 flex justify-center bg-white"
          severity="secondary"
          outlined
          :disabled="!SELECTED_MENU"
          @click="showSettingsDialog = true"
        >
          <div class="flex justify-center text-slate-800">
            <span>{{ t("settings") }}</span>
          </div>
        </Button>

        <Button
          v-if="
            userStore.CURRENT_USER?.canEditSchoolsAndMenus ||
            userStore.CURRENT_USER?.role === Role.superAdmin
          "
          class="flex justify-center"
          @click="state.showForm = true"
        >
          <div class="flex justify-center">
            <span>{{ t("new_menu") }}</span>
          </div>
        </Button>
      </div>
    </div>
  </div>

  <div>
    <menu-detail v-if="SELECTED_MENU" :menu="{ ...SELECTED_MENU }" />
    <div
      v-else
      class="mt-5 grid h-full w-full justify-center gap-5 text-center"
    >
      <span class="text-gray-500">{{ t("no_menu_selected") }}</span>
      <span class="text-gray-500">{{ t("no_menu_selected_description") }}</span>
    </div>
  </div>

  <!-- New Menu Dialog -->
  <Dialog v-model:visible="state.showForm" modal closable dismissable-mask>
    <template #header>
      <h1 class="font-bold text-slate-800">{{ t("new_menu") }}</h1>
    </template>
    <div>
      <span class="text-slate-600">{{ t("fill_in_form") }}</span>
      <menu-form
        class="mt-5"
        :menu="NEW_MENU"
        @update:name="NEW_MENU.name = $event"
        @update:number-of-items="NEW_MENU.numberOfItems = $event"
      />

      <div class="mt-5 flex justify-end font-semibold">
        <Button
          class="mr-5 flex justify-center bg-white"
          severity="secondary"
          outlined
          @click="toggleModal"
        >
          <div class="flex justify-center text-slate-800">
            <span>{{ t("cancel") }}</span>
          </div>
        </Button>

        <Button
          class="flex justify-center"
          :loading="CREATE_MENU_LOADING"
          @click="CREATE_MENU"
        >
          <div class="flex justify-center">
            <span>{{ t("save") }}</span>
          </div>
        </Button>
      </div>
    </div>
  </Dialog>

  <!-- Import Dialog -->
  <Dialog
    v-if="SELECTED_MENU"
    v-model:visible="showImportDialog"
    modal
    closable
    dismissable-mask
  >
    <template #header>
      <h1 class="font-bold text-slate-800">
        {{ t("import") + " " + t("menu") }}
      </h1>
    </template>
    <div>
      <menu-details-import :menu-id="SELECTED_MENU.id" />
    </div>
  </Dialog>

  <!-- Export Dialog -->
  <Dialog
    v-if="SELECTED_MENU"
    v-model:visible="showExportDialog"
    modal
    closable
    dismissable-mask
  >
    <template #header>
      <h1 class="font-bold text-slate-800">
        {{ t("export") + " " + t("menu") }}
      </h1>
    </template>
    <div>
      <menu-details-export :menu="SELECTED_MENU" />
    </div>
  </Dialog>

  <!-- Settings Dialog -->
  <Dialog
    v-if="SELECTED_MENU"
    v-model:visible="showSettingsDialog"
    modal
    closable
    dismissable-mask
  >
    <template #header>
      <h1 class="font-bold text-slate-800">{{ t("settings") }}</h1>
    </template>
    <div>
      <menu-details-settings
        :menu="SELECTED_MENU"
        @menu-deleted="handleMenuDeleted"
      />
    </div>
  </Dialog>

  <!-- Connections Dialog -->
  <Dialog
    v-if="SELECTED_MENU"
    v-model:visible="showConnectionsDialog"
    modal
    closable
    dismissable-mask
  >
    <template #header>
      <h1 class="font-bold text-slate-800">{{ t("connections") }}</h1>
    </template>
    <div>
      <menu-details-connections :menu="SELECTED_MENU" />
    </div>
  </Dialog>
</template>
