<script setup lang="ts">
import type { Menu } from "@/api/prisma-interfaces";
import { useFeedbackToast } from "@/composables/useFeedbackToast";
import { useMenuStore } from "@/stores/MenuStore";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  menu: Menu;
}>();

const menuStore = useMenuStore();
const { t } = useI18n();
const { useSuccessToast, useErrorToast } = useFeedbackToast();

const currentYear = new Date().getFullYear();

const ENCODING = computed(() => [
  { id: "latin1", value: "CSV - Excel (Latin-1)" },
  { id: "utf-8", value: "CSV - Numbers (UTF-8)" },
]);

const YEARS = computed(() => [
  { id: currentYear - 1, value: currentYear - 1 },
  { id: currentYear, value: currentYear },
  { id: currentYear + 1, value: currentYear + 1 },
]);

const selectedYear = ref(YEARS.value[1]);
const selectedEncoding = ref(ENCODING.value[0]);

const EXPORT_TO_FILE = async () => {
  const response = await menuStore.EXPORT_TO_FILE(
    props.menu.id,
    selectedEncoding.value.id,
    selectedYear.value?.id ?? currentYear,
  );

  if (!response) {
    useErrorToast(t("export"), t("failed"));
    return;
  }
  // Create a Blob instance from the response
  const blob = new File([response], `${props.menu.name}.csv`);

  // Create an object URL for the blob
  const url = window.URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");

  // Set the href and download attributes for the link
  link.href = url;
  link.download = blob.name;

  // Append the link to the body
  document.body.appendChild(link);

  // Click the link to start the download
  link.click();

  // Clean-up by removing the link and revoking the object URL
  document.body.removeChild(link);
  window.URL.revokeObjectURL(url);
  useSuccessToast(t("export"), t("successful"));
};
</script>

<template>
  <div>
    <span class="text-sm text-slate-600">{{ $t("export_menu_intro") }}</span>
    <span class="ml-1 mt-3 block text-sm font-semibold text-slate-800">
      {{ $t("year") }}
    </span>
    <Dropdown
      v-model="selectedYear"
      :options="YEARS"
      option-label="value"
      :placeholder="$t('select_year')"
    />
    <span class="ml-1 mt-3 block text-sm font-semibold text-slate-800">
      {{ $t("format") }}
    </span>
    <Dropdown
      v-model="selectedEncoding"
      :options="ENCODING"
      option-label="value"
      :placeholder="$t('select_a_format')"
    />
    <div class="mt-5 flex place-content-end">
      <Button @click="EXPORT_TO_FILE">{{ $t("export") }}</Button>
    </div>
  </div>
</template>
