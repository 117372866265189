<script setup lang="ts">
import { useBulletinStore } from "@/stores/BulletinStore";
import BulletinForm from "../BulletinForm.vue";
import { computed, inject } from "vue";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import Button from "primevue/button";
import Divider from "primevue/divider";
import { useConfirm } from "primevue/useconfirm";
import { useFeedbackToast } from "@/composables/useFeedbackToast";
import type { BulletinDto } from "@/services/BulletinService";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const props = defineProps<{ bulletin: BulletinDto }>();

const bulletinStore = useBulletinStore();
const confirm = useConfirm();
const { t } = useI18n();
const router = useRouter();

const resetSelectedBulletin = inject("resetSelectedBulletin", () => {});

const { useSuccessToast, useErrorToast } = useFeedbackToast();

const BULLETIN = computed(() => props.bulletin);

const UPDATE_BULLETIN = async () => {
  const newLink = BULLETIN.value.link?.trim()
    ? BULLETIN.value.link.trim()
    : null;

  const trimmedText = BULLETIN.value.text?.trim();
  if (!trimmedText && !newLink) {
    useErrorToast(
      t("empty_field_", {
        item: t("text") + " " + t("or") + " " + t("link"),
      }),
    );
    return;
  }

  if (
    !BULLETIN.value.Menus?.length &&
    !BULLETIN.value.Schools?.length &&
    !BULLETIN.value.Districts?.length
  ) {
    useErrorToast(
      t("empty_field_", {
        item:
          t("menu", 2) +
          " ," +
          t("district", 2) +
          " " +
          t("or") +
          " " +
          t("school", 2),
      }),
    );
    return;
  }
  try {
    await bulletinStore.UPDATE_BULLETIN({
      id: BULLETIN.value.id,
      text: BULLETIN.value.text,
      link: newLink,
      activate: BULLETIN.value.activate,
      deactivate: BULLETIN.value.deactivate,
      Menus: BULLETIN.value.Menus,
      Districts: BULLETIN.value.Districts,
      Schools: BULLETIN.value.Schools,
    });
    useSuccessToast(t("update_", { item: t("bulletin") }));
  } catch (error) {
    useErrorToast(t("update_", { item: t("bulletin") }));
  }
};
const DELETE_BULLETIN = async () => {
  if (!props.bulletin?.id) return;
  try {
    await bulletinStore.DELETE_BULLETIN(props.bulletin.id);
    useSuccessToast(t("delete_", { item: t("bulletin") }));
    resetSelectedBulletin();
    router.replace({ name: "BulletinView" });
  } catch (error) {
    useErrorToast(t("delete_", { item: t("bulletin") }));
  }
};

const UPDATE_BULLETIN_LOADING = computed(
  () => bulletinStore.$state.loading.UPDATE_BULLETIN,
);

const DELETE_BULLETIN_LOADING = computed(
  () => bulletinStore.$state.loading.DELETE_BULLETIN,
);

const confirmDeleteBulletin = () => {
  confirm.require({
    message: "Are you sure you want to delete this bulletin?",
    header: "Delete bulletin",
    acceptClass: "bg-red-700 hover:bg-red-600 ring-red-700 focus:ring-red-700",
    rejectLabel: "Cancel",
    acceptLabel: "Delete",
    accept: () => {
      DELETE_BULLETIN();
    },
    reject: () => {},
  });
};
</script>
<template>
  <div v-if="BULLETIN">
    <bulletin-form
      :bulletin="BULLETIN"
      @update:text="BULLETIN.text = $event"
      @update:link="BULLETIN.link = $event"
      @update:activate="BULLETIN.activate = $event"
      @update:deactivate="BULLETIN.deactivate = $event"
      @update:menus="BULLETIN.Menus = $event"
      @update:districts="BULLETIN.Districts = $event"
      @update:schools="BULLETIN.Schools = $event"
    />
    <Divider />

    <div class="mr-0.5 flex justify-end">
      <Button
        class="mr-5 flex justify-center bg-red-700 font-semibold"
        severity="danger"
        :loading="DELETE_BULLETIN_LOADING"
        @click="confirmDeleteBulletin"
      >
        <div class="flex justify-center">
          <span>{{ $t("delete") }}</span>
        </div>
      </Button>

      <Button
        class="flex justify-center font-semibold"
        :loading="UPDATE_BULLETIN_LOADING"
        @click="UPDATE_BULLETIN"
      >
        <div class="flex justify-center">
          <span>{{ $t("save") }}</span>
        </div>
      </Button>
    </div>
  </div>
  <div v-else>
    <LoadingSpinner />
  </div>
</template>
