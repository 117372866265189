import { createPinia } from "pinia";
import { createApp } from "vue";
import * as Sentry from "@sentry/vue";
import { AxiosError } from "axios";
import PrimeVue from "primevue/config";
import Tooltip from "primevue/tooltip";
import "@/index.css";
import App from "@/App.vue";
import router from "@/router";
import Wind from "./presets/wind";
import "@/style.css";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
import * as config from "./config";
import i18n from "./i18n";

const pinia = createPinia();

const app = createApp(App).use(pinia).use(router).use(i18n);

app.config.errorHandler = (error: any, instance: any, info: any) => {
  if (error instanceof AxiosError) {
    console.error("Error:", error.response?.data?.errors);
  } else {
    console.error("Global error:", error);
  }
  console.log("Vue instance:", instance);
  console.log("Error info:", info);
};

Sentry.init({
  app,
  dsn: "https://fe5cc206cfc94dae8822fcf4c0d004bb@sentry.ist.com/43",
  integrations: [Sentry.browserTracingIntegration({ router })],
  enabled: config.nodeEnv !== "localhost",
  environment: config.nodeEnv,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate: config.nodeEnv !== "production" ? 1.0 : 0.2,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [
    "http://bdev-home-dev-02.bdev.ist.local/meal-api/api",
    "http://exthome-prod-home-02.exthome.istint.net:9000/meal-api/api",
  ],
});

app.use(PrimeVue, {
  unstyled: true,
  pt: Wind,
});
app.use(ConfirmationService);
app.use(ToastService);
app.directive("tooltip", Tooltip);

app.mount("#app");
