<script setup lang="ts">
import { ref, watch } from "vue";
import AutoComplete from "primevue/autocomplete";
import SearchService from "../../services/SearchService";
import type {
  District,
  Menu,
  Province,
  School,
  User,
} from "@/api/prisma-interfaces";

type ItemType = "district" | "province" | "user" | "school" | "menu";

type ItemMap = {
  district: District;
  province: Province;
  user: User;
  school: School;
  menu: Menu;
};

const props = defineProps<{
  searchItems: ItemMap[ItemType][];
  itemType: ItemType;
  modelValue?: ItemMap[ItemType];
}>();

const emit = defineEmits<{
  (event: "update:modelValue", value: ItemMap[ItemType]): void;
}>();

const selectedResult = ref();
const searchResult = ref(props.searchItems);

watch(selectedResult, (newValue) => {
  emit("update:modelValue", newValue);
});

watch(
  () => props.modelValue,
  (newValue) => {
    selectedResult.value = newValue;
  },
);

let debounceTimer: NodeJS.Timeout;

const search = (event: any) => {
  let query = event.query;

  // Clear the previous timer if it exists
  if (debounceTimer) {
    clearTimeout(debounceTimer);
  }

  // Set a new timer
  debounceTimer = setTimeout(async () => {
    let result;
    switch (props.itemType) {
      case "district":
        result = await SearchService.searchDistricts(query);
        break;
      case "province":
        result = await SearchService.searchProvinces(query);
        break;
      case "user":
        result = await SearchService.searchUsers(query);
        break;
      case "school":
        result = await SearchService.searchSchools(query);
        break;
      case "menu":
        result = await SearchService.searchMenus(query);
        break;
    }

    searchResult.value = result;
  }, 1000);
};
</script>

<template>
  <div class="w-full">
    <AutoComplete
      v-model="selectedResult"
      :suggestions="searchResult"
      dropdown
      option-label="name"
      :placeholder="`${$t('search')} ${$t(itemType, 2)}`"
      class="p-autocomplete h-full w-full"
      input-class="w-full focus:ring-0 focus:border-0"
      :empty-search-message="$t('no_results_found')"
      @complete="search"
    >
      <template #option="itemProps">
        <span>{{ itemProps.option.name }}</span>
        <span
          v-if="itemType === 'district' && itemProps.option.Province"
          class="text-gray-400"
          >{{ ` - ${itemProps.option.Province.name}` }}</span
        >
        <span
          v-if="itemType === 'school' && itemProps.option.District"
          class="text-gray-400"
          >{{ ` - ${itemProps.option.District.name}` }}</span
        >
      </template>
    </AutoComplete>
  </div>
</template>
