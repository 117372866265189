<script setup lang="ts">
import { onMounted, computed, ref } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import { useRequestedSchoolsStore } from "@/stores/RequestedSchoolsStore";

const requestedSchoolsStore = useRequestedSchoolsStore();

onMounted(async () => {
  await requestedSchoolsStore.FETCH_REQUESTED_SCHOOLS();
  requestedSchools.value = REQUESTED_SCHOOLS.value;
});

const REQUESTED_SCHOOLS = computed(
  () => requestedSchoolsStore.REQUESTED_SCHOOLS,
);

const LOADING_REQUESTED_SCHOOLS = computed(
  () => requestedSchoolsStore.$state.loading.FETCH_REQUESTED_SCHOOLS,
);

const first = ref(0);
const requestedSchools = ref(REQUESTED_SCHOOLS.value);

const onPage = async (event: { first: number; rows: number }) => {
  await requestedSchoolsStore.FETCH_REQUESTED_SCHOOLS(event.rows, event.first);
  first.value = event.first;
  requestedSchools.value = REQUESTED_SCHOOLS.value;
};
</script>

<template>
  <div class="flex flex-col">
    <div
      class="flex flex-row justify-between rounded-t-lg border border-slate-200 bg-gray-50 p-5"
    >
      <div class="row flex">
        <div class="flex flex-col">
          <span class="pl-2 font-semibold">{{
            $t("requested_school", 2)
          }}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-row">
    <div class="w-full rounded-bl-lg border border-t-0 border-slate-200">
      <data-table
        :value="requestedSchools"
        lazy
        data-key="id"
        striped-rows
        paginator
        paginator-template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        scrollable
        :scroll-height="'calc(100vh - 220px)'"
        :loading="LOADING_REQUESTED_SCHOOLS"
        :rows="20"
        :first="first"
        :total-records="requestedSchoolsStore.count"
        @page="onPage($event)"
      >
        <column
          field="count"
          :header="$t('count')"
          :header-style="{
            fontWeight: 'bold',
            paddingTop: '18px',
            background: '#F3F4F6',
          }"
          class="border-b-slate-200 pl-6"
        />
        <column
          field="school"
          :header="$t('school')"
          :header-style="{
            fontWeight: 'bold',
            paddingTop: '18px',
            background: '#F3F4F6',
          }"
          class="border-b-slate-200"
        />

        <column
          field="district"
          :header="$t('district')"
          :header-style="{
            fontWeight: 'bold',
            paddingTop: '18px',
            background: '#F3F4F6',
          }"
          class="border-b-slate-200"
        />
      </data-table>
    </div>
  </div>
</template>
