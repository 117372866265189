import RequestedSchoolsService from "@/services/RequestedSchoolsService";
import { type RequestedSchool } from "@/api/prisma-interfaces";
import { acceptHMRUpdate, defineStore } from "pinia";
import { getErrorMessage } from "@/utils/ErrorHandler";

export interface RequestedScoolsState {
  requestedSchools: RequestedSchool[];
  loading: { [key: string]: boolean };
  count: number;
  error?: { [key: string]: any } | null;
}

// Actions
export const FETCH_REQUESTED_SCHOOLS = "FETCH_REQUESTED_SCHOOLS";

// GETTERS
export const REQUESTED_SCHOOLS = "REQUESTED_SCHOOLS";

export const useRequestedSchoolsStore = defineStore("requestedSchools", {
  state: (): RequestedScoolsState => ({
    requestedSchools: [],
    loading: {},
    count: 0,
    error: null,
  }),
  actions: {
    async [FETCH_REQUESTED_SCHOOLS](take?: number, skip?: number) {
      this.setLoading(FETCH_REQUESTED_SCHOOLS, true);
      try {
        const page = await RequestedSchoolsService.getRequestedSchools(
          take,
          skip,
        );
        this.requestedSchools = page.requestedSchools;
        this.count = page.total;
        return page;
      } catch (error) {
        this.setError(FETCH_REQUESTED_SCHOOLS, getErrorMessage(error));
      } finally {
        this.setLoading(FETCH_REQUESTED_SCHOOLS, false);
      }
    },
    setLoading(action: string, value: boolean) {
      if (value) {
        this.setError(action, null);
      }
      this.loading[action] = value;
    },
    setError(action: string, value: any) {
      this.error = { ...this.error, [action]: value };
      if (value) {
        console.error(action, value);
        throw value;
      }
    },
  },
  getters: {
    [REQUESTED_SCHOOLS](state: RequestedScoolsState) {
      return state.requestedSchools;
    },
  },
});

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useRequestedSchoolsStore, import.meta.hot),
  );
}
