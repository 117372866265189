<script setup lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
import SearchService from "@/services/SearchService";
import AutoComplete from "primevue/autocomplete";
import {
  BuildingLibraryIcon,
  BuildingOfficeIcon,
  MapPinIcon,
  MagnifyingGlassIcon,
} from "@heroicons/vue/24/outline";
import { useConfirm } from "primevue/useconfirm";
import { getUrlName } from "@/utils/UrlNames";
const router = useRouter();
const confirm = useConfirm();

const selectedResult = ref();
const searchResult = ref();

const noMenuDialog = () => {
  confirm.require({
    message: "No menu found for this school",
    header: "Error",
    rejectClass: "hidden",
    acceptClass: "bg-slate-800 ring-0 focus:ring-orange-600 hover:bg-slate-700",
    acceptLabel: "Ok",
  });
};

const search = async (event: any) => {
  let query = event.query;

  let groupedResults;
  let result = await SearchService.publicSearch(query);
  groupedResults = result.reduce((grouped: any, item) => {
    const mappedItem = {
      value: { id: item.id, menuId: item.menuId },
      label: item.name,
      type: item.type,
      urlName: item.urlName,
      urlNames: item.urlNames,
      parentName: item.parentName,
    };
    (grouped[item.type] = grouped[item.type] || []).push(mappedItem);
    return grouped;
  }, {});

  const formattedResults = Object.keys(groupedResults).map((key) => ({
    label: key,
    items: groupedResults[key],
  }));

  searchResult.value = formattedResults;
};

const getImage = (type: string) => {
  switch (type) {
    case "school":
      return BuildingOfficeIcon;
    case "district":
      return BuildingLibraryIcon;
    case "province":
      return MapPinIcon;
  }
};

async function onSelect(event: any) {
  const item = event.value;
  switch (item.type) {
    case "district":
      router.push(`/d/${getUrlName(item.urlName, item.urlNames)}`);
      break;
    case "province":
      router.push(`/p/${getUrlName(item.urlName, item.urlNames)}`);
      break;
    case "school":
      if (item.value.menuId) {
        router.push({
          path: `/${getUrlName(item.urlName, item.urlNames)}`,
        });
      } else {
        noMenuDialog();
      }
      break;
  }
}

declare global {
  interface String {
    capitalizeFirstChar(): string;
  }
}

String.prototype.capitalizeFirstChar = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
</script>

<template>
  <div class="flex w-full flex-row rounded-md bg-white">
    <div class="self-center pl-1">
      <MagnifyingGlassIcon class="h-full w-5 text-gray-400" />
    </div>
    <AutoComplete
      v-model="selectedResult"
      :suggestions="searchResult"
      option-label="label"
      option-group-label="label"
      option-group-children="items"
      :placeholder="$t('search')"
      class="p-autocomplete h-full w-full"
      input-class="w-full border-none focus:border-0 "
      panel-class="w-full min-w-[fit-content]"
      append-to="self"
      :empty-search-message="$t('no_results_found')"
      @complete="search"
      @item-select="onSelect"
    >
      <template #optiongroup="groupProps">
        <div class="flex">
          <component
            :is="getImage(groupProps.option.label)"
            class="mr-2 h-5 w-5"
          />
          <div>{{ $t(groupProps.option.label, 2) }}</div>
        </div>
      </template>
      <template #option="itemProps">
        <span>{{ itemProps.option.label }}</span>
        <span v-if="itemProps.option.parentName" class="text-gray-400">{{
          ` - ${itemProps.option.parentName}`
        }}</span>
      </template>
    </AutoComplete>
  </div>
</template>
