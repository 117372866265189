import type { MealAttribute } from "@/api/prisma-interfaces";
import MealAttributeService, {
  type MealAttributeDto,
} from "@/services/MealAttributesService";
import { getErrorMessage } from "@/utils/ErrorHandler";
import { acceptHMRUpdate, defineStore } from "pinia";

export interface MealAttributesState {
  mealAttributes: MealAttribute[];
  loading: { [key: string]: boolean };
  error?: { [key: string]: any } | null;
}

// Actions
export const FETCH_MEAL_ATTRIBUTES = "FETCH_MEAL_ATTRIBUTES";
export const FETCH_MEAL_ATTRIBUTE = "FETCH_MEAL_ATTRIBUTE";
export const UPDATE_MEAL_ATTRIBUTE = "UPDATE_MEAL_ATTRIBUTE";
export const UPDATE_MEAL_ATTRIBUTE_IMAGE = "UPDATE_MEAL_ATTRIBUTE_IMAGE";
export const DELETE_MEAL_ATTRIBUTE = "DELETE_MEAL_ATTRIBUTE";
export const CREATE_MEAL_ATTRIBUTE = "CREATE_MEAL_ATTRIBUTE";

// Getters
export const MEAL_ATTRIBUTES = "MEAL_ATTRIBUTES";
export const MEAL_ATTRIBUTE = "MEAL_ATTRIBUTE";

export const useMealAttributesStore = defineStore("mealAttributes", {
  state: (): MealAttributesState => ({
    mealAttributes: [],
    loading: {},
    error: null,
  }),
  actions: {
    async [FETCH_MEAL_ATTRIBUTES]() {
      this.setLoading(FETCH_MEAL_ATTRIBUTES, true);
      try {
        const mealAttributes = await MealAttributeService.getMealAttributes();
        this.mealAttributes = mealAttributes;
        return mealAttributes;
      } catch (error) {
        this.setError(FETCH_MEAL_ATTRIBUTES, getErrorMessage(error));
      } finally {
        this.setLoading(FETCH_MEAL_ATTRIBUTES, false);
      }
    },
    async [FETCH_MEAL_ATTRIBUTE](mealAttributeId: string) {
      this.setLoading(FETCH_MEAL_ATTRIBUTE, true);
      try {
        const mealAttribute =
          await MealAttributeService.getMealAttribute(mealAttributeId);
        return mealAttribute;
      } catch (error) {
        this.setError(FETCH_MEAL_ATTRIBUTE, getErrorMessage(error));
      } finally {
        this.setLoading(FETCH_MEAL_ATTRIBUTE, false);
      }
    },
    async [UPDATE_MEAL_ATTRIBUTE](mealAttribute: MealAttributeDto) {
      this.setLoading(UPDATE_MEAL_ATTRIBUTE, true);
      try {
        const updatedMealAttribute =
          await MealAttributeService.updateMealAttribute({
            id: mealAttribute.id,
            name: mealAttribute.name,
            en: mealAttribute.en,
            sv: mealAttribute.sv,
            image: mealAttribute.image,
            imageUrl: mealAttribute.imageUrl,
          });
        const index = this.mealAttributes.findIndex(
          (m) => m.id === updatedMealAttribute.id,
        );
        if (index !== -1) {
          this.mealAttributes.splice(index, 1, updatedMealAttribute);
        }
      } catch (error) {
        this.setError(UPDATE_MEAL_ATTRIBUTE, getErrorMessage(error));
      } finally {
        this.setLoading(UPDATE_MEAL_ATTRIBUTE, false);
      }
    },
    async [UPDATE_MEAL_ATTRIBUTE_IMAGE](
      mealAttributeId: string,
      image: FormData,
    ) {
      this.setLoading(UPDATE_MEAL_ATTRIBUTE_IMAGE, true);
      try {
        const updatedMealAttribute =
          await MealAttributeService.updateMealAttributeImage(
            mealAttributeId,
            image,
          );
        const index = this.mealAttributes.findIndex(
          (m) => m.id === updatedMealAttribute.id,
        );
        if (index !== -1) {
          this.mealAttributes.splice(index, 1, updatedMealAttribute);
        }
        return updatedMealAttribute.image;
      } catch (error) {
        this.setError(UPDATE_MEAL_ATTRIBUTE_IMAGE, getErrorMessage(error));
      } finally {
        this.setLoading(UPDATE_MEAL_ATTRIBUTE_IMAGE, false);
      }
    },
    async [DELETE_MEAL_ATTRIBUTE](mealAttributeId: string) {
      this.setLoading(DELETE_MEAL_ATTRIBUTE, true);
      try {
        await MealAttributeService.deleteMealAttribute(mealAttributeId);
        this.mealAttributes = this.mealAttributes.filter(
          (a) => a.id !== mealAttributeId,
        );
      } catch (error) {
        this.setError(DELETE_MEAL_ATTRIBUTE, getErrorMessage(error));
      } finally {
        this.setLoading(DELETE_MEAL_ATTRIBUTE, false);
      }
    },
    async [CREATE_MEAL_ATTRIBUTE](mealAttribute: MealAttributeDto) {
      this.setLoading(CREATE_MEAL_ATTRIBUTE, true);
      try {
        const createdMealAttribute =
          await MealAttributeService.createMealAttribute(mealAttribute);
        this.mealAttributes.unshift(createdMealAttribute);
        return createdMealAttribute;
      } catch (error) {
        this.setError(CREATE_MEAL_ATTRIBUTE, getErrorMessage(error));
      } finally {
        this.setLoading(CREATE_MEAL_ATTRIBUTE, false);
      }
    },
    setLoading(action: string, value: boolean) {
      if (value) {
        this.setError(action, null);
      }
      this.loading[action] = value;
    },
    setError(action: string, value: any) {
      this.error = { ...this.error, [action]: value };
      if (value) {
        console.error(action, value);
        throw value;
      }
    },
  },
  getters: {
    [MEAL_ATTRIBUTES](state: MealAttributesState) {
      return state.mealAttributes;
    },
  },
});

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useMealAttributesStore, import.meta.hot),
  );
}
