<script setup lang="ts">
import type { Province } from "@/api/prisma-interfaces";
import router from "@/router";
import DataTable, { type DataTableRowClickEvent } from "primevue/datatable";
import Column from "primevue/column";

defineProps<{ province: Province }>();

function routeToDistrict(event: DataTableRowClickEvent) {
  router.push({ name: "DistrictDetail", params: { id: event.data.id } });
}
</script>

<template>
  <div v-if="province && province.Districts && province.Districts.length > 0">
    <data-table
      :value="province.Districts"
      :row-class="() => 'cursor-pointer'"
      @row-click="routeToDistrict"
    >
      <Column
        field="name"
        :header="$t('district', 2)"
        sortable
        header-style="background-color: #F9FAFB"
        class="border-b-slate-200"
      />
    </data-table>
  </div>
  <div v-else>
    <p class="block font-medium leading-6 text-gray-900">
      {{ $t("no_connections_found") }}
    </p>
  </div>
</template>
