<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useSchoolStore } from "@/stores/SchoolStore";
import { useMenuStore } from "@/stores/MenuStore";
import { useDistrictStore } from "@/stores/DistrictStore";
import { useUserStore } from "@/stores/UserStore";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import * as config from "@/config";
import { Role, type User } from "@/api/prisma-interfaces";

import Checkbox from "primevue/checkbox";
import Dropdown from "primevue/dropdown";
import MultiSelectSearchField from "@/components/shared/MultiSelectSearchField.vue";
import { useFeedbackToast } from "@/composables/useFeedbackToast";
import { useI18n } from "vue-i18n";
import { useMealAttributesStore } from "@/stores/MealAttributesStore";
import UserService from "@/services/UserService";

const schoolStore = useSchoolStore();
const menuStore = useMenuStore();
const districtStore = useDistrictStore();
const userStore = useUserStore();
const mealAttributesStore = useMealAttributesStore();
const { t } = useI18n();

const { useSuccessToast, useErrorToast } = useFeedbackToast();

onMounted(async () => {
  await schoolStore.FETCH_SCHOOLS();
  await menuStore.FETCH_MENUS();
  await districtStore.FETCH_DISTRICTS();
  await mealAttributesStore.FETCH_MEAL_ATTRIBUTES();
  await userStore.FETCH_USERS();

  selectedConnectedUsers.value = await userStore.GET_CONNECTED_USERS(
    USER.value.id,
  );
});

const emits = defineEmits(["userUpdated"]);

const props = defineProps<{ user: User }>();

const USER = computed(() => props.user);

const ROLES = Object.values(Role);

const UPDATE_USER = async () => {
  try {
    await userStore.UPDATE_USER(USER.value);
    emits("userUpdated");
    useSuccessToast(t("update_", { item: t("user") }));
  } catch (error) {
    useErrorToast(t("update_", { item: t("user") }));
  }
};

const UPDATE_USER_LOADING = computed(
  () => userStore.$state.loading.UPDATE_USER,
);

// The user that you are viewing now
const selectedConnectedUsers = ref<User[]>();

watch(
  () => USER.value.id,
  async () => {
    selectedConnectedUsers.value = await userStore.GET_CONNECTED_USERS(
      USER.value.id,
    );
  },
);

const addAllSchoolsFromSelectedDistricts = async () => {
  const userDistricts = USER.value.Districts;
  if (!userDistricts) return;
  const districtIds = new Set(userDistricts.map((district) => district.id));
  const schools = await schoolStore.GET_SCHOOLS_BY_DISTRICTS(
    Array.from(districtIds),
  );
  USER.value.Schools = schools;
};

const addAllMenusFromSelectedSchools = async () => {
  const userSchools = USER.value.Schools;
  if (!userSchools) return;
  const schoolIds = new Set(userSchools.map((school) => school.id));
  const menus = await menuStore.GET_MENUS_BY_SCHOOL_IDS(Array.from(schoolIds));
  USER.value.Menus = menus;
};

const secretSuperAdminModal = ref(false);

const makeSuperAdmin = async () => {
  if (
    userStore.CURRENT_USER?.id !== USER.value.id ||
    config.nodeEnv === "production"
  )
    return;
  secretSuperAdminModal.value = false;
  await UserService.secretMakeSuperAdmin(USER.value.id);
};
</script>

<template>
  <div>
    <div>
      <div>
        <span class="ml-1 block text-sm font-semibold text-slate-800">
          {{ t("district", 2) }}
        </span>
        <multi-select-search-field
          v-if="USER.Districts"
          v-model="USER.Districts"
          :search-items="districtStore.districts"
          item-type="district"
        />
      </div>
      <div
        v-if="
          userStore.CURRENT_USER?.canEditSchoolsAndMenus ||
          userStore.CURRENT_USER?.role === Role.superAdmin
        "
        class="mt-5 flex items-center"
      >
        <Checkbox v-model="USER.canEditSchoolsAndMenus" binary />
        <label class="ml-3 text-sm font-semibold text-slate-800">
          {{ t("school_menu_edit") }}
        </label>
      </div>
      <div>
        <div class="flex flex-row justify-between">
          <span class="ml-1 mt-5 block text-sm font-semibold text-slate-800">
            {{ t("school", 2) }}
          </span>
          <span
            v-if="USER.Districts && USER.Districts?.length > 0"
            class="mt-5 block text-sm font-semibold text-cyan-700 hover:cursor-pointer hover:text-cyan-800"
            @click="addAllSchoolsFromSelectedDistricts()"
          >
            {{ t("add_from_selected_districts") }}
          </span>
        </div>
        <multi-select-search-field
          v-if="USER.Schools"
          v-model="USER.Schools"
          :search-items="schoolStore.schools"
          item-type="school"
          filter-type="district"
          :filter-ids="USER.Districts?.map((district) => district.id)"
        />
      </div>
      <div>
        <div class="flex flex-row justify-between">
          <span class="ml-1 mt-5 block text-sm font-semibold text-slate-800">
            {{ t("menu", 2) }}
          </span>
          <span
            v-if="USER.Schools && USER.Schools.length > 0"
            class="mt-5 block text-sm font-semibold text-cyan-700 hover:cursor-pointer hover:text-cyan-800"
            @click="addAllMenusFromSelectedSchools()"
          >
            {{ t("add_from_selected_schools") }}
          </span>
        </div>

        <multi-select-search-field
          v-if="USER.Menus"
          v-model="USER.Menus"
          :search-items="menuStore.menus"
          item-type="menu"
          filter-type="school"
          :filter-ids="USER.Schools?.map((school) => school.id)"
        />
      </div>

      <div
        v-if="
          userStore.CURRENT_USER?.canEditUsers ||
          userStore.CURRENT_USER?.role === Role.superAdmin
        "
        class="mt-5 flex items-center"
      >
        <Checkbox v-model="USER.canEditUsers" binary />
        <label class="ml-3 text-sm font-semibold text-slate-800">
          {{ t("users_edit") }}
        </label>
      </div>
      <div v-if="USER.canEditUsers">
        <span class="ml-1 mt-5 block text-sm font-semibold text-slate-800">
          {{ t("user", 2) }}
        </span>
        <multi-select-search-field
          v-if="userStore.USERS_WITHOUT_CURRENT_USER"
          v-model="selectedConnectedUsers"
          :search-items="userStore.USERS_WITHOUT_CURRENT_USER"
          item-type="user"
          @update:model-value="
            ($event) => {
              if ($event) {
                USER.connectedUserIds = $event.map((user) => user.id);
              }
            }
          "
        />
      </div>
      <div
        v-if="
          userStore.CURRENT_USER?.canEditBulletins ||
          userStore.CURRENT_USER?.role === Role.superAdmin
        "
        class="mt-5 flex items-center"
      >
        <Checkbox v-model="USER.canEditBulletins" binary />
        <label class="ml-3 text-sm font-semibold text-slate-800">
          {{ t("bulletins_edit") }}
        </label>
      </div>
    </div>
    <div v-if="userStore.CURRENT_USER?.role === Role.superAdmin">
      <span class="ml-1 mt-5 block text-sm font-semibold text-slate-800">
        {{ t("role") }}
      </span>
      <Dropdown
        v-model="USER.role"
        display="chip"
        :placeholder="t('select_role')"
        :options="ROLES"
        class="w-full"
        :virtual-scroller-options="{ itemSize: 44 }"
        @update:model-value="USER.role = $event"
      />
    </div>
    <div class="mt-5 flex justify-end space-x-4">
      <Button
        class="flex justify-center font-semibold"
        :loading="UPDATE_USER_LOADING"
        @click="UPDATE_USER"
      >
        <div class="flex justify-center">
          <span>{{ t("save") }}</span>
        </div>
      </Button>
    </div>
    <div
      v-if="
        userStore.CURRENT_USER?.id === USER.id &&
        config.nodeEnv !== 'production'
      "
      class="mt-5 flex justify-end space-x-4"
      @click="secretSuperAdminModal = true"
    >
      {{ "🦧" }}
    </div>
  </div>
  <Dialog
    v-if="
      userStore.CURRENT_USER?.id === USER.id && config.nodeEnv !== 'production'
    "
    v-model:visible="secretSuperAdminModal"
    modal
    closable
    dismissable-mask
  >
    <div>SuperAdmin time?</div>

    <div class="mt-5 flex justify-end">
      <Button
        class="mr-5 flex justify-center font-semibold"
        severity="secondary"
        outlined
        @click="secretSuperAdminModal = false"
      >
        <div class="flex justify-center">
          <span>{{ t("cancel") }}</span>
        </div>
      </Button>

      <Button class="flex justify-center font-semibold" @click="makeSuperAdmin">
        <div class="flex justify-center">
          <span>{{ t("save") }}</span>
        </div>
      </Button>
    </div>
  </Dialog>
</template>
