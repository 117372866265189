<script lang="ts" setup>
import { ref, watch } from "vue";
import ProvinceDetailGeneral from "./Tabs/ProvinceDetailGeneral.vue";
import ProvinceDetailConnections from "./Tabs/ProvinceDetailConnections.vue";
import { Role, type Province } from "@/api/prisma-interfaces";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import { useUserStore } from "@/stores/UserStore";
import { useRoute } from "vue-router";
import { useProvinceStore } from "@/stores/ProvinceStore";

const route = useRoute();
const provinceStore = useProvinceStore();
const userStore = useUserStore();

defineProps<{
  province: Province;
}>();

const PROVINCE = ref<Province | null>(null);

watch(
  route,
  async (to) => {
    if (to.name !== "ProvinceDetail") return;
    const province = await provinceStore.GET_PROVINCE(to.params.id as string);
    PROVINCE.value = province || null;
  },
  { immediate: true },
);
</script>

<template>
  <TabView
    v-if="userStore.CURRENT_USER?.role === Role.superAdmin && PROVINCE"
    class="w-full"
  >
    <TabPanel>
      <template #header>
        <span class="text-sm font-semibold text-slate-800">{{
          $t("general")
        }}</span>
      </template>
      <province-detail-general :province="PROVINCE" />
    </TabPanel>
    <TabPanel>
      <template #header>
        <span class="text-sm font-semibold text-slate-800">{{
          $t("connections")
        }}</span>
      </template>
      <province-detail-connections
        :province="PROVINCE"
        class="overflow-auto"
        style="height: calc(100vh - 275px)"
      />
    </TabPanel>
  </TabView>
</template>
