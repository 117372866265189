<script setup lang="ts">
import { reactive, onMounted, watch, provide } from "vue";
import { useRoute } from "vue-router";
import ProvinceDetail from "./ProvinceDetail.vue";
import ProvinceForm from "./ProvinceForm.vue";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Column from "primevue/column";
import Button from "primevue/button";
import { useProvinceStore } from "@/stores/ProvinceStore";
import { useDistrictStore } from "@/stores/DistrictStore";
import { computed, ref } from "vue";
import { useFeedbackToast } from "@/composables/useFeedbackToast";
import SearchField from "@/components/shared/SearchField.vue";
import { useUserStore } from "@/stores/UserStore";
import { Role } from "@/api/prisma-interfaces";
import type { Province } from "@/api/prisma-interfaces";
import { useI18n } from "vue-i18n";
import router from "@/router";

const { t } = useI18n();
const { useSuccessToast, useErrorToast } = useFeedbackToast();

const provinceStore = useProvinceStore();
const districtStore = useDistrictStore();
const userStore = useUserStore();

onMounted(async () => {
  await provinceStore.FETCH_PROVINCES();
  provinces.value = provinceStore.PROVINCES;
  await districtStore.FETCH_DISTRICTS();

  if (route.params.id) {
    SELECTED_PROVINCE.value =
      (await provinceStore.GET_PROVINCE(route.params.id as string)) ||
      undefined;
  } else {
    SELECTED_PROVINCE.value = undefined;
  }
});

const state = reactive({
  showForm: false,
  newProvinceName: "",
});

const route = useRoute();

const SELECTED_PROVINCE = ref<Province>();

const PROVINCES = computed(() => {
  return provinceStore.PROVINCES;
});
const first = ref(0);
const provinces = ref(PROVINCES.value);

const LOADING_PROVINCES = computed(() => {
  return provinceStore.$state.loading.FETCH_PROVINCES;
});

const CREATE_PROVINCE = async () => {
  const trimmedName = state.newProvinceName.trim();
  if (!trimmedName) {
    useErrorToast(
      t("create_", { item: t("province") }),
      t("empty_field_", { item: t("name") }),
    );
    return;
  }
  try {
    const p = await provinceStore.CREATE_PROVINCE({ name: trimmedName });

    if (p) {
      state.showForm = false;
      state.newProvinceName = "";
      useSuccessToast(t("create_", { item: t("province") }));
      router.push(`/admin/province/${p.id}`);
    }
  } catch (error) {
    useErrorToast(t("create_", { item: t("province") }));
  }
};

const CREATE_PROVINCE_LOADING = computed(() => {
  return provinceStore.$state.loading.CREATE_PROVINCE;
});

function toggleModal() {
  state.newProvinceName = "";
  state.showForm = !state.showForm;
}

const onPage = async (event: { first: number; rows: number }) => {
  await provinceStore.FETCH_PROVINCES(event.rows, event.first);
  first.value = event.first;
  provinces.value = provinceStore.PROVINCES;
};

const resetSelectedProvince = () => (SELECTED_PROVINCE.value = undefined);

provide("resetSelectedProvince", resetSelectedProvince);

watch(
  () => route.params.id as string,
  async (id) => {
    if (route.name !== "ProvinceDetail") return;
    if (id) {
      SELECTED_PROVINCE.value =
        (await provinceStore.GET_PROVINCE(id as string)) || undefined;
    } else {
      SELECTED_PROVINCE.value = undefined;
    }
  },
);
</script>

<template>
  <div
    class="flex flex-row flex-wrap items-end justify-between gap-2 rounded-t-lg border border-slate-200 bg-gray-50 p-5"
  >
    <div class="flex-col">
      <span class="pl-2 text-sm font-semibold">{{ $t("province") }}</span>
      <search-field
        item-type="province"
        class="rounded-md border border-surface-300"
      />
    </div>

    <Button
      v-if="userStore.CURRENT_USER?.role === Role.superAdmin"
      class="flex justify-center font-semibold"
      @click="state.showForm = true"
    >
      <span>{{ $t("new_province") }}</span>
    </Button>
  </div>

  <div class="flex flex-row">
    <div class="w-1/3 rounded-bl-lg border border-t-0 border-slate-200">
      <data-table
        :value="provinces"
        lazy
        data-key="id"
        paginator
        paginator-template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        scrollable
        :scroll-height="'calc(100vh - 220px)'"
        :loading="LOADING_PROVINCES"
        :selection="SELECTED_PROVINCE"
        :rows="20"
        :first="first"
        :total-records="provinceStore.count"
        :row-class="
          ({ id }) => [
            'cursor-pointer',
            'hover:bg-gray-100',
            id === SELECTED_PROVINCE?.id
              ? ' border-l-[3px] border-l-slate-800 flex flex-col bg-gray-50'
              : '',
          ]
        "
        :row-hover="true"
        @page="onPage($event)"
        @row-click="(e) => $router.push(`/admin/province/${e.data.id}`)"
      >
        <column
          field="name"
          :header="$t('province', 2)"
          :header-style="{ fontWeight: 'bold', paddingTop: '18px' }"
          class="px-6"
        />
      </data-table>
    </div>

    <div
      class="flex w-full rounded-br-lg border border-l-0 border-t-0 border-slate-200"
    >
      <province-detail
        v-if="!!SELECTED_PROVINCE"
        :province="{ ...SELECTED_PROVINCE }"
      />
      <div v-else class="mt-5 flex h-full w-full justify-center">
        <span class="text-gray-500">{{ $t("no_province_selected") }}</span>
      </div>
    </div>
  </div>

  <Dialog v-model:visible="state.showForm" modal closable dismissable-mask>
    <template #header>
      <span class="font-bold text-slate-800"> {{ $t("new_province") }} </span>
    </template>
    <div>
      <province-form
        :name="state.newProvinceName"
        @update:name="state.newProvinceName = $event"
      />

      <div class="mt-5 flex justify-end gap-5">
        <Button
          severity="secondary"
          outlined
          :label="$t('cancel')"
          @click="toggleModal"
        >
        </Button>

        <Button
          :label="$t('save')"
          :loading="CREATE_PROVINCE_LOADING"
          @click="CREATE_PROVINCE"
        >
        </Button>
      </div>
    </div>
  </Dialog>
</template>
