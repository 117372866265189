<script setup lang="ts">
import { ref, watch } from "vue";
import AutoComplete from "primevue/autocomplete";
import SearchService from "../../services/SearchService";
import type {
  District,
  Menu,
  Province,
  School,
  User,
} from "@/api/prisma-interfaces";
import { CheckCircleIcon } from "@heroicons/vue/20/solid";

type ItemType = "district" | "province" | "user" | "school" | "menu";
type FilterType = "district" | "school";

type ItemMap = {
  district: District;
  province: Province;
  user: User;
  school: School;
  menu: Menu;
};

const props = defineProps<{
  modelValue: ItemMap[ItemType][] | undefined;
  searchItems: ItemMap[ItemType][];
  itemType: ItemType;
  filterType?: FilterType;
  filterIds?: string[];
}>();

const emit = defineEmits<{
  (event: "update:modelValue", value: ItemMap[ItemType][] | undefined): void;
}>();

const selectedResult = ref(props.modelValue);
const searchResult = ref(props.searchItems);

watch(selectedResult, (newValue) => {
  emit("update:modelValue", newValue);
});

watch(
  () => props.modelValue,
  (newValue) => {
    selectedResult.value = newValue;
  },
);

let debounceTimer: NodeJS.Timeout;

const search = (event: any) => {
  let query = event.query;

  // Clear the previous timer if it exists
  if (debounceTimer) {
    clearTimeout(debounceTimer);
  }

  // Set a new timer
  debounceTimer = setTimeout(async () => {
    let result;
    switch (props.itemType) {
      case "district":
        result = await SearchService.searchDistricts(query);
        break;
      case "province":
        result = await SearchService.searchProvinces(query);
        break;
      case "user":
        result = await SearchService.searchUsers(query);
        break;
      case "school":
        if (props.filterType === "district") {
          result = await SearchService.searchSchools(query, props.filterIds);
        } else {
          result = await SearchService.searchSchools(query);
        }
        break;
      case "menu":
        if (props.filterType === "school") {
          result = await SearchService.searchMenus(query, props.filterIds);
        } else {
          result = await SearchService.searchMenus(query);
        }

        break;
    }

    searchResult.value = result;
  }, 1000);
};
</script>

<template>
  <div class="w-full">
    <AutoComplete
      v-model="selectedResult"
      data-key="id"
      :suggestions="searchResult"
      dropdown
      multiple
      :option-label="itemType === 'user' ? 'fullName' : 'name'"
      :placeholder="$t('search') + ' ' + $t(itemType, 2)"
      class="p-autocomplete h-full w-full"
      input-class="w-full focus:ring-0 focus:border-0"
      @complete="search"
    >
      <template #option="itemProps">
        <div class="flex items-center">
          <span>{{
            itemType === "user"
              ? itemProps.option.fullName
              : itemProps.option.name
          }}</span>
          <span
            v-if="itemType === 'district' && itemProps.option.Province"
            class="text-gray-400"
            >{{ ` - ${itemProps.option.Province.name}` }}</span
          >
          <span
            v-if="itemType === 'school' && itemProps.option.District"
            class="text-gray-400"
            >{{ ` - ${itemProps.option.District.name}` }}</span
          >
          <CheckCircleIcon
            v-if="
              selectedResult?.some(
                (item: any) => item.id === itemProps.option.id,
              )
            "
            class="ml-2 h-5 w-5 text-green-600"
          />
        </div>
      </template>
    </AutoComplete>
  </div>
</template>
