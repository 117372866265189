<script setup lang="ts">
import { onMounted, ref, computed } from "vue";
import { useRoute, RouterLink } from "vue-router";
import LoadingSpinner from "@/components/shared/LoadingSpinner.vue";
import {
  GET_VISIBLE_SCHOOLS_BY_DISTRICT_URL_NAME,
  useSchoolStore,
} from "@/stores/SchoolStore";
import type { School, UrlName } from "@/api/prisma-interfaces";
import { ChevronLeftIcon } from "@heroicons/vue/20/solid";
import { setTitle } from "@/utils/SetTitle";
import { getUrlName } from "@/utils/UrlNames";
const route = useRoute();
const schoolStore = useSchoolStore();
const district = ref("");
const province = ref<{
  id: string;
  name: string;
  urlName: string | null;
  urlNames?: UrlName[];
}>();

let schools = ref<School[]>([]);

onMounted(async () => {
  const result = await schoolStore[GET_VISIBLE_SCHOOLS_BY_DISTRICT_URL_NAME](
    route.params.districtURLName as string,
  );
  schools.value = result?.schools || [];
  district.value = result?.district.name || "";
  province.value = result?.province;
  setTitle(district.value);
});

const LOADING_SCHOOLS = computed(() => {
  return schoolStore.$state.loading.GET_VISIBLE_SCHOOLS_BY_DISTRICT_URL_NAME;
});
</script>
<template>
  <div class="flex justify-center">
    <div v-if="LOADING_SCHOOLS">
      <loading-spinner />
    </div>

    <div v-else>
      <router-link
        class="flex items-center text-lg hover:text-cyan-600"
        :to="`/p/${getUrlName(province?.urlName, province?.urlNames)}`"
      >
        <ChevronLeftIcon class="h-8 w-8 fill-cyan-600" />
        {{ province?.name }}
      </router-link>
      <div class="my-3 border-b-4 border-amber-400 py-1 text-2xl font-bold">
        {{ district }}
      </div>
      <div v-if="schools.length">
        <div
          class="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
        >
          <router-link
            v-for="s in schools"
            :key="s.id"
            class="rounded-md border border-slate-200 px-4 py-2 shadow-sm duration-75 ease-in-out hover:cursor-pointer hover:bg-slate-800 hover:text-white"
            :to="{
              path: `/${getUrlName(s.urlName, s.urlNames)}`,
            }"
          >
            <span>
              {{ s.name }}
            </span>
          </router-link>
        </div>
      </div>
      <div v-else>{{ $t("no_schools_to_show") }}</div>
    </div>
  </div>
</template>
