<script setup lang="ts">
import { reactive, onMounted, computed, ref, watch, provide } from "vue";
import UserDetail from "./UserDetail.vue";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import Column from "primevue/column";
import Button from "primevue/button";
import UserForm from "./UserForm.vue";
import { useUserStore } from "@/stores/UserStore";
import type { UserDto } from "@/services/UserService";
import { Role, type School, type User } from "@/api/prisma-interfaces";
import SearchField from "@/components/shared/SearchField.vue";
import SelectSearchField from "@/components/shared/SelectSearchField.vue";
import { useRoute, useRouter } from "vue-router";
import { useSchoolStore } from "@/stores/SchoolStore";
import { useFeedbackToast } from "@/composables/useFeedbackToast";
import { useI18n } from "vue-i18n";
import { StarIcon } from "@heroicons/vue/24/solid";
import * as uuid from "uuid";

const route = useRoute();
const router = useRouter();
const userStore = useUserStore();
const schoolStore = useSchoolStore();
const { useSuccessToast, useErrorToast } = useFeedbackToast();
const { t } = useI18n();

onMounted(async () => {
  await userStore.FETCH_USERS();
  users.value = userStore.USERS;

  if (route.params.id) {
    SELECTED_USER.value =
      (await userStore.GET_USER(route.params.id as string)) || undefined;
  } else {
    SELECTED_USER.value = undefined;
  }

  await schoolStore.FETCH_SCHOOLS();
});

const state = reactive<{
  showForm: boolean;
  selectedUser: User | null;
}>({
  showForm: false,
  selectedUser: null,
});

const USERS = computed(() => {
  return userStore.USERS;
});

const LOADING_USERS = computed(() => {
  return userStore.$state.loading.FETCH_USERS;
});

const SELECTED_USER = ref<User>();
const SELECTED_SCHOOL = ref<School>();
const usePaginator = ref(true);

watch(
  () => route.params.id as string,
  async (id) => {
    if (route.name !== "UserDetail") return;
    if (id) {
      SELECTED_USER.value = (await userStore.GET_USER(id)) || undefined;
    } else {
      SELECTED_USER.value = undefined;
    }
  },
);

watch(SELECTED_SCHOOL, async (newValue) => {
  const school = newValue;
  if (school?.id) {
    usePaginator.value = false;
    const newUsers = await userStore.GET_USERS_BY_SCHOOL_ID(school.id);
    if (newUsers) {
      users.value = newUsers;
    }
  } else {
    usePaginator.value = true;
    users.value = userStore.USERS;
  }
});

function toggleModal() {
  state.showForm = !state.showForm;
}

const NEW_USER = reactive<UserDto>({
  name: null,
  email: "",
  role: Role.user,
  fullName: "",
  password: uuid.v4(),
  canEditSchoolsAndMenus: null,
  canEditUsers: null,
  canEditBulletins: null,
  connectedUserIds: [],
});

function resetNewUser() {
  Object.assign(NEW_USER, {
    name: null,
    email: "",
    role: Role.user,
    fullName: "",
    password: uuid.v4(),
    canEditSchoolsAndMenus: null,
    canEditUsers: null,
    canEditBulletins: null,
    connectedUserIds: [],
  });
}

const CREATE_USER = async () => {
  const trimmedFullName = NEW_USER.fullName.trim();
  const trimmedEmail = NEW_USER.email.trim();
  if (!trimmedEmail || !trimmedFullName) {
    const detail = [];
    if (!trimmedFullName) {
      detail.push(t("full_name"));
    }
    if (!trimmedEmail) {
      detail.push(t("email"));
    }
    useErrorToast(
      t("create_", { item: t("user") }),
      t("empty_field_", { item: detail.join(", ") }),
    );
    return;
  }
  try {
    const u = await userStore.CREATE_USER({
      ...NEW_USER,
      fullName: trimmedFullName,
      email: trimmedEmail,
    });
    if (u) {
      toggleModal();
      resetNewUser();
      useSuccessToast(t("create_", { item: t("user") }));
      router.push(`/admin/user/${u.id}`);
    }
  } catch (error) {
    useErrorToast(t("create_", { item: t("user") }));
  }
};

const CREATE_USER_LOADING = computed(() => {
  return userStore.$state.loading.CREATE_USER;
});

const first = ref(0);
const users = ref(USERS.value);

const onPage = async (event: { first: number; rows: number }) => {
  await userStore.FETCH_USERS(event.rows, event.first);
  first.value = event.first;
  users.value = userStore.USERS;
};

const resetSelectedUser = () => (SELECTED_USER.value = undefined);
provide("resetSelectedUser", resetSelectedUser);
</script>

<template>
  <div class="flex flex-col">
    <div
      class="flex flex-row flex-wrap items-end justify-between gap-2 rounded-t-lg border border-slate-200 bg-gray-50 p-5"
    >
      <div class="flex flex-col gap-2 sm:flex-row">
        <div class="flex flex-col">
          <span class="pl-2 text-sm font-semibold">{{ t("user") }}</span>
          <search-field
            item-type="user"
            class="rounded-md border border-surface-300"
          />
        </div>
        <Divider class="hidden sm:inline-block" layout="vertical" />
        <div class="flex flex-col">
          <span class="pl-2 text-sm font-semibold">{{ t("school") }}</span>
          <select-search-field
            v-model="SELECTED_SCHOOL"
            :search-items="schoolStore.SCHOOLS"
            item-type="school"
          />
        </div>
      </div>
      <div class="flex justify-end">
        <div class="flex items-end">
          <Button
            v-if="
              userStore.CURRENT_USER?.canEditUsers ||
              userStore.CURRENT_USER?.role === Role.superAdmin
            "
            class="flex justify-center font-semibold"
            @click="state.showForm = true"
          >
            <div class="flex justify-center">
              <span>{{ t("new_user") }}</span>
            </div>
          </Button>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-row">
    <div class="w-1/3 rounded-bl-lg border border-t-0 border-slate-200">
      <data-table
        :value="users"
        lazy
        data-key="id"
        :paginator="usePaginator"
        paginator-template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        scrollable
        :scroll-height="'calc(100vh - 220px)'"
        :loading="LOADING_USERS"
        :selection="SELECTED_USER"
        :rows="usePaginator ? 20 : undefined"
        :first="usePaginator ? first : 0"
        :total-records="userStore.count"
        :row-class="
          ({ id }) => [
            'cursor-pointer',
            'hover:bg-gray-100',
            id === SELECTED_USER?.id
              ? ' border-l-[3px] border-l-slate-800 flex flex-col bg-gray-50'
              : '',
          ]
        "
        :row-hover="true"
        @page="onPage($event)"
        @row-click="(e) => $router.push(`/admin/user/${e.data.id}`)"
      >
        <column
          field="fullName"
          :header="t('user', 2)"
          :header-style="{ fontWeight: 'bold', paddingTop: '18px' }"
          class="px-6"
        >
          <template #body="{ data }">
            <div class="flex justify-between">
              <span>{{ data.fullName }}</span>

              <div
                v-if="data.role === Role.superAdmin"
                class="ml-3 flex items-center justify-center rounded-sm bg-blue-50 px-2 py-1 font-semibold"
              >
                <StarIcon class="h-4 w-4 text-amber-400" />
                <span class="ml-2">{{ t("super_admin") }}</span>
              </div>
            </div>
          </template>
        </column>
      </data-table>
    </div>
    <div
      class="flex w-full rounded-br-lg border border-l-0 border-t-0 border-slate-200"
    >
      <user-detail v-if="SELECTED_USER" :user="SELECTED_USER" />
      <div v-else class="mt-5 flex h-full w-full justify-center">
        <span class="text-gray-500">{{ t("no_user_selected") }}</span>
      </div>
    </div>
  </div>

  <Dialog v-model:visible="state.showForm" modal closable dismissable-mask>
    <template #header>
      <h1 class="font-bold text-slate-800">{{ t("new_user") }}</h1></template
    >
    <div>
      <user-form
        :user="NEW_USER"
        @update:full-name="NEW_USER.fullName = $event"
        @update:email="NEW_USER.email = $event"
      />

      <div class="mt-5 flex justify-end">
        <Button
          class="mr-5 flex justify-center font-semibold"
          severity="secondary"
          outlined
          @click="toggleModal"
        >
          <div class="flex justify-center">
            <span>{{ t("cancel") }}</span>
          </div>
        </Button>

        <Button
          class="flex justify-center font-semibold"
          :loading="CREATE_USER_LOADING"
          @click="CREATE_USER"
        >
          <div class="flex justify-center">
            <span>{{ t("save") }}</span>
          </div>
        </Button>
      </div>
    </div>
  </Dialog>
</template>
