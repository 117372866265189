import HttpClient from "@/utils/HttpClient";
import type { Meal, MealAttribute } from "@/api/prisma-interfaces";
const MEAL_ATTRIBUTES_URL = "4/meal-attributes";

export interface MealAttributeDto
  extends Omit<
    MealAttribute,
    "id" | "oldId" | "order" | "createdAt" | "updatedAt"
  > {
  id?: MealAttribute["id"];
}

export interface MealDto extends Omit<Meal, "id" | "createdAt" | "updatedAt"> {
  id?: Meal["id"];
}

class MealAttributesService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient = new HttpClient()) {
    this.httpClient = httpClient;
  }

  async getMealAttributes(): Promise<MealAttribute[]> {
    const response = await this.httpClient.get<MealAttribute[]>(
      `/admin/${MEAL_ATTRIBUTES_URL}`,
    );
    return response;
  }

  async getMealAttribute(id: string): Promise<MealAttribute> {
    const response = await this.httpClient.get<MealAttribute>(
      `/admin/${MEAL_ATTRIBUTES_URL}/${id}`,
    );
    return response;
  }

  async createMealAttribute(
    mealAttribute: MealAttributeDto,
  ): Promise<MealAttribute> {
    const response = await this.httpClient.post<MealAttribute>(
      `/admin/${MEAL_ATTRIBUTES_URL}`,
      mealAttribute,
    );
    return response;
  }

  async updateMealAttribute(
    mealAttribute: MealAttributeDto,
  ): Promise<MealAttribute> {
    const response = await this.httpClient.put<MealAttribute>(
      `/admin/${MEAL_ATTRIBUTES_URL}`,

      {
        id: mealAttribute.id,
        name: mealAttribute.name,
        en: mealAttribute.en,
        sv: mealAttribute.sv,
      },
    );
    return response;
  }

  async updateMealAttributeImage(
    id: string,
    image: FormData,
  ): Promise<MealAttribute> {
    const response = await this.httpClient.put<MealAttribute>(
      `/admin/${MEAL_ATTRIBUTES_URL}/${id}/image`,
      image,
    );
    return response;
  }

  async deleteMealAttribute(id: string): Promise<void> {
    return await this.httpClient.delete(`/admin/${MEAL_ATTRIBUTES_URL}`, {
      id,
    });
  }
}

export default new MealAttributesService();
