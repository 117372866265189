<script setup lang="ts">
import { useMenuStore } from "@/stores/MenuStore";
import { computed, inject } from "vue";
import MenuForm from "../MenuForm.vue";
import type { Menu } from "@/api/prisma-interfaces";
import Button from "primevue/button";
import { useConfirm } from "primevue/useconfirm";
import { useFeedbackToast } from "@/composables/useFeedbackToast";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const confirm = useConfirm();
const router = useRouter();

const props = defineProps<{
  menu: Menu;
}>();

const { t } = useI18n();
const emit = defineEmits(["menuUpdated", "menuDeleted"]);

const { useSuccessToast, useErrorToast } = useFeedbackToast();

const resetSelectedMenu = inject("resetSelectedMenu", () => {});

const menuUpdated = () => {
  emit("menuUpdated");
};

const menuDeleted = () => {
  emit("menuDeleted");
};

const menuStore = useMenuStore();

const MENU = computed(() => props.menu);

const UPDATE_MENU = async () => {
  try {
    await menuStore.UPDATE_MENU(MENU.value);
    menuUpdated();
    useSuccessToast(t("update_", { item: t("menu") + " " + t("settings") }));
  } catch (error) {
    useErrorToast(t("update_", { item: t("menu") + " " + t("settings") }));
  }
};

const DELETE_MENU = async () => {
  if (!props.menu.id) return;
  try {
    await menuStore.DELETE_MENU(props.menu.id);
    menuDeleted();
    useSuccessToast(t("delete_", { item: t("menu") }));
    resetSelectedMenu();
    router.replace({ name: "MenuView" });
  } catch (error) {
    useErrorToast(t("delete_", { item: t("menu") }));
  }
};

const UPDATE_MENU_LOADING = computed(
  () => menuStore.$state.loading.UPDATE_MENU,
);

const DELETE_MENU_LOADING = computed(
  () => menuStore.$state.loading.DELETE_MENU,
);

const confirmDeleteMenu = () => {
  confirm.require({
    message: t("delete_confirm_", { item: MENU.value.name }),
    header: t("delete_", { item: t("menu") }),
    rejectLabel: t("cancel"),
    acceptLabel: t("delete"),
    acceptClass: "bg-red-700 hover:bg-red-600 ring-red-700 focus:ring-red-700",
    accept: () => {
      DELETE_MENU();
    },
    reject: () => {},
  });
};
</script>

<template>
  <div>
    <span class="text-slate-600">{{ t("menu_settings_info") }}</span>
    <menu-form
      :menu="MENU"
      class="mt-3"
      @update:name="MENU.name = $event"
      @update:number-of-items="MENU.numberOfItems = $event"
    />

    <div class="mt-5 flex justify-end">
      <Button
        class="mr-5 flex justify-center bg-red-700"
        severity="danger"
        :loading="DELETE_MENU_LOADING"
        @click="confirmDeleteMenu"
      >
        <div class="flex justify-center">
          <span>{{ t("delete_", { item: t("menu") }) }}</span>
        </div>
      </Button>

      <Button
        class="flex justify-center"
        :loading="UPDATE_MENU_LOADING"
        @click="UPDATE_MENU"
      >
        <div class="flex justify-center">
          <span>{{ t("save") }}</span>
        </div>
      </Button>
    </div>
  </div>
</template>
