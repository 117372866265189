<script lang="ts" setup>
import { useDistrictStore } from "@/stores/DistrictStore";
import { useProvinceStore } from "@/stores/ProvinceStore";
import { computed, onMounted, inject, ref } from "vue";
import DistrictForm from "../DistrictForm.vue";
import type { District } from "@/api/prisma-interfaces";
import { useMenuStore } from "@/stores/MenuStore";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import { useConfirm } from "primevue/useconfirm";
import { useFeedbackToast } from "@/composables/useFeedbackToast";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const confirm = useConfirm();
const { t } = useI18n();
const router = useRouter();

const emit = defineEmits(["districtUpdated", "districtDeleted"]);

const { useSuccessToast, useErrorToast } = useFeedbackToast();

const districtUpdated = () => {
  emit("districtUpdated");
};

const districtDeleted = () => {
  emit("districtDeleted");
};

const resetSelectedDistrict = inject("resetSelectedDistrict", () => {});

const districtStore = useDistrictStore();
const provinceStore = useProvinceStore();
const menuStore = useMenuStore();

onMounted(() => {
  provinceStore.FETCH_PROVINCES();
  menuStore.FETCH_MENUS();
});

const UPDATE_DISTRICT = async () => {
  try {
    await districtStore.UPDATE_DISTRICT(DISTRICT.value);
    districtUpdated();
    useSuccessToast(t("update_", { item: t("district") }));
  } catch (error) {
    useErrorToast(t("update_", { item: t("district") }));
  }
};

const DELETE_DISTRICT = async () => {
  if (!props.district?.id) return;
  try {
    await districtStore.DELETE_DISTRICT(props.district.id);
    districtDeleted();
    useSuccessToast(t("delete_", { item: t("district") }));
    resetSelectedDistrict();
    router.replace({ name: "DistrictView" });
  } catch (error) {
    useErrorToast(t("delete_", { item: t("district") }));
  }
};

const UPDATE_DISTRICT_LOADING = computed(
  () => districtStore.$state.loading.UPDATE_DISTRICT,
);

const DELETE_DISTRICT_LOADING = computed(
  () => districtStore.$state.loading.DELETE_DISTRICT,
);

const props = defineProps<{
  district: District;
}>();
const showDialog = ref(false);

const DISTRICT = computed(() => {
  return props.district;
});

const confirmDeleteDistrict = () => {
  confirm.require({
    message: t("delete_confirm_", { item: DISTRICT.value.name }),
    header: t("delete_", { item: t("district") }),
    rejectLabel: t("cancel"),
    acceptLabel: t("delete"),
    acceptClass: "bg-red-700 hover:bg-red-600 ring-red-700 focus:ring-red-700",
    accept: () => {
      DELETE_DISTRICT();
    },
    reject: () => {},
  });
};
</script>

<template>
  <div>
    <district-form
      :district="DISTRICT"
      @update:name="DISTRICT.name = $event"
      @update:selected-menu-id="DISTRICT.menuId = $event"
      @update:selected-province-id="DISTRICT.provinceId = $event"
      @update:customer-id="DISTRICT.customerId = $event"
    />
    <Divider />

    <div class="flex justify-end">
      <Button
        class="mr-5 flex justify-center bg-red-700"
        severity="danger"
        :loading="DELETE_DISTRICT_LOADING"
        @click="
          () => {
            if (DISTRICT.Schools && DISTRICT.Schools.length > 0) {
              showDialog = true;
            } else {
              confirmDeleteDistrict();
            }
          }
        "
      >
        <div class="flex justify-center font-semibold">
          <span>{{ t("delete") }}</span>
        </div>
      </Button>

      <Button
        class="flex justify-center"
        :loading="UPDATE_DISTRICT_LOADING"
        @click="UPDATE_DISTRICT"
      >
        <div class="flex justify-center font-semibold">
          <span>{{ t("save") }}</span>
        </div>
      </Button>
    </div>
  </div>
  <Dialog v-model:visible="showDialog" modal closable dismissable-mask>
    <template #header>
      <h1 class="font-bold text-slate-800">
        {{ $t("delete_", { item: $t("district") }) }}
      </h1>
    </template>
    <div>
      <span class="block text-sm font-semibold text-slate-600">
        {{
          $t("_contains_", {
            item: DISTRICT.name,
            amount: DISTRICT.Schools?.length,
          }) +
          " " +
          $t("school", DISTRICT.Schools?.length === 1 ? 1 : 2)
        }}
      </span>
      <span class="mt-2 block text-sm text-slate-600">
        {{
          $t("warn_delete_connected_data", {
            item: DISTRICT.name,
            connectedItem: $t("school", 2),
          })
        }}
      </span>

      <div class="mt-5 flex justify-end">
        <Button
          class="mr-5 flex justify-center"
          severity="secondary"
          outlined
          @click="showDialog = false"
        >
          <div class="flex justify-center font-semibold">
            <span>{{ t("cancel") }}</span>
          </div>
        </Button>

        <Button
          class="flex justify-center bg-red-700"
          severity="danger"
          @click="
            () => {
              DELETE_DISTRICT();
              showDialog = false;
            }
          "
        >
          <div class="flex justify-center font-semibold">
            <span>{{ t("delete") }}</span>
          </div>
        </Button>
      </div>
    </div>
  </Dialog>
</template>
