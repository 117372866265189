<script setup lang="ts">
import { useDistrictStore } from "@/stores/DistrictStore";
import { ref, watchEffect } from "vue";
import type { District } from "@/api/prisma-interfaces";
import DataTable, { type DataTableRowClickEvent } from "primevue/datatable";
import Column from "primevue/column";
import { useRouter } from "vue-router";

const props = defineProps<{ district: District }>();

const router = useRouter();
const districtStore = useDistrictStore();

const DISTRICT_CONNECTIONS = ref();

const fetchDistrictConnections = async () => {
  DISTRICT_CONNECTIONS.value = await districtStore.CONNECTIONS(props.district);
};

watchEffect(async () => {
  await fetchDistrictConnections();
});

function routeToProvince(event: DataTableRowClickEvent) {
  router.push("/admin/province/" + event.data.id);
}

function routeToSchool(event: DataTableRowClickEvent) {
  router.push("/admin/school/" + event.data.id);
}

function routeToMenu(event: DataTableRowClickEvent) {
  router.push("/admin/menu/" + event.data.id);
}

function routeToUser(event: DataTableRowClickEvent) {
  router.push("/admin/user/" + event.data.id);
}
</script>

<template>
  <div v-if="DISTRICT_CONNECTIONS">
    <data-table
      v-if="DISTRICT_CONNECTIONS.province"
      :value="[DISTRICT_CONNECTIONS.province]"
      :row-class="() => 'cursor-pointer'"
      @row-click="routeToProvince"
    >
      <Column
        field="name"
        :header="$t('province', 2)"
        sortable
        header-style="background-color: #F9FAFB"
        class="border-b-slate-200"
      />
    </data-table>

    <data-table
      v-if="DISTRICT_CONNECTIONS.schools && DISTRICT_CONNECTIONS.schools.length"
      class="mt-5"
      :value="DISTRICT_CONNECTIONS.schools"
      :row-class="() => 'cursor-pointer'"
      @row-click="routeToSchool"
    >
      <Column
        field="name"
        :header="$t('school', 2)"
        sortable
        header-style="background-color: #F9FAFB"
        class="border-b-slate-200"
      />
    </data-table>

    <data-table
      v-if="DISTRICT_CONNECTIONS.menu"
      class="mt-5"
      :value="[DISTRICT_CONNECTIONS.menu]"
      :row-class="() => 'cursor-pointer'"
      @row-click="routeToMenu"
    >
      <Column
        field="name"
        :header="$t('menu', 2)"
        sortable
        header-style="background-color: #F9FAFB"
        class="border-b-slate-200"
      />
    </data-table>

    <data-table
      v-if="DISTRICT_CONNECTIONS.users && DISTRICT_CONNECTIONS.users.length > 0"
      class="mt-5"
      :value="DISTRICT_CONNECTIONS.users"
      :row-class="() => 'cursor-pointer'"
      @row-click="routeToUser"
    >
      <Column
        field="fullName"
        :header="$t('user', 2)"
        sortable
        header-style="background-color: #F9FAFB"
        class="border-b-slate-200"
      />
    </data-table>
  </div>
  <div v-else>
    <p class="block font-medium leading-6 text-gray-900">
      {{ $t("no_connections_found") }}
    </p>
  </div>
</template>
