<script setup lang="ts">
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import type { ConnectedAppDto } from "@/services/ConnectedAppService";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import * as uuid from "uuid";
import { useConfirm } from "primevue/useconfirm";

interface ConnectedAppForm {
  connectedApp: ConnectedAppDto;
}

const { t } = useI18n();
const confirm = useConfirm();

const props = defineProps<ConnectedAppForm>();

const isEnabled = computed({
  get: () => props.connectedApp.isEnabled,
  set: (value: boolean) => emit("update:isEnabled", value),
});

const token = computed({
  get: () => props.connectedApp.token,
  set: (value: string) => emit("update:token", value),
});

const emit = defineEmits<{
  (event: "update:name", id: string): void;
  (event: "update:token", id: string): void;
  (event: "update:isEnabled", value: boolean): void;
}>();

function randomToken() {
  token.value = uuid.v4();
}

const confirmRandomizeToken = () => {
  confirm.require({
    message: t("confirm_token_randomize"),
    header: t("generate_random"),
    rejectLabel: t("cancel"),
    acceptLabel: t("generate"),
    rejectClass:
      "bg-red-700 hover:bg-red-600 ring-red-700 focus:ring-red-700 border-red-700",
    accept: () => {
      randomToken();
    },
    reject: () => {},
  });
};
</script>

<template>
  <form>
    <span class="block pl-2 text-sm font-semibold"
      >{{ t("connected_app") }} {{ t("name") }} *
    </span>
    <input-text
      :model-value="connectedApp.name"
      :placeholder="$t('name')"
      class="w-full border-0 ring-slate-300"
      @update:model-value="$emit('update:name', $event || '')"
    />
    <div class="mt-5">
      <div class="flex flex-row justify-between">
        <span class="block pl-2 text-sm font-semibold"
          >{{ t("token") }} *
        </span>
        <button
          type="button"
          class="block text-sm font-semibold text-cyan-700 hover:cursor-pointer hover:text-cyan-800"
          @click="confirmRandomizeToken"
        >
          {{ t("generate_random") }}
        </button>
      </div>
      <input-text
        v-model="token"
        :placeholder="$t('token')"
        class="w-full border-0 ring-slate-300"
      />
    </div>

    <div class="mt-5 flex items-center">
      <Checkbox v-model="isEnabled" :binary="true" />
      <label for="isEnabled" class="ml-3">{{ t("enable_app") }}</label>
    </div>
  </form>
</template>
