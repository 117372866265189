<script setup lang="ts">
import { useFeedbackToast } from "@/composables/useFeedbackToast";
import { useMenuStore } from "@/stores/MenuStore";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import { computed, ref, inject } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  menuId: string;
}>();

const closeImportDialog = inject("closeImportDialog", () => {});

const menuStore = useMenuStore();
const { t } = useI18n();
const { useSuccessToast, useErrorToast } = useFeedbackToast();

const ENCODING = computed(() => [
  { id: "latin1", value: "CSV - Excel (Latin-1)" },
  { id: "utf-8", value: "CSV - Numbers (UTF-8)" },
]);

const selectedEncoding = ref(ENCODING.value[0]);
const file = ref();

const handleFile = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target.files) {
    file.value = target.files[0];
  }
};

const IMPORT_FROM_FILE = async () => {
  if (!file.value) return;
  try {
    await menuStore.IMPORT_FROM_FILE(
      props.menuId,
      file.value,
      selectedEncoding.value.id,
    );
    useSuccessToast(t("import"), t("successful"));
    closeImportDialog();
  } catch (error) {
    useErrorToast(t("import"), t("failed"));
  }
};
</script>

<template>
  <div>
    <div
      class="mt-3 flex w-full flex-col items-center justify-center rounded-lg bg-slate-100 p-5"
    >
      <span class="mb-3 text-sm text-slate-800">{{
        $t("import_menu_upload_file_intro")
      }}</span>
      <label for="fileInput" class="flex flex-col items-center justify-center">
        <span
          class="rounded-lg border border-slate-800 p-2 text-sm text-slate-800 hover:cursor-pointer hover:bg-slate-200"
          >{{ file ? file.name : $t("upload_file") }}</span
        >
        <input
          id="fileInput"
          type="file"
          accept=".csv"
          class="hidden"
          @change="handleFile"
        />
      </label>
    </div>

    <span class="ml-1 mt-3 block text-sm font-semibold text-slate-800">
      Format
    </span>
    <Dropdown
      v-model="selectedEncoding"
      :options="ENCODING"
      option-label="value"
      :placeholder="$t('select_format')"
      class="w-full"
    />
    <div class="mt-5 flex place-content-end">
      <Button @click="IMPORT_FROM_FILE">{{ $t("import") }}</Button>
    </div>
  </div>
</template>
