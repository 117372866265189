import { acceptHMRUpdate, defineStore } from "pinia";
import type { Day } from "@/api/prisma-interfaces";
import DayService, { type DayDto } from "@/services/DayService";
import { getErrorMessage } from "@/utils/ErrorHandler";

export interface DayState {
  days: Day[];
  loading: { [key: string]: boolean };
  error?: { [key: string]: any } | null;
}

// Actions
export const FETCH_DAYS_IN_RANGE = "FETCH_DAYS_IN_RANGE";
export const UPDATE_DAYS = "UPDATE_DAYS";
export const DELETE_DAYS = "DELETE_DAYS";
export const CREATE_DAYS = "CREATE_DAYS";

// Getters
export const DAYS = "DAYS";

export const useDayStore = defineStore("day", {
  state: (): DayState => ({
    days: [],
    loading: {},
    error: null,
  }),
  actions: {
    async [FETCH_DAYS_IN_RANGE](data: {
      menuId: string;
      startDate: Date;
      endDate: Date;
    }) {
      this.setLoading(FETCH_DAYS_IN_RANGE, true);
      try {
        const days = await DayService.getDaysInRange(
          data.menuId,
          data.startDate,
          data.endDate,
        );
        this.days = days;
        return days;
      } catch (error) {
        this.setError(FETCH_DAYS_IN_RANGE, getErrorMessage(error));
      } finally {
        this.setLoading(FETCH_DAYS_IN_RANGE, false);
      }
    },
    async [UPDATE_DAYS](days: DayDto[]) {
      this.setLoading(UPDATE_DAYS, true);
      try {
        const updatedDays = await DayService.updateDays({ days });
        if (updatedDays.length !== days.length) {
          throw new Error("Not all days were updated");
        }
        updatedDays.forEach((updatedDay) => {
          const index = this.days.findIndex((d) => d.id === updatedDay.id);
          if (index !== -1) {
            this.days.splice(index, 1, updatedDay);
          }
        });
        return updatedDays;
      } catch (error) {
        this.setError(UPDATE_DAYS, getErrorMessage(error));
      } finally {
        this.setLoading(UPDATE_DAYS, false);
      }
    },
    async [DELETE_DAYS](menuId: string, dayIds: string[]) {
      this.setLoading(DELETE_DAYS, true);
      try {
        await DayService.deleteDays(menuId, dayIds);
        this.days = this.days.filter((d) => !dayIds.includes(d.id));
      } catch (error) {
        this.setError(DELETE_DAYS, getErrorMessage(error));
      } finally {
        this.setLoading(DELETE_DAYS, false);
      }
    },
    async [CREATE_DAYS](days: DayDto[]) {
      this.setLoading(CREATE_DAYS, true);
      try {
        const createdDay = await DayService.createDays(days);
        this.days = [...this.days, ...createdDay];
        return createdDay;
      } catch (error) {
        this.setError(CREATE_DAYS, getErrorMessage(error));
      } finally {
        this.setLoading(CREATE_DAYS, false);
      }
    },
    setLoading(action: string, value: boolean) {
      if (value) {
        this.setError(action, null);
      }
      this.loading[action] = value;
    },
    setError(action: string, value: any) {
      this.error = { ...this.error, [action]: value };
      if (value) {
        throw value;
      }
    },
  },
  getters: {
    [DAYS](state: DayState): Day[] {
      return state.days.sort((a, b) => (a.date < b.date ? -1 : 1));
    },
  },
});

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDayStore, import.meta.hot));
}
