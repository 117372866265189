import UserService, { type UserDto } from "@/services/UserService";
import type { User } from "@/api/prisma-interfaces";
import { defineStore } from "pinia";
import { getErrorMessage } from "@/utils/ErrorHandler";

export interface UserState {
  currentUser: User | null;
  users: User[];
  loading: { [key: string]: boolean };
  count: number;
  error?: { [key: string]: any } | null;
}

// Actions
const LOGIN_USER = "LOGIN_USER";
const LOGOUT_USER = "LOGOUT_USER";
const SIGNUP_USER = "SIGNUP_USER";
const FORGOT_PASSWORD = "FORGOT_PASSWORD";
const VERIFY_RESET_PASSWORD_TOKEN = "VERIFY_RESET_PASSWORD_TOKEN";
const WELCOME_EMAIL = "WELCOME_EMAIL";
const UPDATED_EMAIL = "UPDATED_EMAIL";
const FETCH_USERS = "FETCH_USERS";
const UPDATE_USER = "UPDATE_USER";
const UPDATE_USER_PASSWORD = "UPDATE_USER_PASSWORD";
const DELETE_USER = "DELETE_USER";
const CREATE_USER = "CREATE_USER";
const GET_USER = "GET_USER";
const GET_USERS_BY_SCHOOL_ID = "GET_USERS_BY_SCHOOL_ID";
const GET_CONNECTED_USERS = "GET_CONNECTED_USERS";

// Getters
const CURRENT_USER = "CURRENT_USER";
const REFRESH_CURRENT_USER = "REFRESH_CURRENT_USER";
const USERS = "USERS";
const USERS_WITHOUT_CURRENT_USER = "USERS_WITHOUT_CURRENT_USER";

export const useUserStore = defineStore("user", {
  state: (): UserState => ({
    currentUser: null,
    loading: {},
    error: null,
    count: 0,
    users: [],
  }),
  actions: {
    async [LOGIN_USER](
      email: string,
      password: string,
    ): Promise<User | undefined> {
      this.setLoading(LOGIN_USER, true);
      try {
        const user = await UserService.signIn(email, password);
        const currentUser = await UserService.getUser(user.id);
        this.currentUser = currentUser;
        return user;
      } catch (error) {
        this.setError(LOGIN_USER, getErrorMessage(error));
      } finally {
        this.setLoading(LOGIN_USER, false);
      }
    },
    async [LOGOUT_USER]() {
      this.setLoading(LOGOUT_USER, true);
      try {
        const user = await UserService.signOut();
        this.currentUser = null;
        return user;
      } catch (error) {
        this.setError(LOGOUT_USER, getErrorMessage(error));
      } finally {
        this.setLoading(LOGOUT_USER, false);
      }
    },
    async [SIGNUP_USER](
      email: string,
      password: string,
      fullName: string,
    ): Promise<User | undefined> {
      this.setLoading(SIGNUP_USER, true);
      try {
        const user = await UserService.signUp(email, password, fullName);
        this.currentUser = user;
        return user;
      } catch (error) {
        this.setError(SIGNUP_USER, getErrorMessage(error));
      } finally {
        this.setLoading(SIGNUP_USER, false);
      }
    },
    async [FORGOT_PASSWORD](email: string) {
      this.setLoading(FORGOT_PASSWORD, true);
      try {
        await UserService.forgotPassword(email);
      } catch (error) {
        this.setError(FORGOT_PASSWORD, getErrorMessage(error));
      } finally {
        this.setLoading(FORGOT_PASSWORD, false);
      }
    },
    async [VERIFY_RESET_PASSWORD_TOKEN](token: string, userId: string) {
      this.setLoading(VERIFY_RESET_PASSWORD_TOKEN, true);
      try {
        await UserService.verifyResetPasswordToken(token, userId);
      } catch (error) {
        this.setError(VERIFY_RESET_PASSWORD_TOKEN, getErrorMessage(error));
      } finally {
        this.setLoading(VERIFY_RESET_PASSWORD_TOKEN, false);
      }
    },
    async [WELCOME_EMAIL](userId: string) {
      this.setLoading(WELCOME_EMAIL, true);
      try {
        await UserService.sendWelcomeEmail(userId);
      } catch (error) {
        this.setError(WELCOME_EMAIL, getErrorMessage(error));
      } finally {
        this.setLoading(WELCOME_EMAIL, false);
      }
    },
    async [UPDATED_EMAIL](userId: string) {
      this.setLoading(UPDATED_EMAIL, true);
      try {
        await UserService.sendUpdatedEmailAddressEmail(userId);
      } catch (error) {
        this.setError(UPDATED_EMAIL, getErrorMessage(error));
      } finally {
        this.setLoading(UPDATED_EMAIL, false);
      }
    },
    async [REFRESH_CURRENT_USER]() {
      this.setLoading(REFRESH_CURRENT_USER, true);
      try {
        const user = await UserService.currentUser();
        if (user) {
          this.currentUser = user;
          return user;
        }
      } catch (error) {
        this.setError(REFRESH_CURRENT_USER, getErrorMessage(error));
      } finally {
        this.setLoading(REFRESH_CURRENT_USER, false);
      }
    },
    async [FETCH_USERS](take?: number, skip?: number) {
      this.setLoading(FETCH_USERS, true);
      try {
        const page = await UserService.getUsers(take, skip);
        this.users = page.users;
        this.count = page.total;
        return page;
      } catch (error) {
        this.setError(FETCH_USERS, getErrorMessage(error));
      } finally {
        this.setLoading(FETCH_USERS, false);
      }
    },
    async [UPDATE_USER](user: UserDto) {
      this.setLoading(UPDATE_USER, true);
      try {
        const updatedUser = await UserService.updateUser({ user });
        const index = this.users.findIndex((u) => u.id === updatedUser.id);
        if (index !== -1) {
          this.users.splice(index, 1, updatedUser);
        }
      } catch (error) {
        this.setError(UPDATE_USER, getErrorMessage(error));
      } finally {
        this.setLoading(UPDATE_USER, false);
      }
    },
    async [UPDATE_USER_PASSWORD](userId: string, password: string) {
      this.setLoading(UPDATE_USER_PASSWORD, true);
      try {
        await UserService.updateUserPassword(userId, password);
      } catch (error) {
        this.setError(UPDATE_USER_PASSWORD, getErrorMessage(error));
      } finally {
        this.setLoading(UPDATE_USER_PASSWORD, false);
      }
    },
    async [DELETE_USER](userId: string) {
      this.setLoading(DELETE_USER, true);
      try {
        await UserService.deleteUser(userId);
        const index = this.users.findIndex((u) => u.id === userId);
        if (index !== -1) {
          this.users.splice(index, 1);
        }
      } catch (error) {
        this.setError(DELETE_USER, getErrorMessage(error));
      } finally {
        this.setLoading(DELETE_USER, false);
      }
    },
    async [CREATE_USER](user: UserDto) {
      this.setLoading(CREATE_USER, true);
      try {
        const createdUser = await UserService.createUser(user);
        this.users.unshift(createdUser);
        return createdUser;
      } catch (error) {
        this.setError(CREATE_USER, getErrorMessage(error));
      } finally {
        this.setLoading(CREATE_USER, false);
      }
    },
    async [GET_USER](userId: string) {
      this.setLoading(GET_USER, true);
      try {
        const user = await UserService.getUser(userId);
        return user;
      } catch (error) {
        this.setError(GET_USER, getErrorMessage(error));
      } finally {
        this.setLoading(GET_USER, false);
      }
    },
    async [GET_USERS_BY_SCHOOL_ID](schoolId: string) {
      this.setLoading(GET_USERS_BY_SCHOOL_ID, true);
      try {
        const users = await UserService.getUsersBySchoolId(schoolId);
        return users;
      } catch (error) {
        this.setError(GET_USERS_BY_SCHOOL_ID, getErrorMessage(error));
      } finally {
        this.setLoading(GET_USERS_BY_SCHOOL_ID, false);
      }
    },
    async [GET_CONNECTED_USERS](userId: string) {
      this.setLoading(GET_CONNECTED_USERS, true);
      try {
        const users = await UserService.getConnectedUsers(userId);
        return users;
      } catch (error) {
        this.setError(GET_CONNECTED_USERS, getErrorMessage(error));
      } finally {
        this.setLoading(GET_CONNECTED_USERS, false);
      }
    },
    setLoading(action: string, value: boolean) {
      if (value) {
        this.setError(action, null);
      }
      this.loading[action] = value;
    },
    setError(action: string, value: any) {
      this.error = { ...this.error, [action]: value };
      if (value) {
        throw value;
      }
    },
  },
  getters: {
    [CURRENT_USER](): User | null {
      return this.currentUser;
    },
    [USERS](state: UserState): User[] {
      return state.users;
    },

    [USERS_WITHOUT_CURRENT_USER](state: UserState): User[] {
      return state.users.filter((user) => user.id !== state.currentUser?.id);
    },
  },
});
