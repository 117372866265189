import BulletinService, { type BulletinDto } from "@/services/BulletinService";
import { acceptHMRUpdate, defineStore } from "pinia";
import type { Bulletin, District, Menu, School } from "@/api/prisma-interfaces";
import { getErrorMessage } from "@/utils/ErrorHandler";

export interface BulletinState {
  bulletins: Bulletin[];
  loading: { [key: string]: boolean };
  count: number;
  error?: { [key: string]: any } | null;
}

export interface BulletinConnections {
  districts?: District[];
  schools?: School[];
  menus?: Menu[];
}

// Actions
export const FETCH_BULLETINS = "FETCH_BULLETINS";
export const UPDATE_BULLETIN = "UPDATE_BULLETIN";
export const UPDATE_BULLETIN_IMAGE = "UPDATE_BULLETIN_IMAGE";
export const DELETE_BULLETIN = "DELETE_BULLETIN";
export const CREATE_BULLETIN = "CREATE_BULLETIN";
export const GET_BULLETIN = "GET_BULLETIN";
export const GET_RELATED_BULLETINS_BY_IDS = "GET_RELATED_BULLETINS_BY_IDS";

// Getters
export const BULLETINS = "BULLETINS";
export const CONNECTIONS = "CONNECTIONS";

export const useBulletinStore = defineStore("bulletin", {
  state: (): BulletinState => ({
    bulletins: [],
    loading: {},
    count: 0,
    error: null,
  }),
  actions: {
    async [FETCH_BULLETINS](take?: number, skip?: number) {
      this.setLoading(FETCH_BULLETINS, true);
      try {
        const page = await BulletinService.getBulletins(take, skip);
        this.bulletins = page.bulletins.filter((b) => b.text?.trim() !== "");
        this.count = page.total;
        return page;
      } catch (error) {
        this.setError(FETCH_BULLETINS, getErrorMessage(error));
      } finally {
        this.setLoading(FETCH_BULLETINS, false);
      }
    },
    async [UPDATE_BULLETIN](bulletin: BulletinDto) {
      this.setLoading(UPDATE_BULLETIN, true);
      try {
        const updatedBulletin = await BulletinService.updateBulletin(bulletin);
        const index = this.bulletins.findIndex(
          (d) => d.id === updatedBulletin.id,
        );
        if (index !== -1) {
          this.bulletins.splice(index, 1, updatedBulletin);
        }
      } catch (error) {
        this.setError(UPDATE_BULLETIN, getErrorMessage(error));
      } finally {
        this.setLoading(UPDATE_BULLETIN, false);
      }
    },
    async [UPDATE_BULLETIN_IMAGE](bulletinId: string, image: FormData) {
      this.setLoading(UPDATE_BULLETIN_IMAGE, true);
      try {
        const updatedBulletin = await BulletinService.updateBulletinImage(
          bulletinId,
          image,
        );
        const index = this.bulletins.findIndex(
          (d) => d.id === updatedBulletin.id,
        );
        if (index !== -1) {
          this.bulletins.splice(index, 1, updatedBulletin);
        }
        return updatedBulletin.image;
      } catch (error) {
        this.setError(UPDATE_BULLETIN_IMAGE, getErrorMessage(error));
      } finally {
        this.setLoading(UPDATE_BULLETIN_IMAGE, false);
      }
    },
    async [DELETE_BULLETIN](bulletinId: string) {
      this.setLoading(DELETE_BULLETIN, true);
      try {
        await BulletinService.deleteBulletin(bulletinId);
        const index = this.bulletins.findIndex((d) => d.id === bulletinId);
        if (index !== -1) {
          this.bulletins.splice(index, 1);
        }
      } catch (error) {
        this.setError(DELETE_BULLETIN, getErrorMessage(error));
      } finally {
        this.setLoading(DELETE_BULLETIN, false);
      }
    },
    async [CREATE_BULLETIN](bulletin: BulletinDto) {
      this.setLoading(CREATE_BULLETIN, true);
      try {
        const createdBulletin = await BulletinService.createBulletin(bulletin);
        this.bulletins.unshift(createdBulletin);
        return createdBulletin;
      } catch (error) {
        this.setError(CREATE_BULLETIN, getErrorMessage(error));
      } finally {
        this.setLoading(CREATE_BULLETIN, false);
      }
    },
    async [GET_BULLETIN](bulletinId: string) {
      this.setLoading(GET_BULLETIN, true);
      try {
        const bulletin = await BulletinService.getBulletin(bulletinId);
        return bulletin;
      } catch (error) {
        this.setError(GET_BULLETIN, getErrorMessage(error));
      } finally {
        this.setLoading(GET_BULLETIN, false);
      }
    },
    async [GET_RELATED_BULLETINS_BY_IDS](
      menuId: string,
      schoolId: string,
      districtId: string,
    ) {
      this.loading[GET_RELATED_BULLETINS_BY_IDS] = true;
      try {
        const bulletins = await BulletinService.getRelatedBulletinsByIds(
          menuId,
          schoolId,
          districtId,
        );
        return bulletins;
      } catch (error) {
        this.error = { ...this.error, [GET_RELATED_BULLETINS_BY_IDS]: error };
      } finally {
        this.loading[GET_RELATED_BULLETINS_BY_IDS] = false;
      }
    },
    setLoading(action: string, value: boolean) {
      if (value) {
        this.setError(action, null);
      }
      this.loading[action] = value;
    },
    setError(action: string, value: any) {
      this.error = { ...this.error, [action]: value };
      if (value) {
        throw value;
      }
    },
  },
  getters: {
    [BULLETINS](state: BulletinState): Bulletin[] {
      return state.bulletins;
    },
    [CONNECTIONS](): (bulletin: Bulletin) => BulletinConnections {
      return (bulletin: Bulletin) => {
        const districts = bulletin.Districts;
        const schools = bulletin.Schools;
        const menus = bulletin.Menus;
        return {
          districts,
          schools,
          menus,
        };
      };
    },
  },
});

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useBulletinStore, import.meta.hot));
}
