export function getCurrentWeek(): number {
  return getWeekNumberByDate(new Date());
}

export function getDatesInWeek(week: number, year: number): Date[] {
  const workDaysOfWeek = [];
  const simpleDate = new Date(Date.UTC(year, 0, 4)); // Jan 4 is always in week 1 of ISO week date system
  const dayOfWeek = simpleDate.getUTCDay() || 7; // Get day of week, convert Sunday (0) to 7
  const startDate = new Date(simpleDate);
  startDate.setUTCDate(
    startDate.getUTCDate() + (week - 1) * 7 - (dayOfWeek - 1),
  ); // Calculate the start date of the given week number

  // Get workdays (Monday to Friday) of the given week
  for (let i = 0; i < 5; i++) {
    const date = new Date(startDate);
    date.setUTCDate(startDate.getUTCDate() + i);
    workDaysOfWeek.push(
      new Date(
        Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()),
      ),
    );
  }
  return workDaysOfWeek;
}

export function getWeeksInYear(year: number): number {
  const lastDayOfYear = new Date(year, 11, 31); // December 31st

  // Get the ISO week number for the first and last day of the year
  const lastWeek = getWeekNumberByDate(lastDayOfYear);

  // Return the number of ISO weeks in the year
  return lastWeek === 1 ? 52 : lastWeek;
}

export function getDaysInMonth(month: number, year: number): number {
  return new Date(year, month, 0).getDate();
}

export function getWeekNumberByDate(date: Date): number {
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1
  const week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1
  const number =
    1 +
    Math.round(
      ((date.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7,
    );

  return number;
}

// Returns the start and end date of a given week and year
export function getWeekDates(weekNumber: number, year: number): [Date, Date] {
  // Jan 4 is always in week 1 of ISO week date system
  const simpleDate = new Date(Date.UTC(year, 0, 4));

  // Get day of week, convert Sunday (0) to 7
  const dayOfWeek = simpleDate.getUTCDay() || 7;

  // Calculate the start date (Monday) of the given week number
  const startDate = new Date(simpleDate);
  startDate.setUTCDate(
    simpleDate.getUTCDate() + (weekNumber - 1) * 7 - (dayOfWeek - 1),
  );

  // Calculate the end date (Sunday) of the given week
  const endDate = new Date(startDate);
  endDate.setUTCDate(startDate.getUTCDate() + 6);

  return [
    new Date(
      Date.UTC(
        startDate.getUTCFullYear(),
        startDate.getUTCMonth(),
        startDate.getUTCDate(),
      ),
    ),
    new Date(
      Date.UTC(
        endDate.getUTCFullYear(),
        endDate.getUTCMonth(),
        endDate.getUTCDate(),
      ),
    ),
  ];
}

export * as dateUtils from "./DateUtils";
