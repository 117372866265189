<script setup lang="ts">
import { computed, inject } from "vue";
import ConnectedAppForm from "../ConnectedAppForm.vue";
import Button from "primevue/button";
import Divider from "primevue/divider";
import { useConfirm } from "primevue/useconfirm";
import type { ConnectedApp } from "@/api/prisma-interfaces";
import { useFeedbackToast } from "@/composables/useFeedbackToast";
import { useI18n } from "vue-i18n";
import { useConnectedAppStore } from "@/stores/ConnectedAppStore";
import { useRouter } from "vue-router";

const props = defineProps<{
  connectedApp: ConnectedApp;
}>();

const { t } = useI18n();
const confirm = useConfirm();
const { useSuccessToast, useErrorToast } = useFeedbackToast();
const router = useRouter();

const resetSelectedApp = inject("resetSelectedApp", () => {});

const connectedAppStore = useConnectedAppStore();

const CONNECTED_APP = computed(() => {
  return props.connectedApp;
});

const UPDATE_CONNECTED_APP = async () => {
  try {
    await connectedAppStore.UPDATE_CONNECTED_APP(props.connectedApp);
    useSuccessToast(t("update_", { item: t("connected_app") }));
  } catch (error) {
    useErrorToast(t("update_", { item: t("connected_app") }));
  }
};

const DELETE_CONNECTED_APP = async () => {
  if (!props.connectedApp.id) return;
  try {
    await connectedAppStore.DELETE_CONNECTED_APP(props.connectedApp.id);
    useSuccessToast(t("delete_", { item: t("connected_app") }));
    router.push({ name: "ConnectedAppView" });
    resetSelectedApp();
  } catch (error) {
    useErrorToast(t("delete_", { item: t("connected_app") }));
  }
};

const UPDATE_CONNECTED_APP_LOADING = computed(
  () => connectedAppStore.$state.loading.UPDATE_CONNECTED_APP,
);

const DELETE_CONNECTED_APP_LOADING = computed(
  () => connectedAppStore.$state.loading.DELETE_CONNECTED_APP,
);

const confirmDeleteApp = () => {
  confirm.require({
    message: t("delete_confirm_", { item: CONNECTED_APP.value.name }),
    header: t("delete_", { item: t("connected_app") }),
    rejectLabel: t("cancel"),
    acceptLabel: t("delete"),
    acceptClass: "bg-red-700 hover:bg-red-600 ring-red-700 focus:ring-red-700",
    accept: () => {
      DELETE_CONNECTED_APP();
    },
    reject: () => {},
  });
};
</script>
<template>
  <connected-app-form
    :connected-app="CONNECTED_APP"
    @update:name="CONNECTED_APP.name = $event"
    @update:token="CONNECTED_APP.token = $event"
    @update:is-enabled="CONNECTED_APP.isEnabled = $event"
  />

  <Divider />
  <div class="mt-2 flex justify-end">
    <div class="flex gap-5">
      <Button
        class="bg-red-700"
        severity="danger"
        :label="t('delete')"
        :loading="DELETE_CONNECTED_APP_LOADING"
        @click="confirmDeleteApp"
      >
      </Button>
      <Button
        :loading="UPDATE_CONNECTED_APP_LOADING"
        :label="t('save')"
        @click="UPDATE_CONNECTED_APP"
      />
    </div>
  </div>
</template>
