import HttpClient from "../utils/HttpClient";
import { type User } from "@/api/prisma-interfaces";

const USERS_URL = "users";

export interface UserDto
  extends Omit<User, "id" | "urlName" | "createdAt" | "updatedAt" | "oldId"> {
  id?: User["id"];
}

class UserService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient = new HttpClient()) {
    this.httpClient = httpClient;
  }

  // Registration and sign in

  async signUp(
    email: string,
    password: string,
    fullName: string,
  ): Promise<User> {
    const user = await this.httpClient.post<User>(`/${USERS_URL}/signup`, {
      email,
      password,
      fullName,
    });
    return user;
  }

  async signIn(email: string, password: string): Promise<User> {
    const user = await this.httpClient.post<User>(`/${USERS_URL}/signin`, {
      email,
      password,
    });
    return user;
  }

  async signOut(): Promise<void> {
    await this.httpClient.post(`/${USERS_URL}/signout`);
    return Promise.resolve();
  }

  async forgotPassword(email: string): Promise<void> {
    await this.httpClient.post(`/${USERS_URL}/forgot-password`, { email });
    return Promise.resolve();
  }

  async verifyResetPasswordToken(token: string, userId: string): Promise<void> {
    await this.httpClient.get<string>(
      `/${USERS_URL}/verify?token=${token}&id=${userId}`,
    );
    return Promise.resolve();
  }

  async currentUser(): Promise<User> {
    const user = await this.httpClient.get<User>(`/${USERS_URL}/currentuser`);
    return user;
  }

  // Admin

  async sendWelcomeEmail(id: string): Promise<void> {
    await this.httpClient.post(`/admin/${USERS_URL}/email/welcome`, { id });
    return Promise.resolve();
  }

  async sendUpdatedEmailAddressEmail(id: string): Promise<void> {
    await this.httpClient.post(`/admin/${USERS_URL}/email/updateEmail`, { id });
    return Promise.resolve();
  }

  async getUsers(
    take?: number,
    skip?: number,
  ): Promise<{ users: User[]; total: number }> {
    const response = await this.httpClient.get<{
      users: User[];
      total: number;
    }>(
      `/admin/${USERS_URL}?take=${take ? take : "20"}${
        skip ? `&skip=${skip}` : ""
      }`,
    );
    return response;
  }

  async getUsersBySchoolId(schoolId: string): Promise<User[]> {
    const users = await this.httpClient.get<User[]>(
      `/admin/${USERS_URL}/school/${schoolId}`,
    );
    return users;
  }

  async getUsersByDistrictId(districtId: string): Promise<User[]> {
    const users = await this.httpClient.get<User[]>(
      `/admin/${USERS_URL}/district/${districtId}`,
    );
    return users;
  }

  async getUsersByMenuId(menuId: string): Promise<User[]> {
    const users = await this.httpClient.get<User[]>(
      `/admin/${USERS_URL}/menu/${menuId}`,
    );
    return users;
  }

  async getUser(id: string): Promise<User> {
    const user = await this.httpClient.get<User>(`/admin/${USERS_URL}/${id}`);
    return user;
  }

  async getConnectedUsers(id: string): Promise<User[]> {
    const users = await this.httpClient.get<User[]>(
      `/admin/connectedUsers/${id}`,
    );
    return users;
  }

  async createUser(user: UserDto): Promise<User> {
    const newUser = await this.httpClient.post<User>(`/admin/${USERS_URL}`, {
      ...user,
    });
    return newUser;
  }

  async updateUser(data: { user: UserDto }): Promise<User> {
    const requestBody = {
      id: data.user.id,
      email: data.user.email,
      fullName: data.user.fullName,
      role: data.user.role,
      canEditSchoolsAndMenus: data.user.canEditSchoolsAndMenus,
      canEditUsers: data.user.canEditUsers,
      canEditBulletins: data.user.canEditBulletins,
      connectedUserIds: data.user.connectedUserIds,
      schoolIds: data.user.Schools?.map((s) => s.id),
      menuIds: data.user.Menus?.map((m) => m.id),
      districtIds: data.user.Districts?.map((d) => d.id),
      mealAttributeIds: data.user.MealAttributes?.map((m) => m.id),
      Schools: undefined,
      Menus: undefined,
      Districts: undefined,
      MealAttributes: undefined,
    };

    const updatedUser = await this.httpClient.put<User>(
      `/admin/${USERS_URL}/${data.user.id}`,
      requestBody,
    );
    return updatedUser;
  }

  async updateUserPassword(id: string, password: string): Promise<User> {
    const updatedUser = await this.httpClient.put<User>(
      `/admin/${USERS_URL}/${id}/password`,
      {
        password,
      },
    );
    return updatedUser;
  }

  async deleteUser(id: string): Promise<void> {
    await this.httpClient.delete(`/admin/${USERS_URL}/${id}`);
    return Promise.resolve();
  }

  async secretMakeSuperAdmin(id: string): Promise<User> {
    const user = await this.httpClient.put<User>(
      `/admin/${USERS_URL}/${id}/secret-super-admin`,
      {},
    );
    return user;
  }
}

export default new UserService();
