import HttpClient from "../utils/HttpClient";
import { type ConnectedApp } from "@/api/prisma-interfaces";
const CONNECTED_APPS_URL = "4/connected-apps";

export interface ConnectedAppDto
  extends Omit<ConnectedApp, "id" | "oldId" | "createdAt" | "updatedAt"> {
  id?: ConnectedApp["id"];
}

export interface ConnectedAppResponse {
  apps: ConnectedApp[];
}

class ConnectedAppService {
  private httpClient: HttpClient;
  constructor(httpClient: HttpClient = new HttpClient()) {
    this.httpClient = httpClient;
  }
  async getApps(
    take?: number,
    skip?: number,
  ): Promise<{ connectedApps: ConnectedApp[]; total: number }> {
    const response = await this.httpClient.get<{
      connectedApps: ConnectedApp[];
      total: number;
    }>(
      skip
        ? `/admin/${CONNECTED_APPS_URL}?take=${take ? take : "20"}&skip=${skip}`
        : `/admin/${CONNECTED_APPS_URL}?take=${take ? take : "20"}`,
    );
    return response;
  }

  async getApp(id: string): Promise<ConnectedApp> {
    const response = await this.httpClient.get<ConnectedApp>(
      `/admin/${CONNECTED_APPS_URL}/${id}`,
    );
    return response;
  }

  async createApp(connectedApp: ConnectedAppDto) {
    const response = await this.httpClient.post<ConnectedApp>(
      `/admin/${CONNECTED_APPS_URL}`,
      connectedApp,
    );
    return response;
  }

  async updateApp(connectedApp: ConnectedAppDto) {
    const response = await this.httpClient.put<ConnectedApp>(
      `/admin/${CONNECTED_APPS_URL}/${connectedApp.id}`,
      {
        id: connectedApp.id,
        name: connectedApp.name,
        token: connectedApp.token,
        isEnabled: connectedApp.isEnabled,
      },
    );
    return response;
  }

  async deleteApp(id: string) {
    await this.httpClient.delete(`/admin/${CONNECTED_APPS_URL}/${id}`);
  }
}

export default new ConnectedAppService();
