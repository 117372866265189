<script setup lang="ts">
import { ref } from "vue";
import AutoComplete from "primevue/autocomplete";
import SearchService, { type SearchResult } from "@/services/SearchService";
import {
  BuildingLibraryIcon,
  BuildingOfficeIcon,
  MapPinIcon,
  NewspaperIcon,
  UserIcon,
  ChatBubbleBottomCenterTextIcon,
  MagnifyingGlassIcon,
  Square2StackIcon,
} from "@heroicons/vue/24/outline";
import { useRouter } from "vue-router";
import type {
  Bulletin,
  District,
  Menu,
  Province,
  School,
  User,
} from "@/api/prisma-interfaces";

type ItemType =
  | "district"
  | "province"
  | "user"
  | "school"
  | "menu"
  | "bulletin"
  | "connected_app"
  | "meal_attribute";

const props = defineProps<{
  itemType?: ItemType;
  dropdown?: boolean;
}>();

const router = useRouter();
const selectedResult = ref();
const searchResult = ref();
let debounceTimer: NodeJS.Timeout;

const search = (event: any) => {
  let query = event.query;

  // Clear the previous timer if it exists
  if (debounceTimer) {
    clearTimeout(debounceTimer);
  }

  // Set a new timer
  debounceTimer = setTimeout(async () => {
    const type = props.itemType;
    let groupedResults;

    if (type) {
      let result;
      switch (type) {
        case "district":
          result = await SearchService.searchDistricts(query);
          break;
        case "province":
          result = await SearchService.searchProvinces(query);
          break;
        case "user":
          result = await SearchService.searchUsers(query);
          break;
        case "school":
          result = await SearchService.searchSchools(query);
          break;
        case "menu":
          result = await SearchService.searchMenus(query);
          break;
        case "bulletin":
          result = await SearchService.searchBulletins(query);
          break;
        case "connected_app":
          result = await SearchService.searchConnectedApps(query);
          break;
        case "meal_attribute":
          result = await SearchService.searchMealAttributes(query);
          break;
      }
      groupedResults = result.reduce((grouped: any, item) => {
        const mappedItem = {
          value: item.id,
          label:
            type === "user"
              ? (item as User).fullName
              : type === "bulletin"
              ? (item as Bulletin).text
              : (item as District | Province | School | Menu | SearchResult)
                  .name,
          type: type,
          parentName:
            type === "district"
              ? (item as District)?.Province?.name
              : type === "school"
              ? (item as School)?.District?.name
              : undefined,
        };
        (grouped[type] = grouped[type] || []).push(mappedItem);
        return grouped;
      }, {});
    } else {
      let result = await SearchService.search(query);

      groupedResults = result.reduce((grouped: any, item) => {
        const mappedItem = {
          value: item.id,
          label: item.name,
          type: item.type,
          parentName: item.parentName,
        };
        (grouped[item.type] = grouped[item.type] || []).push(mappedItem);
        return grouped;
      }, {});
    }

    const formattedResults = Object.keys(groupedResults).map((key) => ({
      label: key,
      items: groupedResults[key],
    }));

    searchResult.value = formattedResults;
  }, 1000);
};

const getImage = (type: string) => {
  switch (type) {
    case "district":
      return BuildingLibraryIcon;
    case "province":
      return MapPinIcon;
    case "user":
      return UserIcon;
    case "school":
      return BuildingOfficeIcon;
    case "menu":
      return NewspaperIcon;
    case "bulletin":
      return ChatBubbleBottomCenterTextIcon;
    case "connected_app":
      return Square2StackIcon;
  }
};
async function onSelect(event: any) {
  const item = event.value;

  switch (item.type) {
    case "district":
      router.push({ name: "DistrictDetail", params: { id: item.value } });
      break;
    case "province":
      router.push({ name: "ProvinceDetail", params: { id: item.value } });
      break;
    case "user":
      router.push({ name: "UserDetail", params: { id: item.value } });
      break;
    case "school":
      router.push({ name: "SchoolDetail", params: { id: item.value } });
      break;
    case "menu":
      router.push({ name: "MenuDetail", params: { id: item.value } });
      break;
    case "connected_app":
      router.push({ name: "ConnectedAppDetail", params: { id: item.value } });
      break;
    case "bulletin":
      router.push({ name: "BulletinDetail", params: { id: item.value } });
      break;
    case "meal_attribute":
      router.push({
        name: "MealAttributesDetail",
        params: { id: item.value },
      });
      break;
  }
  selectedResult.value = null;
}

declare global {
  interface String {
    capitalizeFirstChar(): string;
  }
}

String.prototype.capitalizeFirstChar = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
</script>

<template>
  <div class="flex w-full flex-row rounded-md bg-white">
    <div class="self-center pl-1">
      <MagnifyingGlassIcon class="h-full w-5 text-gray-400" />
    </div>
    <AutoComplete
      v-model="selectedResult"
      :suggestions="searchResult"
      option-label="label"
      option-group-label="label"
      option-group-children="items"
      :placeholder="$t('search')"
      class="p-autocomplete h-full w-full"
      input-class="w-full border-none focus:border-0 "
      :empty-search-message="$t('no_results_found')"
      :dropdown="dropdown"
      :pt="{
        dropdownButton: {
          root: {
            class:
              'relative text-sm leading-none items-center inline-flex text-center align-bottom rounded-r-md px-2.5 py-1.5 -ml-px text-surface-600 dark:text-surface-100 bg-surface-100 dark:bg-surface-800 border-l border-surface-300 dark:border-surface-700 ring-inset ring-surface-300 dark:ring-surface-700 hover:bg-surface-200 dark:hover:bg-surface-700 focus:outline-none focus:outline-offset-0 focus:ring-1 focus:ring-primary-500 dark:focus:ring-primary-400',
          },
        },
      }"
      @complete="search"
      @item-select="onSelect"
    >
      <template #optiongroup="groupProps">
        <div class="flex">
          <component
            :is="getImage(groupProps.option.label)"
            v-if="getImage(groupProps.option.label)"
            class="mr-2 h-5 w-5"
          />
          <div>{{ $t(groupProps.option.label, 2) }}</div>
        </div>
      </template>
      <template #option="itemProps">
        <span>{{ itemProps.option.label }}</span>
        <span v-if="itemProps.option.parentName" class="text-gray-400">{{
          ` - ${itemProps.option.parentName}`
        }}</span>
      </template>
    </AutoComplete>
  </div>
</template>
