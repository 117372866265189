<script setup lang="ts">
import { useUserStore } from "@/stores/UserStore";
import { computed, reactive, watch, watchEffect } from "vue";
import { onMounted } from "vue";
import { useRoute, useRouter, RouterLink, RouterView } from "vue-router";
import SearchField from "@/components/shared/SearchField.vue";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
} from "@headlessui/vue";
import {
  Bars3Icon,
  XMarkIcon,
  ChevronDownIcon,
  UserIcon,
} from "@heroicons/vue/24/outline";
import Avatar from "primevue/avatar";
import { Role } from "@/api/prisma-interfaces";
import LanguageSwitcher from "@/components/shared/LanguageSwitcher.vue";
import { useI18n } from "vue-i18n";
import { useFeedbackToast } from "@/composables/useFeedbackToast";
import * as config from "../config";

const userStore = useUserStore();
const { useErrorToast } = useFeedbackToast();
const LOGOUT_USER = async () => {
  try {
    await userStore.LOGOUT_USER();
    router.push("/");
  } catch (error) {
    console.error(error);
    useErrorToast(t("sign_out_error"));
  }
};

const route = useRoute();
const { t } = useI18n();
const CURRENT_USER = computed(() => userStore.currentUser);

const navigation = computed(() => [
  {
    href: "/admin/province",
    name: "province",
    current: false,
    show: CURRENT_USER.value?.role === Role.superAdmin,
  },
  {
    href: "/admin/district",
    name: "district",
    current: false,
    show:
      (CURRENT_USER.value?.Districts &&
        CURRENT_USER.value.Districts.length > 0) ||
      CURRENT_USER.value?.role === Role.superAdmin,
  },
  {
    href: "/admin/menu",
    name: "menu",
    current: false,
    show: true,
  },
  {
    href: "/admin/school",
    name: "school",
    current: false,
    show:
      (CURRENT_USER.value?.Schools && CURRENT_USER.value.Schools.length > 0) ||
      (CURRENT_USER.value?.Districts &&
        CURRENT_USER.value.Districts.length > 0) ||
      CURRENT_USER.value?.role === Role.superAdmin,
  },
  {
    href: "/admin/user",
    name: "user",
    current: false,
    show: true,
  },
  {
    href: "/admin/bulletin",
    name: "bulletin",
    current: false,
    show:
      CURRENT_USER.value?.canEditBulletins ||
      CURRENT_USER.value?.role === Role.superAdmin,
  },
  {
    href: "/admin/meal-attributes",
    name: "meal_attribute",
    current: false,
    show: CURRENT_USER.value?.role === Role.superAdmin,
  },
  {
    href: "/admin/requested-schools",
    name: "requested_school",
    show: CURRENT_USER.value?.role === Role.superAdmin,
  },
  {
    href: "/admin/connected-app",
    name: "connected_app",
    current: false,
    show: CURRENT_USER.value?.role === Role.superAdmin,
  },
]);
const profileNavigation = computed(() => [
  { name: "my_profile", href: `/admin/user/${CURRENT_USER.value?.id}` },
  { name: "sign_out", href: "/", action: LOGOUT_USER },
]);

const state = reactive({
  loginModal: false,
  sidebarOpen: false,
  navigation: navigation.value,
  profileNavigation: profileNavigation.value,
});

watch(navigation, (newNavigation) => {
  state.navigation = newNavigation;
});

watch(profileNavigation, (newProfileNavigation) => {
  state.profileNavigation = newProfileNavigation;
});

const router = useRouter();

router.beforeEach((to, from, next) => {
  state.sidebarOpen = false;
  next();
});

onMounted(() => {
  userStore.REFRESH_CURRENT_USER();
});

const isCurrentRoute = (href: string) => route.path.includes(href);

watchEffect(() => {
  state.navigation = state.navigation
    .filter((item) => item.show)
    .map((item) => ({
      ...item,
      current: item.href === route.path,
    }));
});
</script>

<template>
  <div>
    <TransitionRoot as="template" :show="state.sidebarOpen">
      <Dialog
        as="div"
        class="relative z-50 lg:hidden"
        @close="state.sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div
                  class="absolute left-full top-0 flex w-16 justify-center pt-5"
                >
                  <button
                    type="button"
                    class="-m-2.5 p-2.5"
                    @click="state.sidebarOpen = false"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div
                class="bg-gradient-[189deg] from-6% flex grow flex-col overflow-y-auto border-gray-900 bg-gradient-to-b from-[#0b374a] to-[#020618] px-8 pb-4"
              >
                <router-link to="/admin/menu">
                  <div class="row flex items-center">
                    <img
                      class="-ml-4 h-24 w-24"
                      src="../assets/skolmaten_transparent_background.svg"
                      alt="IST Logo"
                    />

                    <span
                      v-if="config.nodeEnv !== 'production'"
                      class="text-sm text-white"
                      >{{ config.nodeEnv }}</span
                    >
                  </div>
                </router-link>

                <nav class="flex flex-1 flex-col">
                  <ul role="list" class="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in state.navigation" :key="item.name">
                          <router-link
                            :to="item.href"
                            :class="[
                              isCurrentRoute(item.href)
                                ? ' text-amber-400 hover:bg-cyan-900'
                                : 'text-white hover:bg-cyan-900 hover:text-white',
                              'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                            ]"
                          >
                            {{ t(item.name) }}
                          </router-link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <a
                    href="https://www.ist.com/sv/kundcenter"
                    target="_blank"
                    class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-white hover:cursor-pointer hover:bg-cyan-900"
                    >{{ t("contact_support") }}</a
                  >
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div
      class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-[min(18rem,278px)] lg:flex-col"
    >
      <!-- Sidebar component, swap this element with another sidebar if you like -->

      <div
        class="bg-gradient-[189deg] from-6% flex grow flex-col overflow-y-auto border-gray-900 bg-slate-950 bg-gradient-to-b from-[#0b374a] to-[#020618] px-8 pb-4"
      >
        <router-link to="/admin/menu">
          <div class="row flex items-center">
            <img
              class="-ml-4 h-24 w-24"
              src="../assets/skolmaten_transparent_background.svg"
              alt="IST Logo"
            />

            <span
              v-if="config.nodeEnv !== 'production'"
              class="text-sm text-white"
              >{{ config.nodeEnv }}</span
            >
          </div>
        </router-link>

        <nav class="flex flex-1 flex-col">
          <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li v-for="item in state.navigation" :key="item.name">
                  <router-link
                    :to="item.href"
                    :class="[
                      isCurrentRoute(item.href)
                        ? ' text-amber-400 hover:bg-cyan-900'
                        : 'text-white hover:bg-cyan-900 hover:text-white',
                      'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 ',
                    ]"
                  >
                    {{ t(item.name, 2) }}
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
          <a
            href="https://www.ist.com/sv/kundcenter"
            target="_blank"
            class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-white hover:cursor-pointer hover:bg-cyan-900"
            >{{ t("contact_support") }}</a
          >
        </nav>
      </div>
    </div>

    <div class="lg:pl-[min(18rem,278px)]">
      <div
        class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8"
      >
        <button
          type="button"
          class="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          @click="state.sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>

        <!-- Separator -->
        <div class="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

        <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
          <div class="flex flex-1">
            <label for="search-field" class="sr-only">Search</label>
            <search-field class="w-full" />
          </div>
          <div class="flex items-center gap-x-4 lg:gap-x-6">
            <!-- Separator -->
            <div
              class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
              aria-hidden="true"
            />
            <LanguageSwitcher />
            <!-- Separator -->
            <div
              class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
              aria-hidden="true"
            />

            <!-- Profile dropdown -->
            <Menu as="div" class="relative">
              <MenuButton class="-m-1.5 flex items-center p-1.5">
                <span class="sr-only">Open user menu</span>
                <Avatar
                  shape="circle"
                  style="background-color: #1e293b; color: #fff"
                >
                  <template #icon>
                    <UserIcon class="h-6 w-6" aria-hidden="true" />
                  </template>
                </Avatar>
                <span class="hidden lg:flex lg:items-center">
                  <span
                    class="ml-2 text-sm font-semibold leading-6 text-gray-900"
                    aria-hidden="true"
                    >{{ CURRENT_USER?.fullName.split(" ")[0] }}</span
                  >
                  <ChevronDownIcon
                    class="ml-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </MenuButton>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-0 z-10 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
                >
                  <MenuItem
                    v-for="item in state.profileNavigation"
                    :key="item.name"
                  >
                    <router-link :to="item.href || ''">
                      <span
                        class="block px-5 py-2 text-sm text-slate-800 hover:bg-slate-800 hover:text-white"
                        @click="item.action"
                      >
                        {{ t(item.name) }}</span
                      >
                    </router-link>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
      </div>

      <main class="py-10">
        <div class="px-4 sm:px-6 lg:px-8">
          <!-- Your content -->
          <router-view />
        </div>
      </main>
    </div>
  </div>
</template>
