<script setup lang="ts">
import { dateUtils } from "@/utils/DateUtils";
import { computed, watchEffect, ref, watch, onMounted, inject } from "vue";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ExclamationCircleIcon,
} from "@heroicons/vue/20/solid";
import MenuRow from "./MenuRow.vue";
import { useDayStore } from "@/stores/DayStore";
import { useWeekStateStore } from "@/stores/WeekStateStore";
import type { DayDto } from "@/services/DayService";
import type { Menu, WeekState } from "@/api/prisma-interfaces";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Tag from "primevue/tag";
import { useFeedbackToast } from "@/composables/useFeedbackToast";
import { useI18n } from "vue-i18n";
import MenuDetailsConnections from "./MenuDetailsConnections.vue";
import { useMealAttributesStore } from "@/stores/MealAttributesStore";

const props = defineProps<{
  menu: Menu;
}>();

const { t } = useI18n();
const dayStore = useDayStore();
const weekStateStore = useWeekStateStore();
const mealAttributeStore = useMealAttributesStore();
const refetchMenu = inject("refetchMenu", () => {});

const selectedWeek = ref<number>(dateUtils.getCurrentWeek());

const year = ref<number>(new Date().getFullYear());
const weeks = computed(() => dateUtils.getWeeksInYear(year.value));
const dayNames = ["monday", "tuesday", "wednesday", "thursday", "friday"];
const changedDays = new Set<string>();

const showHolidaysDialog = ref(false);
const showConnectionsDialog = ref(false);
const holidayReason = ref<string>();
const holidayStartDate = ref<Date>();
const holidayEndDate = ref<Date>();
const invalidValues = ref(false);

const menuListRef = ref<HTMLElement>();
const weekListRefs = ref<HTMLElement[]>([]);

const skipUnwrap = { weekListRefs };

onMounted(async () => {
  mealAttributeStore.FETCH_MEAL_ATTRIBUTES();
  skipUnwrap.weekListRefs.value[selectedWeek.value].scrollIntoView({
    behavior: "smooth",
    block: "center",
  });

  const states = await weekStateStore.FETCH_WEEK_STATES_IN_YEAR(
    props.menu.id,
    year.value,
  );
  weekId.value = states?.find(
    (weekState) => weekState.week === selectedWeek.value,
  )?.id;
});

const { useSuccessToast, useErrorToast } = useFeedbackToast();

const dates = computed(() =>
  dateUtils.getDatesInWeek(selectedWeek.value, year.value),
);

const getStateStatus = (numOfDays: number) => {
  if (numOfDays === 5) {
    return 1;
  } else if (numOfDays > 0) {
    return 2;
  } else {
    return 3;
  }
};

const currentWeek = ref<WeekState>();
const weekId = ref();

watch(
  [() => props.menu.id, () => year.value, () => selectedWeek.value],
  async ([newMenuId, newYear, newWeek]) => {
    try {
      const states = await weekStateStore.FETCH_WEEK_STATES_IN_YEAR(
        newMenuId,
        newYear,
      );
      weekId.value = states?.find((weekState) => weekState.week === newWeek)
        ?.id;
    } catch (error) {
      console.error("Failed to fetch week states in year:", error);
    }
  },
);

watch(
  [() => props.menu.id, () => weekId.value, () => selectedWeek.value],
  async ([newMenuId, newWeekId]) => {
    try {
      if (newWeekId) {
        currentWeek.value = await weekStateStore.FETCH_WEEK_STATE(
          newMenuId,
          newWeekId,
        );
      } else {
        currentWeek.value = undefined;
      }
    } catch (error) {
      console.error("Failed to fetch week state:", error);
    }
  },
  { immediate: true },
);

const isSharedMenu = computed(() => {
  return (
    (props.menu.Districts && props.menu.Districts.length > 1) ||
    (props.menu.Schools && props.menu.Schools.length > 1)
  );
});

const WEEK_STATE_MAP = computed(() => {
  const weekStateMap: Record<number, WeekState> = [];
  weekStateStore.WEEK_STATES.map((weekState) => {
    weekStateMap[weekState.week] = weekState;
  });
  return weekStateMap;
});

const DAYS_MAP = computed(() => {
  const dayMap = new Map<Date, DayDto | null>();
  dates.value.map((date) => {
    const day = currentWeek.value?.Days?.find(
      (day) => new Date(day.date).toDateString() === date.toDateString(),
    );
    if (day && weekId.value) {
      dayMap.set(date, day);
    } else {
      dayMap.set(date, null);
    }
  });
  return dayMap;
});

const isDayEmpty = (day: DayDto) => {
  return (
    (!day.Meals ||
      day.Meals?.every((meal) => !meal || meal.name.trim() === "")) &&
    (day.cancelled === null || day.cancelled === undefined)
  );
};

const SAVE_DAYS = async () => {
  try {
    const updateDays: DayDto[] = [];
    const createDays: DayDto[] = [];
    const deleteDays: string[] = [];
    let trackState = 0;

    const weekState = WEEK_STATE_MAP.value[selectedWeek.value];
    let weekStateId = weekState?.id;
    if (!weekState) {
      const newWeekState = await weekStateStore.CREATE_WEEK_STATE({
        menuId: props.menu.id,
        year: year.value,
        week: selectedWeek.value,
        state: 0,
      });
      weekStateId = newWeekState ? newWeekState.id : weekStateId;
    }

    for (let i = 0; i < dates.value.length; i++) {
      const date = dates.value[i];
      const day = DAYS_MAP.value.get(date);

      if (changedDays.has(date.toISOString()) && day) {
        if (day.id) {
          if (isDayEmpty(day)) {
            deleteDays.push(day.id);
            trackState--;
          } else {
            updateDays.push({
              ...day,
              weekStateId: weekStateId,
            });
          }
        } else {
          if (!isDayEmpty(day)) {
            createDays.push({
              ...day,
              weekStateId: weekStateId,
            });
            trackState++;
          }
        }
      }

      if (day && day.id) {
        trackState++;
      }
    }

    if (updateDays.length > 0) {
      const updatedDays = await dayStore.UPDATE_DAYS(updateDays);
      if (updatedDays) {
        updatedDays.forEach((day) => {
          const dayDate = new Date(day.date).toDateString();
          DAYS_MAP.value.forEach((value, key) => {
            if (key.toDateString() === dayDate) {
              DAYS_MAP.value.set(key, day);
            }
          });
        });
      }
    }
    if (createDays.length > 0) {
      const days = await dayStore.CREATE_DAYS(createDays);

      // updates the days map to include the newly created days' ids
      const newDaysMap = new Map(
        days?.map((day) => [new Date(day.date).toDateString(), day]),
      );
      DAYS_MAP.value.forEach((day, date) => {
        if (day) {
          const dateString = date.toDateString();
          const newDay = newDaysMap.get(dateString);
          if (newDay) {
            day.id = newDay.id;
            day.Meals = newDay.Meals;
          }
        }
      });
    }
    if (deleteDays.length > 0) {
      await dayStore.DELETE_DAYS(props.menu.id, deleteDays);

      // updates the days map to remove the deleted days
      DAYS_MAP.value.forEach((day, date) => {
        if (day?.id) {
          if (deleteDays.includes(day?.id)) {
            DAYS_MAP.value.set(date, null);
          }
        }
      });
    }

    const state = getStateStatus(trackState);
    const existingWeek = WEEK_STATE_MAP.value[selectedWeek.value];
    if (existingWeek) {
      if (state === 3) {
        await weekStateStore.DELETE_WEEK_STATE(props.menu.id, existingWeek.id);
      } else {
        await weekStateStore.UPDATE_WEEK_STATE({
          ...existingWeek,
          state: state,
        });
      }
    }
    changedDays.clear();
    useSuccessToast(t("update_", { item: t("menu", 2) }));
  } catch (error) {
    console.error(error);
    useErrorToast(t("update_", { item: t("menu", 2) }));
  }
};

const SAVE_DAYS_LOADING = computed(() => {
  return (
    dayStore.$state.loading.UPDATE_DAYS ||
    dayStore.$state.loading.CREATE_DAYS ||
    dayStore.$state.loading.DELETE_DAYS
  );
});

const handleWeekChange = async (week: number) => {
  selectedWeek.value = week;

  const states = await weekStateStore.FETCH_WEEK_STATES_IN_YEAR(
    props.menu.id,
    year.value,
  );

  weekId.value = states?.find(
    (weekState) => weekState.week === selectedWeek.value,
  )?.id;
  if (weekId.value) {
    currentWeek.value = await weekStateStore.FETCH_WEEK_STATE(
      props.menu.id,
      weekId.value,
    );
  } else {
    currentWeek.value = undefined;
  }
  if (menuListRef.value) {
    menuListRef.value?.scrollTo(0, 0);
  }

  skipUnwrap.weekListRefs.value[selectedWeek.value].scrollIntoView({
    behavior: "smooth",
    block: "center",
  });
};

const updateDay = (day: DayDto, date: Date) => {
  changedDays.add(date.toISOString());
  DAYS_MAP.value.set(date, day);
};

const ADD_HOLIDAYS = async () => {
  if (holidayStartDate.value && holidayEndDate.value && holidayReason.value) {
    holidayStartDate.value = new Date(
      holidayStartDate.value.getTime() -
        holidayStartDate.value.getTimezoneOffset() * 60000,
    );
    holidayEndDate.value = new Date(
      holidayEndDate.value.getTime() -
        holidayEndDate.value.getTimezoneOffset() * 60000,
    );
    try {
      await weekStateStore.ADD_HOLIDAYS(
        props.menu.id,
        holidayStartDate.value,
        holidayEndDate.value,
        holidayReason.value,
      );
      holidayReason.value = undefined;
      holidayStartDate.value = undefined;
      holidayEndDate.value = undefined;
      refetchMenu();
      useSuccessToast(t("add_holidays"));
    } catch (error) {
      useErrorToast(t("add_holidays"));
    }

    showHolidaysDialog.value = false;
  } else {
    invalidValues.value = true;
  }
};

watchEffect(() => {
  if (holidayReason.value && holidayStartDate.value && holidayEndDate.value) {
    invalidValues.value = false;
  }
});
</script>

<template>
  <div
    class="rounded-b-lg border border-t-0 border-slate-200"
    :style="{ height: 'calc(100vh - 200px)' }"
  >
    <div class="flex h-full flex-row">
      <div
        class="flex min-w-fit flex-col items-center overflow-auto border-r border-slate-200"
      >
        <div class="flex flex-row items-center py-4">
          <ChevronLeftIcon class="h-6 w-6 fill-slate-800" @click="year--" />
          <span class="px-1 font-bold text-slate-800"
            >{{ t("year") }} {{ year }}</span
          >
          <ChevronRightIcon class="h-6 w-6 fill-slate-800" @click="year++" />
        </div>
        <div class="flex w-full flex-col">
          <span
            class="flex justify-center border-t border-slate-200 py-3 text-sm font-bold text-slate-800"
          >
            {{ t("week") }}
          </span>
          <ul class="w-full">
            <li
              v-for="week in weeks"
              :ref="skipUnwrap.weekListRefs"
              :key="week"
              class="cursor-pointer border-t border-slate-200 text-xs hover:bg-gray-100"
              :class="[
                week === selectedWeek
                  ? 'flex flex-col border-l-[3px] border-l-slate-800 bg-gray-50'
                  : '',
              ]"
            >
              <button
                class="w-full py-2 outline-offset-[-3px]"
                @click="handleWeekChange(week)"
              >
                <div class="mx-6 flex">
                  <div
                    class="flex h-7 w-7 flex-shrink-0 items-center justify-center rounded-full"
                    :class="[
                      'rounded-full',
                      selectedWeek === week ? 'bg-slate-800 text-white' : '',
                    ]"
                  >
                    <span>{{ week }}</span>
                  </div>
                  <div
                    v-if="WEEK_STATE_MAP[week]?.state === 1"
                    class="ml-3 flex items-center justify-center rounded-sm bg-blue-50 px-2 py-1 font-semibold"
                  >
                    <div class="rounded-full bg-emerald-500 p-1"></div>
                    <span class="ml-2">{{ t("done") }}</span>
                  </div>
                  <div
                    v-else-if="WEEK_STATE_MAP[week]?.state === 2"
                    class="ml-3 flex items-center justify-center rounded-sm bg-blue-50 px-2 py-1 font-semibold"
                  >
                    <div class="rounded-full bg-yellow-400 p-1"></div>
                    <span class="ml-2">{{ t("draft") }}</span>
                  </div>
                </div>
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex grow flex-col">
        <div
          class="flex flex-col justify-between gap-2 border-b border-slate-200 px-7 py-3 sm:flex-row sm:items-end"
        >
          <div>
            <p class="font-bold text-slate-800">
              {{ menu.name }} - {{ t("week") }} {{ selectedWeek }}
            </p>
            <div class="flex flex-col gap-2 sm:flex-row">
              <div
                v-if="WEEK_STATE_MAP[selectedWeek]?.state === 1"
                class="flex w-fit items-center justify-center rounded-sm bg-blue-50 px-2 py-1 text-xs font-semibold"
              >
                <div class="rounded-full bg-emerald-500 p-1"></div>
                <span class="ml-2">{{ t("done") }}</span>
              </div>
              <div
                v-else-if="WEEK_STATE_MAP[selectedWeek]?.state === 2"
                class="flex w-fit items-center justify-center rounded-sm bg-blue-50 px-2 py-1 text-xs font-semibold"
              >
                <div class="rounded-full bg-yellow-400 p-1"></div>
                <span class="ml-2">{{ t("draft") }}</span>
              </div>
              <div v-if="isSharedMenu" class="flex items-center">
                <Tag
                  v-tooltip.top="$t('shared_menu_description')"
                  :value="t('shared_menu')"
                >
                  <template #icon>
                    <ExclamationCircleIcon
                      class="mr-2 h-4 w-4 text-amber-400"
                    />
                  </template>
                </Tag>
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-2 sm:flex-row">
            <Button
              v-if="isSharedMenu"
              class="mr-2 flex w-fit justify-center bg-white font-semibold"
              severity="secondary"
              outlined
              @click="showConnectionsDialog = true"
            >
              <div class="flex justify-center text-slate-800">
                <span>{{ t("show_connections") }}</span>
              </div>
            </Button>
            <Button
              class="mr-2 flex w-fit justify-center bg-white font-semibold"
              severity="secondary"
              outlined
              @click="showHolidaysDialog = true"
            >
              <div class="flex justify-center text-slate-800">
                <span>{{ t("add_holidays") }}</span>
              </div>
            </Button>
            <Button
              class="flex w-fit justify-center font-semibold"
              wcag-label="Button with icon"
              :loading="SAVE_DAYS_LOADING"
              @click="SAVE_DAYS"
            >
              <div class="flex justify-center">
                <span>{{ t("publish") }}</span>
              </div>
            </Button>
          </div>
        </div>
        <div
          id="menuList"
          ref="menuListRef"
          class="h-full w-full overflow-auto"
        >
          <ul class="mt-10">
            <li v-for="(day, i) in DAYS_MAP" :key="i">
              <div>
                <menu-row
                  :menu-id="props.menu.id"
                  :day="day[1]"
                  :date="day[0]"
                  :no-of-items="props.menu.numberOfItems"
                  :day-name="t(dayNames[i])"
                  @update:day="updateDay($event, day[0])"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="showHolidaysDialog" modal closable dismissable-mask>
    <template #header>
      <h1 class="font-bold text-slate-800">{{ t("add_holidays") }}</h1>
    </template>
    <div>
      <span class="text-sm text-slate-600">{{
        t("select_range_and_add_reason")
      }}</span>
      <div class="mt-5">
        <span class="ml-1 mt-3 block text-sm font-semibold text-slate-800">
          {{ t("reason") }}
        </span>
        <input-text
          v-model="holidayReason"
          :class="{
            'border-0 ring-slate-300': !invalidValues,
            'border border-red-500': invalidValues,
            'w-full': true,
          }"
          :placeholder="t('reason')"
          :invalid="invalidValues"
        />
        <span class="ml-1 mt-3 block text-sm font-semibold text-slate-800">
          {{ t("start_date") }}
        </span>
        <Calendar
          v-model="holidayStartDate"
          class="w-full"
          :input-class="{
            'border-0 ring-slate-300': !invalidValues,
            'border border-red-500': invalidValues,
          }"
          show-icon
          :manual-input="false"
          placeholder="yyyy-mm-dd"
          date-format="yy-mm-dd"
          :invalid="invalidValues"
        />
        <span class="ml-1 mt-3 block text-sm font-semibold text-slate-800">
          {{ t("end_date") }}
        </span>
        <Calendar
          v-model="holidayEndDate"
          class="w-full"
          :input-class="{
            'border-0 ring-slate-300': !invalidValues,
            'border border-red-500': invalidValues,
          }"
          show-icon
          :manual-input="false"
          placeholder="yyyy-mm-dd"
          date-format="yy-mm-dd"
          :invalid="invalidValues"
        />
      </div>
      <div class="mt-5 flex justify-end gap-3 font-semibold">
        <Button
          class="flex justify-center"
          severity="secondary"
          outlined
          @click="
            () => {
              showHolidaysDialog = false;
              invalidValues = false;
              holidayReason = undefined;
              holidayStartDate = undefined;
              holidayEndDate = undefined;
            }
          "
        >
          <div class="flex justify-center">
            <span>{{ t("cancel") }}</span>
          </div>
        </Button>
        <Button class="flex justify-center" @click="ADD_HOLIDAYS">
          <div class="flex justify-center">
            <span>{{ t("save") }}</span>
          </div>
        </Button>
      </div>
    </div>
  </Dialog>
  <Dialog
    v-if="menu"
    v-model:visible="showConnectionsDialog"
    modal
    closable
    dismissable-mask
  >
    <template #header>
      <h1 class="font-bold text-slate-800">{{ t("connections") }}</h1>
    </template>
    <div>
      <menu-details-connections :menu="menu" />
    </div>
  </Dialog>
</template>
