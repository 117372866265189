import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
} from "vue-router";
import * as config from "../config";
import Tran from "@/i18n/translation";
import LoginView from "@/views/LoginView.vue";
import SignupView from "@/views/SignupView.vue";
import ResetPasswordView from "@/views/ResetPasswordView.vue";
import ProvinceRoot from "@/views/Province/ProvinceRoot.vue";
import DistrictRoot from "@/views/District/DistrictRoot.vue";
import MenuRoot from "@/views/Menu/MenuRoot.vue";
import SchoolRoot from "@/views/School/SchoolRoot.vue";
import UserRoot from "@/views/User/UserRoot.vue";
import MealAttributesRoot from "@/views/MealAttributes/MealAttributesRoot.vue";
import RequestedSchoolsRoot from "@/views/RequestedSchools/RequestedSchoolsRoot.vue";
import BulletinRoot from "@/views/Bulletin/BulletinRoot.vue";
import PublicRoot from "@/views/Public/PublicRoot.vue";
import PublicDistricts from "@/views/Public/PublicDistricts.vue";
import PublicProvinces from "@/views/Public/PublicProvinces.vue";
import PublicSchools from "@/views/Public/PublicSchools.vue";
import { useUserStore } from "@/stores/UserStore";
import PublicMenus from "@/views/Public/PublicMenus.vue";
import MainNav from "@/components/MainNav.vue";
import NotFound from "@/views/NotFound.vue";
import PublicCookies from "@/views/Public/PublicCookies.vue";
import { Role } from "@/api/prisma-interfaces";
import ConnectedAppRoot from "@/views/ConnectedApp/ConnectedAppRoot.vue";
import PublicNearbySchools from "@/views/Public/PublicNearbySchools.vue";
import MealAttributesDetail from "@/views/MealAttributes/MealAttributesDetail.vue";
import PublicApiDocumentation from "@/views/Public/PublicApiDocumentation.vue";

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "PublicView",
    component: PublicRoot,
    meta: {
      layout: "public",
    },
    children: [
      {
        path: "/",
        name: "PublicProvinces",
        component: PublicProvinces,
      },
      {
        path: "p/:provinceURLName",
        name: "PublicDistricts",
        component: PublicDistricts,
        meta: {
          setsOwnTitle: true,
        },
      },
      {
        path: "d/:districtURLName",
        name: "PublicSchools",
        component: PublicSchools,
        meta: {
          setsOwnTitle: true,
        },
      },
      {
        path: "/:schoolURLName",
        name: "PublicMenus",
        component: PublicMenus,
        meta: {
          setsOwnTitle: true,
        },
      },
      {
        path: "nearby",
        name: "PublicNearbySchools",
        component: PublicNearbySchools,
      },
      {
        path: "about/cookies",
        name: "PublicCookies",
        component: PublicCookies,
      },
      {
        path: "about/api",
        name: "PublicApiDocumentation",
        component: PublicApiDocumentation,
      },
    ],
  },
  {
    path: "/login",
    name: "LoginView",
    component: LoginView,
  },
  {
    path: "/reset-password",
    name: "ResetPasswordView",
    component: ResetPasswordView,
  },
  {
    path: "/admin",
    name: "MainView",
    component: MainNav,
    meta: {
      layout: "admin",
    },
    children: [
      {
        path: "",
        name: "MenuView",
        redirect: "menu",
      },
      {
        path: "province",
        name: "ProvinceView",
        component: ProvinceRoot,
      },
      {
        path: "province/:id",
        name: "ProvinceDetail",
        component: ProvinceRoot,
      },
      {
        path: "district",
        name: "DistrictView",
        component: DistrictRoot,
      },
      {
        path: "district/:id",
        name: "DistrictDetail",
        component: DistrictRoot,
      },
      {
        path: "menu",
        name: "MenuView",
        component: MenuRoot,
      },
      {
        path: "menu/:id",
        name: "MenuDetail",
        component: MenuRoot,
      },
      {
        path: "school",
        name: "SchoolView",
        component: SchoolRoot,
      },
      {
        path: "school/:id",
        name: "SchoolDetail",
        component: SchoolRoot,
      },
      {
        path: "meal-attributes",
        name: "MealAttributesView",
        component: MealAttributesRoot,
        children: [
          {
            path: ":id",
            name: "MealAttributesDetail",
            component: MealAttributesDetail,
          },
        ],
      },
      {
        path: "requested-schools",
        name: "RequestedSchoolsView",
        component: RequestedSchoolsRoot,
      },
      {
        path: "bulletin",
        name: "BulletinView",
        component: BulletinRoot,
      },
      {
        path: "bulletin/:id",
        name: "BulletinDetail",
        component: BulletinRoot,
      },
      {
        path: "user",
        name: "UserView",
        component: UserRoot,
      },
      {
        path: "user/:id",
        name: "UserDetail",
        component: UserRoot,
      },
      {
        path: "connected-app",
        name: "ConnectedAppView",
        component: ConnectedAppRoot,
      },
      {
        path: "connected-app/:id",
        name: "ConnectedAppDetail",
        component: ConnectedAppRoot,
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFoundView",
    component: NotFound,
  },
];

if (config.nodeEnv !== "production") {
  routes.push({
    path: "/register",
    name: "SignupView",
    component: SignupView,
  });
}
const router = createRouter({
  history: createWebHistory(config.viteBaseUrl),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const shouldSetTitle = !to.meta.setsOwnTitle;
  if (shouldSetTitle) {
    // Reset the title only if the route does not have the custom meta field or if it's set to true
    document.title = config.appTitle;
  }

  const userStore = useUserStore();
  const user = await userStore.REFRESH_CURRENT_USER();

  const locale = await Tran.guessDefaultLocale();
  await Tran.switchLanguage(locale);

  if (to.path === "/province") {
    next("/");
  }

  if (to.path === "/login" && user) {
    next({ name: "MenuView" });
  }

  if (to.path === "/admin/meal-attributes" && user?.role !== Role.superAdmin) {
    router.back();
    alert("You are not authorized to view this page");
  }

  if (!user && to.path.startsWith("/admin")) {
    next({ name: "LoginView" });
  } else {
    next();
  }
});

export default router;
