import { type ConnectedApp } from "@/api/prisma-interfaces";
import ConnectedAppService, {
  type ConnectedAppDto,
} from "@/services/ConnectedAppService";
import { getErrorMessage } from "@/utils/ErrorHandler";
import { acceptHMRUpdate, defineStore } from "pinia";

interface ConnectedAppState {
  connectedApps: ConnectedApp[];
  loading: { [key: string]: boolean };
  count: number;
  error?: { [key: string]: any } | null;
}

// Actions
export const FETCH_CONNECTED_APPS = "FETCH_CONNECTED_APPS";
export const GET_CONNECTED_APP = "GET_CONNECTED_APP";
export const UPDATE_CONNECTED_APP = "UPDATE_CONNECTED_APP";
export const DELETE_CONNECTED_APP = "DELETE_CONNECTED_APP";
export const CREATE_CONNECTED_APP = "CREATE_CONNECTED_APP";

export const useConnectedAppStore = defineStore("connectedApp", {
  state: (): ConnectedAppState => ({
    connectedApps: [],
    loading: {},
    count: 0,
    error: null,
  }),
  actions: {
    async [FETCH_CONNECTED_APPS](take?: number, skip?: number) {
      this.setLoading(FETCH_CONNECTED_APPS, true);
      try {
        const page = await ConnectedAppService.getApps(take, skip);
        this.connectedApps = page.connectedApps;
        this.count = page.total;
        return page;
      } catch (error) {
        this.setError(FETCH_CONNECTED_APPS, getErrorMessage(error));
      } finally {
        this.setLoading(FETCH_CONNECTED_APPS, false);
      }
    },
    async [GET_CONNECTED_APP](id: string) {
      this.setLoading(GET_CONNECTED_APP, true);
      try {
        const connectedApp = await ConnectedAppService.getApp(id);
        return connectedApp;
      } catch (error) {
        this.setError(GET_CONNECTED_APP, getErrorMessage(error));
      } finally {
        this.setLoading(FETCH_CONNECTED_APPS, false);
      }
    },
    async [CREATE_CONNECTED_APP](connectedApp: ConnectedAppDto) {
      this.setLoading(CREATE_CONNECTED_APP, true);
      try {
        const createdApp = await ConnectedAppService.createApp(connectedApp);
        this.connectedApps.unshift(createdApp);
      } catch (error) {
        this.setError(CREATE_CONNECTED_APP, getErrorMessage(error));
      } finally {
        this.setLoading(CREATE_CONNECTED_APP, false);
      }
    },
    async [UPDATE_CONNECTED_APP](connectedApp: ConnectedAppDto) {
      this.setLoading(UPDATE_CONNECTED_APP, true);
      try {
        const updatedApp = await ConnectedAppService.updateApp(connectedApp);
        const index = this.connectedApps.findIndex(
          (p) => p.id === updatedApp.id,
        );
        if (index !== -1) {
          this.connectedApps.splice(index, 1, updatedApp);
        }
      } catch (error) {
        this.setError(UPDATE_CONNECTED_APP, getErrorMessage(error));
      } finally {
        this.setLoading(UPDATE_CONNECTED_APP, false);
      }
    },
    async [DELETE_CONNECTED_APP](id: string) {
      this.setLoading(DELETE_CONNECTED_APP, true);
      try {
        await ConnectedAppService.deleteApp(id);
        const index = this.connectedApps.findIndex((p) => p.id === id);
        if (index !== -1) {
          this.connectedApps.splice(index, 1);
        }
      } catch (error) {
        this.setError(DELETE_CONNECTED_APP, getErrorMessage(error));
      } finally {
        this.setLoading(DELETE_CONNECTED_APP, false);
      }
    },
    setLoading(action: string, value: boolean) {
      if (value) {
        this.setError(action, null);
      }
      this.loading[action] = value;
    },
    setError(action: string, value: any) {
      this.error = { ...this.error, [action]: value };
      if (value) {
        throw value;
      }
    },
  },
});

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useConnectedAppStore, import.meta.hot),
  );
}
