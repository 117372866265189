import HttpClient from "@/utils/HttpClient";
import type { Day } from "@/api/prisma-interfaces";
const MENU_URL = "4/menu";
const DAYS_URL = "days";

export interface DayDto
  extends Omit<Day, "id" | "createdAt" | "updatedAt" | "oldId"> {
  id?: Day["id"];
}

class DayService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient = new HttpClient()) {
    this.httpClient = httpClient;
  }

  async getDaysInRange(
    menuId: string,
    startDate: Date,
    endDate: Date,
  ): Promise<Day[]> {
    const response = await this.httpClient.get<Day[]>(
      `/admin/${MENU_URL}/${menuId}/${DAYS_URL}?start_date=${startDate.toISOString()}&end_date=${endDate.toISOString()}`,
    );
    return response;
  }

  async createDays(days: DayDto[]): Promise<Day[]> {
    const response = await this.httpClient.post<Day[]>(
      `/admin/${MENU_URL}/${days[0].menuId}/${DAYS_URL}`,
      {
        days,
      },
    );
    return response;
  }

  async updateDays(data: { days: DayDto[] }): Promise<Day[]> {
    const response = await this.httpClient.put<Day[]>(
      `/admin/${MENU_URL}/${data.days[0].menuId}/${DAYS_URL}`,
      data,
    );
    return response;
  }

  async deleteDays(menuId: string, dayIds: string[]): Promise<void> {
    await this.httpClient.delete(`/admin/${MENU_URL}/${menuId}/${DAYS_URL}`, {
      ids: dayIds,
    });
  }
}

export default new DayService();
