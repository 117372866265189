<script setup lang="ts">
import { reactive, onMounted, watch, provide } from "vue";
import { useRoute } from "vue-router";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Column from "primevue/column";
import Button from "primevue/button";
import { computed, ref } from "vue";
import { useFeedbackToast } from "@/composables/useFeedbackToast";
import SearchField from "@/components/shared/SearchField.vue";
import type { ConnectedApp } from "@/api/prisma-interfaces";
import { useI18n } from "vue-i18n";
import { useConnectedAppStore } from "@/stores/ConnectedAppStore";
import ConnectedAppForm from "./ConnectedAppForm.vue";
import ConnectedAppDetail from "./ConnectedAppDetail.vue";
import type { ConnectedAppDto } from "@/services/ConnectedAppService";

const { t } = useI18n();
const { useSuccessToast, useErrorToast } = useFeedbackToast();

const connectedAppStore = useConnectedAppStore();

onMounted(async () => {
  await connectedAppStore.FETCH_CONNECTED_APPS();
  connectedApps.value = connectedAppStore.connectedApps;

  if (route.params.id) {
    SELECTED_CONNECTED_APP.value =
      (await connectedAppStore.GET_CONNECTED_APP(route.params.id as string)) ||
      undefined;
  } else {
    SELECTED_CONNECTED_APP.value = undefined;
  }
});

const state = reactive({
  showForm: false,
});

const NEW_CONNECTED_APP = reactive<ConnectedAppDto>({
  name: "",
  token: "",
  isEnabled: true,
});

const resetConnectedApp = () => {
  Object.assign(NEW_CONNECTED_APP, { name: "", token: "", isEnabled: true });
};

const route = useRoute();

const SELECTED_CONNECTED_APP = ref<ConnectedApp>();

const CONNECTED_APPS = computed(() => {
  return connectedAppStore.connectedApps;
});
const first = ref(0);
const connectedApps = ref(CONNECTED_APPS.value);

const LOADING_CONNECTED_APPS = computed(() => {
  return connectedAppStore.$state.loading.FETCH_CONNECTED_APPS;
});

const CREATE_CONNECTED_APP = async () => {
  const trimmedName = NEW_CONNECTED_APP.name.trim();
  if (!trimmedName || !NEW_CONNECTED_APP.token) {
    const detail = [];
    if (!trimmedName) {
      detail.push(t("name"));
    }
    if (!NEW_CONNECTED_APP.token) {
      detail.push(t("token"));
    }
    useErrorToast(
      t("create_", { item: t("connected_app") }),
      t("empty_field_", { item: detail.join(", ") }),
    );
    return;
  }
  try {
    await connectedAppStore.CREATE_CONNECTED_APP(NEW_CONNECTED_APP);
    state.showForm = false;
    resetConnectedApp();
    useSuccessToast(t("create_", { item: t("connected_app") }));
  } catch (error) {
    useErrorToast(t("create_", { item: t("connected_app") }));
  }
};

const CREATE_CONNECTED_APP_LOADING = computed(() => {
  return connectedAppStore.$state.loading.CREATE_CONNECTED_APP;
});

function toggleModal() {
  state.showForm = !state.showForm;
}

const onPage = async (event: { first: number; rows: number }) => {
  await connectedAppStore.FETCH_CONNECTED_APPS(event.rows, event.first);
  first.value = event.first;
  connectedApps.value = connectedAppStore.connectedApps;
};

watch(
  () => route.params.id as string,
  async (id) => {
    if (route.name !== "ConnectedAppDetail") return;
    if (id) {
      SELECTED_CONNECTED_APP.value =
        (await connectedAppStore.GET_CONNECTED_APP(id as string)) || undefined;
    } else {
      SELECTED_CONNECTED_APP.value = undefined;
    }
  },
);

const resetSelectedApp = () => (SELECTED_CONNECTED_APP.value = undefined);
provide("resetSelectedApp", resetSelectedApp);
</script>

<template>
  <div class="flex flex-col">
    <div
      class="flex flex-row flex-wrap items-end justify-between gap-2 rounded-t-lg border border-slate-200 bg-gray-50 p-5"
    >
      <div class="flex-col">
        <span class="pl-2 text-sm font-semibold">{{
          $t("connected_app")
        }}</span>
        <search-field
          item-type="connected_app"
          class="rounded-md border border-surface-300"
        />
      </div>

      <Button
        class="flex justify-center font-semibold"
        @click="state.showForm = true"
      >
        <span>{{ $t("new_connected_app") }}</span>
      </Button>
    </div>
  </div>
  <div class="flex flex-row">
    <div class="w-1/3 rounded-bl-lg border border-t-0 border-slate-200">
      <data-table
        :value="connectedApps"
        lazy
        data-key="id"
        paginator
        paginator-template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        scrollable
        :scroll-height="'calc(100vh - 220px)'"
        :loading="LOADING_CONNECTED_APPS"
        :selection="SELECTED_CONNECTED_APP"
        :rows="20"
        :first="first"
        :total-records="connectedAppStore.count"
        :row-class="
          ({ id }) => [
            'cursor-pointer',
            'hover:bg-gray-100',
            id === SELECTED_CONNECTED_APP?.id
              ? ' border-l-[3px] border-l-slate-800 flex flex-col bg-gray-50'
              : '',
          ]
        "
        :row-hover="true"
        @page="onPage($event)"
        @row-click="(e) => $router.push(`/admin/connected-app/${e.data.id}`)"
      >
        <column
          field="name"
          :header="$t('connected_app', 2)"
          :header-style="{ fontWeight: 'bold', paddingTop: '18px' }"
          class="px-6"
        >
          <template #body="{ data }">
            <div class="flex justify-between">
              <span>{{ data.name }}</span>

              <div
                v-if="data.isEnabled"
                class="ml-3 flex items-center justify-center rounded-sm bg-blue-50 px-2 py-1 font-semibold"
              >
                <div class="rounded-full bg-emerald-500 p-1"></div>
                <span class="ml-2">{{ t("enabled") }}</span>
              </div>

              <div
                v-else
                class="ml-3 flex items-center justify-center rounded-sm bg-blue-50 px-2 py-1 font-semibold"
              >
                <div class="rounded-full bg-red-500 p-1"></div>
                <span class="ml-2">{{ t("disabled") }}</span>
              </div>
            </div>
          </template>
        </column>
      </data-table>
    </div>

    <div
      class="flex w-full rounded-br-lg border border-l-0 border-t-0 border-slate-200"
    >
      <connected-app-detail
        v-if="SELECTED_CONNECTED_APP != null"
        :connected-app="{ ...SELECTED_CONNECTED_APP }"
      />
      <div v-else class="mt-5 flex h-full w-full justify-center">
        <span class="text-gray-500">{{ $t("no_connected_app_selected") }}</span>
      </div>
    </div>
  </div>

  <Dialog v-model:visible="state.showForm" modal closable dismissable-mask>
    <template #header>
      <span class="font-bold text-slate-800">
        {{ $t("new_connected_app") }}
      </span>
    </template>
    <div>
      <connected-app-form
        :connected-app="NEW_CONNECTED_APP"
        @update:name="NEW_CONNECTED_APP.name = $event"
        @update:token="NEW_CONNECTED_APP.token = $event"
        @update:is-enabled="NEW_CONNECTED_APP.isEnabled = $event"
      />

      <div class="mt-5 flex justify-end gap-5">
        <Button
          severity="secondary"
          outlined
          :label="$t('cancel')"
          @click="toggleModal"
        >
        </Button>

        <Button
          :label="$t('save')"
          :loading="CREATE_CONNECTED_APP_LOADING"
          @click="CREATE_CONNECTED_APP"
        ></Button>
      </div>
    </div>
  </Dialog>
</template>
