<script setup lang="ts">
import { useMenuStore } from "@/stores/MenuStore";
import type { Menu } from "@/api/prisma-interfaces";
import { ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
import DataTable, { type DataTableRowClickEvent } from "primevue/datatable";
import Column from "primevue/column";

const props = defineProps<{
  menu: Menu;
}>();

const router = useRouter();
const menuStore = useMenuStore();

const MENU_CONNECTIONS = ref();

const fetchMenuConnections = async () => {
  MENU_CONNECTIONS.value = await menuStore.CONNECTIONS(props.menu);
};

watchEffect(async () => {
  await fetchMenuConnections();
});

function routeToDistrict(event: DataTableRowClickEvent) {
  router.push("/admin/district/" + event.data.id);
}

function routeToSchool(event: DataTableRowClickEvent) {
  router.push("/admin/school/" + event.data.id);
}

function routeToUser(event: DataTableRowClickEvent) {
  router.push("/admin/user/" + event.data.id);
}

function hasConnections() {
  return (
    MENU_CONNECTIONS.value.districts?.length ||
    MENU_CONNECTIONS.value.schools?.length ||
    MENU_CONNECTIONS.value.users?.length
  );
}
</script>

<template>
  <div v-if="hasConnections()">
    <data-table
      v-if="MENU_CONNECTIONS.districts && MENU_CONNECTIONS.districts.length > 0"
      :value="MENU_CONNECTIONS.districts"
      :row-class="() => 'cursor-pointer'"
      @row-click="routeToDistrict"
    >
      <Column
        field="name"
        :header="$t('district', 2)"
        sortable
        header-style="background-color: #F9FAFB"
        class="border-b-slate-200"
      />
    </data-table>

    <data-table
      v-if="MENU_CONNECTIONS.schools && MENU_CONNECTIONS.schools.length > 0"
      class="mt-5"
      :value="MENU_CONNECTIONS.schools"
      :row-class="() => 'cursor-pointer'"
      @row-click="routeToSchool"
    >
      <Column
        field="name"
        :header="$t('school', 2)"
        sortable
        header-style="background-color: #F9FAFB"
        class="border-b-slate-200"
      />
    </data-table>

    <data-table
      v-if="MENU_CONNECTIONS.users && MENU_CONNECTIONS.users.length > 0"
      class="mt-5"
      :value="MENU_CONNECTIONS.users"
      :row-class="() => 'cursor-pointer'"
      @row-click="routeToUser"
    >
      <Column
        field="fullName"
        :header="$t('user', 2)"
        sortable
        header-style="background-color: #F9FAFB"
        class="border-b-slate-200"
      />
    </data-table>
  </div>
  <div v-else>
    <p class="block font-medium leading-6 text-gray-900">
      {{ $t("no_connections_found") }}
    </p>
  </div>
</template>
