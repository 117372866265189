<script setup lang="ts">
import { ref, watch, onMounted } from "vue";
import InputText from "primevue/inputtext";
import SelectSearchField from "@/components/shared/SelectSearchField.vue";
import type { DistrictDto } from "@/services/DistrictService";
import type { Menu, Province } from "@/api/prisma-interfaces";
import { useMenuStore } from "@/stores/MenuStore";
import { useProvinceStore } from "@/stores/ProvinceStore";

interface DistrictForm {
  district: DistrictDto;
}

onMounted(async () => {
  menuStore.FETCH_MENUS();
  provinceStore.FETCH_PROVINCES();

  if (props.district.provinceId)
    selectedProvince.value = await provinceStore.GET_PROVINCE(
      props.district.provinceId,
    );

  if (props.district.menuId)
    selectedMenu.value = await menuStore.GET_MENU(props.district.menuId);
});

const menuStore = useMenuStore();
const provinceStore = useProvinceStore();

const props = defineProps<DistrictForm>();

const selectedMenu = ref<Menu>();
const selectedProvince = ref<Province>();

const emit = defineEmits<{
  (event: "update:name", id: string): void;
  (event: "update:selectedMenuId", id: string): void;
  (event: "update:selectedProvinceId", id: string): void;
  (event: "update:customerId", value: string | null): void;
}>();

watch(
  () => props.district,
  async (district) => {
    if (district.menuId) {
      selectedMenu.value = await menuStore.GET_MENU(district.menuId);
    } else {
      selectedMenu.value = undefined;
    }

    if (district.provinceId) {
      selectedProvince.value = await provinceStore.GET_PROVINCE(
        district.provinceId,
      );
    } else {
      selectedProvince.value = undefined;
    }
  },
);
</script>

<template>
  <form>
    <span class="block pl-2 text-sm font-semibold"
      >{{ $t("district") }} {{ $t("name") }} *
    </span>
    <input-text
      :model-value="district.name"
      :placeholder="$t('name')"
      class="w-full border-0 ring-slate-300"
      @update:model-value="$emit('update:name', $event || '')"
    />
    <div class="mt-5">
      <span class="block pl-2 text-sm font-semibold">{{ $t("menu") }}</span>
      <select-search-field
        v-model="selectedMenu"
        :search-items="menuStore.MENUS"
        item-type="menu"
        class="w-full"
        @update:model-value="
          () => {
            if (selectedMenu?.id) {
              emit('update:selectedMenuId', selectedMenu.id);
            }
          }
        "
      />
    </div>
    <div class="mt-5">
      <span class="block pl-2 text-sm font-semibold"
        >{{ $t("province") }} *</span
      >
      <select-search-field
        v-model="selectedProvince"
        :search-items="provinceStore.PROVINCES"
        item-type="province"
        class="w-full"
        @update:model-value="
          () => {
            if (selectedProvince?.id) {
              emit('update:selectedProvinceId', selectedProvince.id);
            }
          }
        "
      />
    </div>

    <div class="mt-5">
      <span class="block pl-2 text-sm font-semibold"
        >{{ $t("customer_id") }}
      </span>
      <input-text
        :model-value="district.customerId"
        :placeholder="$t('customer_id')"
        class="w-full border-0 ring-slate-300"
        @update:model-value="$emit('update:customerId', $event || null)"
      />
    </div>
  </form>
</template>
