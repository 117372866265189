<script setup lang="ts">
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import TriStateCheckbox from "primevue/tristatecheckbox";
import SelectSearchField from "@/components/shared/SelectSearchField.vue";
import { computed, ref, watch, inject } from "vue";
import { useMenuStore } from "@/stores/MenuStore";
import type { Day, WeekState } from "@/api/prisma-interfaces";
import { useDayStore } from "@/stores/DayStore";
import { useI18n } from "vue-i18n";
import { useFeedbackToast } from "@/composables/useFeedbackToast";

const menuStore = useMenuStore();
const dayStore = useDayStore();

const { locale, t } = useI18n();
const { useSuccessToast, useErrorToast } = useFeedbackToast();

const props = defineProps<{
  menuId: string;
}>();

const closeImportDialog = inject("closeImportDialog", () => {});
const refetchMenu = inject("refetchMenu", () => {});

const currentYear = new Date().getFullYear();
const YEARS = computed(() => [
  { id: currentYear - 1, value: currentYear - 1 },
  { id: currentYear, value: currentYear },
  { id: currentYear + 1, value: currentYear + 1 },
]);

const selectedYear = ref(YEARS.value[1]);
const selectedMenu = ref();
const showPreview = ref(false);
const previewData = ref<WeekState[]>();
const dayNames = ["monday", "tuesday", "wednesday", "thursday", "friday"];
const checkedWeeks = ref<Record<string, boolean | null>>({});
const checkedDays = ref<Record<string, boolean>>({});
const oldDays = ref<Day[]>();

watch(checkedDays.value, (newCheckedDays) => {
  if (!previewData.value) return;
  for (const week of previewData.value) {
    if (!week.Days) continue;
    const dayStates = week.Days.map((day) => newCheckedDays[day.id]);
    if (dayStates.every((state) => state)) {
      checkedWeeks.value[week.id] = true;
    } else if (dayStates.some((state) => state)) {
      checkedWeeks.value[week.id] = false;
    } else {
      checkedWeeks.value[week.id] = null;
    }
  }
});

watch(checkedWeeks.value, (newCheckedWeeks) => {
  if (!previewData.value) return;
  for (const week of previewData.value) {
    if (!week.Days) continue;
    if (newCheckedWeeks[week.id]) {
      for (const day of week.Days) {
        checkedDays.value[day.id] = true;
      }
    } else if (newCheckedWeeks[week.id] === null) {
      for (const day of week.Days) {
        checkedDays.value[day.id] = false;
      }
    }
  }
});

const PREVIEW_MENU_IMPORT = async () => {
  if (!selectedMenu.value || !selectedYear.value) {
    useErrorToast(t("empty_field_"), t("menu"));
    return;
  }

  try {
    previewData.value = await menuStore.GET_IMPORT_PREVIEW(
      selectedMenu.value.id,
      selectedYear.value.id,
    );

    oldDays.value = await dayStore.FETCH_DAYS_IN_RANGE({
      menuId: props.menuId,
      startDate: new Date(selectedYear.value.id, 0, 1),
      endDate: new Date(selectedYear.value.id, 11, 31),
    });
    showPreview.value = true;
  } catch (error) {
    useErrorToast(t("preview"), t("failed"));
  }
};

const PREVIEW_LOADING = computed(
  () => menuStore.$state.loading.GET_IMPORT_PREVIEW,
);

const IMPORT_FROM_MENU = async () => {
  try {
    if (!checkedWeeks.value || !checkedDays.value || !previewData.value) {
      useErrorToast(t("import"), t("no_data_selected"));
      return;
    }

    const weeks = previewData.value
      .filter((week) => checkedWeeks.value[week.id] === true)
      .map((week) => week.id);

    const days = previewData.value
      .flatMap((week) => week.Days || [])
      .filter((day) => checkedDays.value[day.id])
      .map((day) => day.id);

    if (weeks.length === 0 || days.length === 0) {
      useErrorToast(t("import"), t("no_data_selected"));
      return;
    }

    if (!props.menuId) {
      useErrorToast(t("import"), t("empty_field_", { item: t("menu") }));
      return;
    }

    if (!selectedYear.value) {
      useErrorToast(t("import"), t("year_not_selected"));
      return;
    }

    await menuStore.IMPORT_FROM_MENU(props.menuId, weeks, days);
    showPreview.value = false;
    useSuccessToast(t("import"), t("successful"));
    closeImportDialog();
    refetchMenu();
  } catch (error) {
    useErrorToast(t("import"), t("failed"));
    console.error("Import error:", error);
  }
};

const IMPORT_LOADING = computed(
  () => menuStore.$state.loading.IMPORT_FROM_MENU,
);

const allChecked = ref(false);
const toggleAllWeeks = computed({
  get() {
    return allChecked.value;
  },
  set(value) {
    allChecked.value = value;
    if (previewData.value) {
      previewData.value.forEach((week) => {
        checkedWeeks.value[week.id] = value ? true : null;
        if (week.Days) {
          week.Days.forEach((day) => {
            checkedDays.value[day.id] = value;
          });
        }
      });
    }
  },
});

watch(
  checkedWeeks,
  (newCheckedWeeks) => {
    allChecked.value = Object.values(newCheckedWeeks).every(
      (value) => value === true,
    );
  },
  { deep: true },
);
</script>

<template>
  <div>
    <span class="ml-1 mt-3 block text-sm font-semibold text-slate-800">
      {{ $t("from_another_menu") }}
    </span>
    <select-search-field
      v-model="selectedMenu"
      :search-items="menuStore.MENUS"
      item-type="menu"
      class="w-full"
    />

    <span class="ml-1 mt-3 block text-sm font-semibold text-slate-800">
      {{ $t("year") }}
    </span>
    <Dropdown
      v-model="selectedYear"
      :options="YEARS"
      option-label="value"
      :placeholder="$t('select_year')"
      class="w-full"
    />

    <div class="mt-5 flex justify-end">
      <Button
        class="flex justify-center"
        :loading="PREVIEW_LOADING"
        @click="PREVIEW_MENU_IMPORT"
      >
        <div class="flex justify-center">
          <span>{{ $t("preview") }}</span>
        </div>
      </Button>
      <Dialog
        v-model:visible="showPreview"
        modal
        header="Import Preview"
        dismissable-mask
        style="width: 80vw; max-width: 800px"
      >
        <div class="flex flex-row justify-between">
          <label>
            <Checkbox v-model="toggleAllWeeks" :binary="true" class="mr-2" />
            <span class="font-bold">{{ $t("select_all") }}</span>
          </label>
          <div>
            <Button
              class="flex w-28 justify-center"
              :loading="IMPORT_LOADING"
              :disabled="
                Object.values(checkedWeeks).every((value) => value === null)
              "
              @click="IMPORT_FROM_MENU"
            >
              <span>{{ $t("import_chosen") }}</span>
            </Button>
          </div>
        </div>

        <div v-for="week in previewData" :key="week.id" class="mb-5">
          <div class="flex items-center">
            <TriStateCheckbox
              v-model="checkedWeeks[week.id]"
              :binary="true"
              class="mr-2"
            />
            <span class="block text-lg font-bold">
              {{ $t("week") }} {{ week.week }}
            </span>
          </div>
          <div
            v-for="day in week.Days"
            :key="day.id"
            class="flex items-center justify-between border-b border-gray-100 py-2"
          >
            <div class="flex items-center">
              <Checkbox
                v-model="checkedDays[day.id]"
                :binary="true"
                class="mr-2"
              />
              <div>
                <p class="text-gray-500">
                  {{ $t(dayNames[new Date(day.date).getDay() - 1]) }}
                </p>
                <p class="pr-10 text-sm text-gray-500">
                  {{ new Date(day.date).toLocaleDateString(locale) }}
                </p>
              </div>
              <div class="flex flex-col">
                <div v-if="day.cancelled">
                  <p class="text-red-700">
                    {{ day.cancelled }}
                  </p>
                </div>
                <div v-for="meal in day.Meals" v-else :key="meal.id">
                  <p>{{ meal.name }}</p>
                </div>
              </div>
            </div>
            <span
              v-if="
                oldDays?.find((d) => {
                  return day.date === d.date;
                })
              "
              class="font-semibold"
              >{{ $t("already_exists") }}</span
            >
          </div>
        </div>
      </Dialog>
    </div>
  </div>
</template>
