import i18n from "@/i18n";
import { nextTick } from "vue";
import Flag_GB from "@/assets/flagIcons/flag_gb.svg";
import Flag_SE from "@/assets/flagIcons/flag_se.svg";

// followed this tutorial: https://lokalise.com/blog/vue-i18n/

type LocaleOption = {
  value: string;
  label: string;
  icon: string;
};

const Translation = {
  get supportedLocales(): LocaleOption[] {
    return [
      {
        value: "en",
        label: "English",
        icon: Flag_GB,
      },
      {
        value: "sv",
        label: "Svenska",
        icon: Flag_SE,
      },
    ];
  },
  set currentLocale(newLocale: string) {
    i18n.global.locale.value = newLocale;
  },
  async switchLanguage(newLocale: string) {
    await Translation.loadLocaleMessages(newLocale);
    Translation.currentLocale = newLocale;
    document.querySelector("html")?.setAttribute("lang", newLocale);
    localStorage.setItem("user-locale", newLocale);
  },
  isLocaleSupported(locale: string) {
    return Translation.supportedLocales.some((supportedLocale) =>
      supportedLocale.value.includes(locale),
    );
  },
  getUserLocale() {
    const locale: string =
      window.navigator.language || Translation.defaultLocale;
    return {
      locale: locale,
      localeNoRegion: locale.split("-")[0],
    };
  },

  getPersistedLocale() {
    const persistedLocale = localStorage.getItem("user-locale");
    if (persistedLocale && Translation.isLocaleSupported(persistedLocale)) {
      return persistedLocale;
    } else {
      return null;
    }
  },

  guessDefaultLocale() {
    const userPersistedLocale = Translation.getPersistedLocale();
    if (userPersistedLocale) {
      return userPersistedLocale;
    }
    const userPreferredLocale = Translation.getUserLocale();
    if (Translation.isLocaleSupported(userPreferredLocale.locale)) {
      return userPreferredLocale.locale;
    }
    if (Translation.isLocaleSupported(userPreferredLocale.localeNoRegion)) {
      return userPreferredLocale.localeNoRegion;
    }

    return Translation.defaultLocale;
  },
  get defaultLocale() {
    return import.meta.env.VITE_DEFAULT_LOCALE;
  },

  async loadLocaleMessages(locale: string) {
    if (!i18n.global.availableLocales.includes(locale)) {
      const messages = await import(`@/i18n/locales/${locale}.json`);
      i18n.global.setLocaleMessage(locale, messages.default);
    }

    return nextTick();
  },
};

export default Translation;
