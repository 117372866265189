export const menuPrintTemplate = `<!DOCTYPE html>
<html>
<head>
  <title>{{ schoolName }}</title>
  <style>
    body { 
      font-family: Arial, sans-serif; 
      margin: 0;
      padding: 0;
    }
    .menu-container { 
      max-width: 100%; 
      margin: 0 auto;
      font-size: {{ fontSize }};
    }
    .school-header { 
      display: flex; 
      align-items: center; 
      justify-content: space-between; 
      border-bottom: 4px solid #fbbf24; 
      padding: 2px 0; 
      margin-bottom: 8px; 
    }
    .school-name { 
      font-size: 1.2em; 
      font-weight: bold; 
      flex: 1; 
      overflow-wrap: break-word; 
      hyphens: auto; 
    }
    .school-logo { 
      height: 36px; 
      width: 36px; 
      border-radius: 50%; 
    }
    .week-title { 
      font-size: 1.1em; 
      font-weight: 600; 
      margin-bottom: 6px; 
    }
    .day-row { 
      display: flex; 
      flex-direction: row; 
      border-bottom: 1px solid #e2e8f0; 
      margin: 6px 0; 
      padding-bottom: 4px;
    }
    .day-name { 
      width: 80px; 
      margin-right: 10px; 
    }
    .day-name-text { 
      font-size: 1em; 
      font-weight: 600; 
    }
    .day-date { 
      font-size: 0.8em; 
      color: #6b7280; 
    }
    .meal-item {
      margin-bottom: 8px;
    }
    .meal-name {
      font-weight: 500;
    }
    .meal-attributes { 
      display: flex; 
      flex-wrap: wrap; 
      margin-top: 2px;
      gap: 4px;
      justify-content: flex-start;
    }
    .attribute-bubble { 
      margin: 2px 0; 
      display: inline-block;
    }
    .bulletin { 
      display: flex; 
      gap: 6px; 
      padding-bottom: 10px; 
      font-size: 0.8em;
    }
    .bulletin-image { 
      height: auto; 
      width: 80px; 
      border-radius: 4px; 
    }
    .grid-container { 
      display: grid; 
      grid-template-columns: {{ gridColumns }}; 
      gap: 10px; 
      page-break-inside: avoid;
    }
    .grid-item { 
      padding: 5px; 
    }
    .meal-content {
      flex: 1;
      margin-left: 5px;
    }
    @media print {
      @page { 
        size: portrait; 
        margin: 0.5cm; 
      }
      body { 
        margin: 0; 
        padding: 0; 
      }
      .menu-container { 
        page-break-inside: avoid; 
      }
    }
  </style>
</head>
<body>
  <div class="grid-container">
    {{ menuContent }}
  </div>
  <script>
    window.onload = function() {
      window.print();
      setTimeout(function() { window.close(); }, 500);
    };
  </script>
</body>
</html>`;
