<script setup lang="ts"></script>

<template>
  <div class="mx-10 flex justify-center">
    <div>
      <div
        class="my-3 w-full border-b-4 border-amber-400 py-1 text-2xl font-bold"
      >
        <span>{{ $t("cookies") }}</span>
      </div>
      <div>
        <div class="pb-3 text-xl font-bold">{{ $t("usage") }}</div>
        <p>
          {{ $t("cookie_usage") }}
        </p>
      </div>
      <div>
        <div class="pb-3 text-xl font-bold">{{ $t("about_cookies") }}</div>
        <p>{{ $t("about_cookies_description") }}</p>
      </div>
      <div>
        <div class="pb-3 text-xl font-bold">{{ $t("what_are_cookies") }}</div>
        <p>{{ $t("cookies_description") }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
p {
  padding-bottom: 20px;
}
</style>
