<script lang="ts" setup>
import { useBulletinStore } from "@/stores/BulletinStore";
import { watch, ref } from "vue";
import { useRoute } from "vue-router";
import { Role, type Bulletin } from "@/api/prisma-interfaces";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import BulletinDetailGeneral from "./Tabs/BulletinDetailGeneral.vue";
import BulletinDetailConnections from "./Tabs/BulletinDetailConnections.vue";
import BulletinDetailPicture from "./Tabs/BulletinDetailPicture.vue";
import type { BulletinDto } from "@/services/BulletinService";
import { useUserStore } from "@/stores/UserStore";

const route = useRoute();
const bulletinStore = useBulletinStore();
const userStore = useUserStore();

const BULLETIN = ref<Bulletin | null>(null);

watch(
  route,
  async (to) => {
    if (to.name !== "BulletinDetail") return;
    const bulletin = await bulletinStore.GET_BULLETIN(to.params.id as string);
    BULLETIN.value = bulletin || null;
  },
  { immediate: true },
);

defineProps<{
  bulletin: BulletinDto;
}>();
</script>

<template>
  <div v-if="BULLETIN" class="w-full">
    <TabView>
      <TabPanel
        v-if="
          userStore.CURRENT_USER?.canEditBulletins ||
          userStore.CURRENT_USER?.role === Role.superAdmin
        "
      >
        <template #header>
          <span class="text-sm font-semibold text-slate-800">{{
            $t("general")
          }}</span>
        </template>
        <bulletin-detail-general
          :bulletin="BULLETIN"
          class="overflow-auto px-0.5"
          style="height: calc(100vh - 275px)"
        />
      </TabPanel>
      <TabPanel
        v-if="
          userStore.CURRENT_USER?.canEditBulletins ||
          userStore.CURRENT_USER?.role === Role.superAdmin
        "
      >
        <template #header>
          <span class="text-sm font-semibold text-slate-800">{{
            $t("image")
          }}</span>
        </template>
        <bulletin-detail-picture :bulletin="BULLETIN" />
      </TabPanel>
      <TabPanel>
        <template #header>
          <span class="text-sm font-semibold text-slate-800">{{
            $t("connections")
          }}</span>
        </template>
        <bulletin-detail-connections
          :bulletin="BULLETIN"
          class="overflow-auto"
          style="height: calc(100vh - 275px)"
        />
      </TabPanel>
    </TabView>
  </div>
</template>
