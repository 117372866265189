import HttpClient from "@/utils/HttpClient";
const ORGANISATION_URL = "4/organisation";

class OrganisationService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient = new HttpClient()) {
    this.httpClient = httpClient;
  }

  async getISTSchools(
    customerId: string,
  ): Promise<{ schools: { id: string; name: string; schoolCode: string }[] }> {
    const response = await this.httpClient.get<{
      schools: { id: string; name: string; schoolCode: string }[];
    }>(`/admin/${ORGANISATION_URL}/${customerId}/schools`);
    return response;
  }
}

export default new OrganisationService();
