<script setup lang="ts">
import { useSchoolStore } from "@/stores/SchoolStore";
import { watch, ref } from "vue";
import { Role, type School } from "@/api/prisma-interfaces";
import { useRoute } from "vue-router";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import SchoolDetailGeneral from "./Tabs/SchoolDetailGeneral.vue";
import SchoolDetailConnections from "./Tabs/SchoolDetailConnections.vue";
import SchoolDetailPicture from "./Tabs/SchoolDetailPicture.vue";
import SchoolDetailLocation from "./Tabs/SchoolDetailLocation.vue";
import { useUserStore } from "@/stores/UserStore";

const route = useRoute();
const schoolStore = useSchoolStore();
const userStore = useUserStore();

const SCHOOL = ref<School | null>(null);

watch(
  route,
  async (to) => {
    if (to.name !== "SchoolDetail") return;
    const school = await schoolStore.GET_SCHOOL(to.params.id as string);
    SCHOOL.value = school || null;
  },
  { immediate: true },
);

defineProps<{
  school: School;
}>();
</script>

<template>
  <div v-if="SCHOOL" class="w-full">
    <TabView>
      <TabPanel
        v-if="
          userStore.CURRENT_USER?.canEditSchoolsAndMenus ||
          userStore.CURRENT_USER?.role === Role.superAdmin
        "
      >
        <template #header>
          <span class="text-sm font-semibold text-slate-800">{{
            $t("general")
          }}</span>
        </template>
        <school-detail-general :school="SCHOOL" />
      </TabPanel>
      <TabPanel
        v-if="
          userStore.CURRENT_USER?.canEditSchoolsAndMenus ||
          userStore.CURRENT_USER?.role === Role.superAdmin
        "
      >
        <template #header>
          <span class="text-sm font-semibold text-slate-800">{{
            $t("image")
          }}</span>
        </template>
        <school-detail-picture :school="SCHOOL" />
      </TabPanel>
      <TabPanel
        v-if="
          userStore.CURRENT_USER?.canEditSchoolsAndMenus ||
          userStore.CURRENT_USER?.role === Role.superAdmin
        "
      >
        <template #header>
          <span class="text-sm font-semibold text-slate-800">Location</span>
        </template>
        <school-detail-location :school="SCHOOL" />
      </TabPanel>
      <TabPanel>
        <template #header>
          <span class="text-sm font-semibold text-slate-800">{{
            $t("connections")
          }}</span>
        </template>
        <school-detail-connections
          :school="SCHOOL"
          class="overflow-auto"
          style="height: calc(100vh - 275px)"
        />
      </TabPanel>
    </TabView>
  </div>
</template>
