<template>
  <div
    class="h-10 w-10 animate-spin rounded-full border-4 border-gray-300 border-t-slate-800"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LoadingSpinner",
});
</script>
