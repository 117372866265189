<script setup lang="ts">
import { defineProps } from "vue";
import { buffToBase64 } from "@/utils/ImageUtils";
import { LinkIcon } from "@heroicons/vue/20/solid";
import type { Bulletin } from "@/api/prisma-interfaces";

defineProps<{ bulletin: Bulletin }>();

function formatBulletinLink(link: string | null) {
  if (link && link.trim() !== "") {
    if (!link.startsWith("http")) {
      link = `http://${link}`;
    }
    return link;
  }
  return undefined;
}
</script>

<template>
  <div class="flex flex-row gap-2 pb-5">
    <img
      v-if="bulletin.image"
      :src="buffToBase64(bulletin.image.data)"
      class="h-fit w-28 rounded-md"
    />
    <div>
      <a
        :href="formatBulletinLink(bulletin.link)"
        target="_blank"
        class="flex flex-row gap-1"
        :class="{
          'text-cyan-700': bulletin.link,
          'hover:text-cyan-600': bulletin.link,
          'cursor-pointer': bulletin.link,
        }"
      >
        <span>
          <LinkIcon
            v-if="bulletin.link && bulletin.link.trim() !== ''"
            class="mr-1 inline w-4"
          />{{ bulletin.text ? bulletin.text : bulletin.link }}</span
        >
      </a>
    </div>
  </div>
</template>
