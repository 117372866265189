import { buffToBase64 } from "@/utils/ImageUtils";
import {
  getDatesInWeek,
  getWeekDates,
  getWeekNumberByDate,
} from "@/utils/DateUtils";
import type { Bulletin, WeekState } from "@/api/prisma-interfaces";
import type { PublicMenuResponse } from "@/services/MenuService";
import { menuPrintTemplate } from "./PrintTemplate";
import DOMPurify from "dompurify";

// Weekday constants
const WEEK_DAYS = ["monday", "tuesday", "wednesday", "thursday", "friday"];

/**
 * Prints menu data for specified number of weeks
 * @param schoolId School ID to fetch menu for
 * @param baseWeekOffset Starting week offset from current week
 * @param weeksToInclude Number of weeks to include in the printout
 * @param today Reference date (usually today)
 * @param t Translation function
 * @param locale Current locale
 * @param menuStore Menu store instance
 */
export async function printMenus(
  schoolUrlName: string,
  baseWeekOffset: number,
  weeksToInclude: number = 1,
  today: Date = new Date(),
  t: (key: string) => string,
  locale: string,
  menuStore: any,
) {
  try {
    // Create a new window for printing
    const printWindow = window.open("", "_blank");
    if (!printWindow) {
      console.error(
        "Failed to open print window. Please check your popup settings.",
      );
      return;
    }

    // Get initial menu to extract school name
    const initialMenu = await menuStore.GET_PUBLIC_MENU_BY_SCHOOL_URL_NAME(
      schoolUrlName,
      today.getFullYear(),
      getWeekNumberByDate(today),
    );

    // Generate menu content for each week
    let menuContent = "";

    // Generate HTML for each week
    for (let i = 0; i < weeksToInclude; i++) {
      const currentWeekOffset = baseWeekOffset + i;
      const offsetDate = new Date(today);
      offsetDate.setDate(today.getDate() + 7 * currentWeekOffset);

      const weekData = {
        week: getWeekNumberByDate(offsetDate),
        year: offsetDate.getFullYear(),
      };

      // Fetch menu data for this week
      const weekMenu = await menuStore.GET_PUBLIC_MENU_BY_SCHOOL_URL_NAME(
        schoolUrlName,
        weekData.year,
        weekData.week,
      );

      menuContent += generateWeekMenuHtml(weekMenu, weekData, locale, t);
    }

    // Determine grid layout and font size based on number of weeks
    let gridColumns = "1fr";
    let fontSize = "16px";

    if (weeksToInclude === 2) {
      gridColumns = "repeat(2, 1fr)";
      fontSize = "14px";
    } else if (weeksToInclude === 4) {
      gridColumns = "repeat(2, 1fr)"; // 2x2 grid
      fontSize = "12px";
    }

    // Sanitize school name for the template
    const sanitizedSchoolName =
      DOMPurify.sanitize(initialMenu?.School?.name || "School Menu") ||
      "School Menu";

    // Replace placeholders in the template
    const templateHtml = menuPrintTemplate
      .replace("{{ schoolName }}", sanitizedSchoolName)
      .replace("{{ gridColumns }}", gridColumns)
      .replace("{{ fontSize }}", fontSize)
      .replace("{{ menuContent }}", menuContent);

    // Write to the new window and trigger print
    printWindow.document.write(templateHtml);
    printWindow.document.close();
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
}

/**
 * Generates HTML for a single week's menu
 */
function generateWeekMenuHtml(
  weekMenu: PublicMenuResponse | undefined,
  weekData: { week: number; year: number },
  locale: string,
  t: (key: string) => string,
): string {
  const weekDaysData = getDatesInWeek(weekData.week, weekData.year);

  // Sanitize school name
  const sanitizedSchoolName =
    DOMPurify.sanitize(weekMenu?.School?.name || "School Menu") ||
    "School Menu";

  let weekHtml = `
    <div class="grid-item">
      <div class="menu-container">
        <div class="school-header">
          <div class="school-name">${sanitizedSchoolName}</div>
          ${
            weekMenu?.School?.image
              ? `<img class="school-logo" src="${buffToBase64(
                  weekMenu.School.image.data,
                )}" />`
              : weekMenu?.School?.District?.image
              ? `<img class="school-logo" src="${buffToBase64(
                  weekMenu.School.District.image.data,
                )}" />`
              : ""
          }
        </div>
        <div class="week-title">${t("week")} ${weekData.week}</div>
  `;

  // Add days and meals
  for (const day of weekDaysData) {
    const dayIndex = findDayIndex(day, weekMenu?.WeekState ?? undefined);

    weekHtml += `
      <div class="day-row">
        <div class="day-name">
          <div class="day-name-text">${t(WEEK_DAYS[day.getDay() - 1])}</div>
          <div class="day-date">${day.toISOString().split("T")[0]}</div>
        </div>
        <div class="meal-content">
    `;

    if (dayIndex >= 0 && weekMenu?.WeekState?.Days) {
      if (weekMenu.WeekState.Days[dayIndex]?.cancelled) {
        // Sanitize cancelled message
        const sanitizedCancelMessage = DOMPurify.sanitize(
          weekMenu.WeekState.Days[dayIndex]?.cancelled || "",
        );
        weekHtml += `<div style="color: #b91c1c;">${sanitizedCancelMessage}</div>`;
      } else {
        const meals = weekMenu.WeekState.Days[dayIndex]?.Meals || [];
        for (const meal of meals) {
          // Sanitize meal name
          const sanitizedMealName = DOMPurify.sanitize(meal.name);

          weekHtml += `
            <div class="meal-item">
              <div class="meal-name">${sanitizedMealName}</div>
          `;

          if (meal.MealAttributes && meal.MealAttributes.length > 0) {
            weekHtml += `<div class="meal-attributes">`;

            for (const attribute of meal.MealAttributes) {
              // Sanitize attribute names
              const attributeName =
                locale === "sv"
                  ? DOMPurify.sanitize(attribute.sv || attribute.name)
                  : DOMPurify.sanitize(attribute.en || attribute.name);

              if (attribute.image?.data) {
                weekHtml += `
                  <div class="attribute-bubble">
                    <img src="${buffToBase64(attribute.image.data)}" 
                         title="${attributeName}" 
                         style="width: 20px; height: 20px;" />
                  </div>
                `;
              } else {
                weekHtml += `
                  <div class="attribute-bubble" 
                       style="background-color: #e2e8f0; border-radius: 9999px; padding: 1px 6px; font-size: 0.75em;">
                    ${attributeName}
                  </div>
                `;
              }
            }

            weekHtml += `</div>`;
          }

          weekHtml += `</div>`;
        }
      }
    } else {
      weekHtml += `<div style="color: #6b7280;">${t("menu_missing")}</div>`;
    }

    weekHtml += `</div></div>`;
  }

  // Add bulletin if available
  let activeBulletin;
  if (weekMenu?.Bulletin && bulletinIsActive(weekMenu.Bulletin, weekData)) {
    activeBulletin = weekMenu.Bulletin;
  } else if (
    weekMenu?.School.Bulletin &&
    bulletinIsActive(weekMenu.School.Bulletin, weekData)
  ) {
    activeBulletin = weekMenu.School.Bulletin;
  } else if (
    weekMenu?.School.District.Bulletin &&
    bulletinIsActive(weekMenu.School.District.Bulletin, weekData)
  ) {
    activeBulletin = weekMenu.School.District.Bulletin;
  }

  if (activeBulletin) {
    // Sanitize bulletin text and link
    const sanitizedBulletinText = DOMPurify.sanitize(activeBulletin.text || "");
    const sanitizedBulletinLink = DOMPurify.sanitize(activeBulletin.link || "");

    weekHtml += `
      <div class="bulletin">
        ${
          activeBulletin.image
            ? `<img class="bulletin-image" src="${buffToBase64(
                activeBulletin.image.data,
              )}" />`
            : ""
        }
        <div>${sanitizedBulletinText || sanitizedBulletinLink}</div>
      </div>
    `;
  }

  weekHtml += `</div></div>`;

  return weekHtml;
}

/**
 * Helper function to find day index in a specific week's data
 */
function findDayIndex(date: Date, weekState?: WeekState): number {
  if (!weekState || !weekState.Days?.length) return -1;

  return weekState.Days.findIndex((d) => {
    const newDate = new Date(d.date);
    return (
      newDate.getFullYear() === date.getFullYear() &&
      newDate.getMonth() === date.getMonth() &&
      newDate.getDate() === date.getDate()
    );
  });
}

/**
 * Checks if a bulletin is active for the given week
 */
function bulletinIsActive(
  bulletin?: Bulletin,
  weekData?: { week: number; year: number },
): boolean {
  if (!bulletin) return false;

  if (!bulletin?.activate && !bulletin?.deactivate) {
    return true;
  }

  if (bulletin?.activate && !bulletin?.deactivate) {
    return (
      new Date(bulletin.activate).getTime() <=
      getWeekDates(weekData!.week, weekData!.year)[1].getTime()
    );
  }

  if (!bulletin?.activate && bulletin?.deactivate) {
    return (
      new Date(bulletin.deactivate).getTime() >=
      getWeekDates(weekData!.week, weekData!.year)[0].getTime()
    );
  }

  if (bulletin?.activate && bulletin?.deactivate) {
    return (
      new Date(bulletin.activate).getTime() <=
        getWeekDates(weekData!.week, weekData!.year)[1].getTime() &&
      new Date(bulletin.deactivate).getTime() >=
        getWeekDates(weekData!.week, weekData!.year)[0].getTime()
    );
  }
  return false;
}
