<script setup lang="ts">
import { useBulletinStore } from "@/stores/BulletinStore";
import BulletinForm from "./BulletinForm.vue";
import BulletinDetail from "./BulletinDetail.vue";
import { computed, onMounted, reactive, ref, watch, provide } from "vue";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Column from "primevue/column";
import type { BulletinDto } from "@/services/BulletinService";
import { useRoute, useRouter } from "vue-router";
import SearchField from "@/components/shared/SearchField.vue";
import { useUserStore } from "@/stores/UserStore";
import { Role } from "@/api/prisma-interfaces";
import { useFeedbackToast } from "@/composables/useFeedbackToast";
import { useI18n } from "vue-i18n";

const route = useRoute();
const router = useRouter();
const userStore = useUserStore();
const bulletinStore = useBulletinStore();
const { useSuccessToast, useErrorToast } = useFeedbackToast();
const { t } = useI18n();

onMounted(async () => {
  await bulletinStore.FETCH_BULLETINS();
  bulletins.value = bulletinStore.BULLETINS;
  if (route.params.id) {
    SELECTED_BULLETIN.value =
      (await bulletinStore.GET_BULLETIN(route.params.id as string)) ||
      undefined;
  } else {
    SELECTED_BULLETIN.value = undefined;
  }
});

watch(
  () => route.params.id as string,
  async (id) => {
    if (route.name !== "BulletinDetail") return;
    if (id) {
      SELECTED_BULLETIN.value =
        (await bulletinStore.GET_BULLETIN(id)) || undefined;
    } else {
      SELECTED_BULLETIN.value = undefined;
    }
  },
);

const BULLETINS = computed(() => {
  return bulletinStore.BULLETINS;
});

const LOADING_BULLETINS = computed(() => {
  return bulletinStore.$state.loading.FETCH_BULLETINS;
});

const CREATE_BULLETIN = async () => {
  const trimmedText = NEW_BULLETIN.text?.trim();
  const trimmedLink = NEW_BULLETIN.link?.trim();
  if (!trimmedText && !trimmedLink) {
    useErrorToast(
      t("empty_field_", {
        item: t("text") + " " + t("or") + " " + t("link"),
      }),
    );
    return;
  }

  if (
    !NEW_BULLETIN.Menus?.length &&
    !NEW_BULLETIN.Schools?.length &&
    !NEW_BULLETIN.Districts?.length
  ) {
    useErrorToast(
      t("empty_field_", {
        item:
          t("menu", 2) +
          ", " +
          t("district", 2) +
          " " +
          t("or") +
          " " +
          t("school", 2),
      }),
    );
    return;
  }
  try {
    const b = await bulletinStore.CREATE_BULLETIN({
      link: trimmedLink || null,
      text: trimmedText || null,
      activate: NEW_BULLETIN.activate,
      deactivate: NEW_BULLETIN.deactivate,
      Menus: NEW_BULLETIN.Menus,
      Districts: NEW_BULLETIN.Districts,
      Schools: NEW_BULLETIN.Schools,
    });
    if (b) {
      toggleModal();
      resetBulletin();
      useSuccessToast(t("create_", { item: t("bulletin") }));
      router.push(`/admin/bulletin/${b.id}`);
    }
  } catch (error) {
    console.error(error);
    useErrorToast(t("create_", { item: t("bulletin") }));
  }
};

const NEW_BULLETIN = reactive<BulletinDto>({
  link: null,
  text: "",
  activate: null,
  deactivate: null,
  Menus: [],
  Districts: [],
  Schools: [],
});
const SELECTED_BULLETIN = ref<BulletinDto>();

const state = reactive({
  showForm: false,
  showDetails: false,
});

const toggleModal = () => {
  state.showForm = !state.showForm;
};

const resetBulletin = () => {
  Object.assign(NEW_BULLETIN, {
    link: null,
    text: "",
    activate: null,
    deactivate: null,
    Menus: [],
    Districts: [],
    Schools: [],
  });
  NEW_BULLETIN.link = "";
  NEW_BULLETIN.text = "";
  NEW_BULLETIN.activate = null;
  NEW_BULLETIN.deactivate = null;
  NEW_BULLETIN.Menus = [];
  NEW_BULLETIN.Districts = [];
  NEW_BULLETIN.Schools = [];
};

const usePaginator = ref(true);
const first = ref(0);
const bulletins = ref(BULLETINS.value);

const onPage = async (event: { first: number; rows: number }) => {
  await bulletinStore.FETCH_BULLETINS(event.rows, event.first);
  first.value = event.first;
  bulletins.value = bulletinStore.BULLETINS;
};

const resetSelectedBulletin = () => (SELECTED_BULLETIN.value = undefined);
provide("resetSelectedBulletin", resetSelectedBulletin);
</script>

<template>
  <div class="flex flex-col">
    <div
      class="flex flex-row flex-wrap items-end justify-between gap-2 rounded-t-lg border border-slate-200 bg-gray-50 p-5"
    >
      <div class="flex w-3/4 flex-col">
        <span class="pl-2 text-sm font-semibold">{{ $t("bulletin") }}</span>
        <search-field
          item-type="bulletin"
          class="rounded-md border border-surface-300"
        />
      </div>
      <div class="flex justify-end">
        <div class="flex items-end">
          <Button
            v-if="
              userStore.CURRENT_USER?.canEditBulletins ||
              userStore.CURRENT_USER?.role === Role.superAdmin
            "
            class="flex justify-center font-semibold"
            @click="state.showForm = true"
          >
            <div class="flex justify-center">
              <span>{{ $t("new_bulletin") }}</span>
            </div>
          </Button>
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-row">
    <div class="w-1/3 rounded-bl-lg border border-t-0 border-slate-200">
      <data-table
        :value="bulletins"
        lazy
        data-key="id"
        :paginator="usePaginator"
        paginator-template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
        scrollable
        :scroll-height="'calc(100vh - 220px)'"
        :loading="LOADING_BULLETINS"
        :selection="SELECTED_BULLETIN"
        :rows="usePaginator ? 20 : undefined"
        :first="usePaginator ? first : 0"
        :total-records="bulletinStore.count"
        :row-class="
          ({ id }) => [
            'cursor-pointer',
            'hover:bg-gray-100',
            id === SELECTED_BULLETIN?.id
              ? ' border-l-[3px] border-l-slate-800 flex flex-col bg-gray-50'
              : '',
          ]
        "
        :row-hover="true"
        @page="onPage($event)"
        @row-click="(e) => $router.push(`/admin/bulletin/${e.data.id}`)"
      >
        <column
          :header="$t('bulletin', 2)"
          :header-style="{ fontWeight: 'bold', paddingTop: '18px' }"
          class="px-6"
        >
          <template #body="{ data }">
            <div v-if="data.text && data.text.length > 0" class="flex flex-col">
              <span class="line-clamp-4 text-sm">{{ data.text }}</span>
              <span class="text-xs text-gray-500">{{ data.link }}</span>
            </div>
            <div v-else class="flex flex-col">
              <span class="line-clamp-4 text-sm">{{ data.link }}</span>
            </div>
          </template>
        </column>
      </data-table>
    </div>

    <div
      class="flex w-full rounded-br-lg border border-l-0 border-t-0 border-slate-200"
    >
      <bulletin-detail v-if="SELECTED_BULLETIN" :bulletin="SELECTED_BULLETIN" />
      <div v-else class="mt-5 flex h-full w-full justify-center">
        <span class="text-gray-500">{{ $t("no_bulletin_selected") }}</span>
      </div>
    </div>
  </div>

  <Dialog v-model:visible="state.showForm" modal closable dismissable-mask>
    <template #header>
      <h1 class="font-bold text-slate-800">
        {{ $t("new_bulletin") }}
      </h1></template
    >
    <div>
      <span class="block text-sm text-slate-600">{{ $t("fill_in_form") }}</span>
      <bulletin-form
        :bulletin="NEW_BULLETIN"
        class="mt-5"
        @update:text="NEW_BULLETIN.text = $event"
        @update:link="NEW_BULLETIN.link = $event"
        @update:activate="NEW_BULLETIN.activate = $event"
        @update:deactivate="NEW_BULLETIN.deactivate = $event"
        @update:menus="NEW_BULLETIN.Menus = $event"
        @update:districts="NEW_BULLETIN.Districts = $event"
        @update:schools="NEW_BULLETIN.Schools = $event"
      />

      <div class="mb-1 mt-5 flex justify-end">
        <Button
          class="mr-5 flex justify-center font-semibold"
          severity="secondary"
          outlined
          @click="toggleModal"
        >
          <div class="flex justify-center">
            <span>{{ $t("cancel") }}</span>
          </div>
        </Button>

        <Button
          class="flex justify-center font-semibold"
          @click="CREATE_BULLETIN"
        >
          <div class="flex justify-center">
            <span>{{ $t("save") }}</span>
          </div>
        </Button>
      </div>
    </div>
  </Dialog>
</template>
