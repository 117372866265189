<script setup lang="ts">
import { useUserStore } from "@/stores/UserStore";
import { reactive, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import Button from "primevue/button";
import InlineMessage from "primevue/inlinemessage";
import { useConfirm } from "primevue/useconfirm";
import { useFeedbackToast } from "@/composables/useFeedbackToast";
import { useI18n } from "vue-i18n";

const router = useRouter();
const route = useRoute();
const userStore = useUserStore();
const confirm = useConfirm();
const { t } = useI18n();
const { useSuccessToast } = useFeedbackToast();

onMounted(async () => {
  const token = route.query.token as string;
  const userId = route.query.id as string;
  try {
    await userStore.VERIFY_RESET_PASSWORD_TOKEN(token, userId);
  } catch (error) {
    errorDialog(error);
  }
});
const errorDialog = (error: any) => {
  confirm.require({
    message: error.message,
    header: "Error",
    rejectClass: "bg-slate-800 ring-0 focus:ring-orange-600 hover:bg-slate-700",
    rejectLabel: "Back to Login",
    acceptClass: "bg-slate-800 ring-0 focus:ring-orange-600 hover:bg-slate-700",
    acceptLabel: "Try Again",
    reject: async () => {
      await userStore.LOGOUT_USER();
      router.push("/login");
    },
  });
};

const state = reactive({
  newPassword: "",
  repeatPassword: "",
});

const resetPassword = async () => {
  try {
    if (state.newPassword !== state.repeatPassword) {
      throw new Error("Passwords do not match");
    }
    const token = route.query.token as string;
    const userId = route.query.id as string;
    await userStore.VERIFY_RESET_PASSWORD_TOKEN(token, userId);

    await userStore.UPDATE_USER_PASSWORD(userId, state.newPassword);
    router.push("/login");
    useSuccessToast(t("reset_pass_success"));
  } catch (error) {
    errorDialog(error);
  }
};

const UPDATE_USER_LOADING = computed(
  () => userStore.$state.loading.UPDATE_USER,
);
</script>

<template>
  <div
    class="flex min-h-screen flex-1 flex-col justify-start bg-gradient-to-r from-[#0b374a] to-[#020618] px-6 py-20 lg:px-8"
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <img
        class="mx-auto h-24 w-24"
        src="../assets/skolmaten_transparent_background.svg"
        alt="IST Logo"
      />
      <h2
        class="text-center text-2xl font-bold leading-9 tracking-tight text-white"
      >
        {{ $t("reset_your_password") }}
      </h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <form class="space-y-6" @submit.prevent="resetPassword">
        <div>
          <div class="flex items-center justify-between">
            <label
              for="newPassword"
              class="ml-2 block text-sm font-semibold leading-6 text-white"
              >{{ $t("new_password") }} *</label
            >
          </div>
          <div>
            <input
              id="newPassword"
              v-model="state.newPassword"
              name="newPassword"
              type="password"
              required="true"
              class="block w-full rounded-md border-0 py-1.5 text-slate-800 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-focus sm:text-sm sm:leading-6"
            />
          </div>
        </div>

        <div>
          <div class="flex items-center justify-between">
            <label
              for="repeatPassword"
              class="ml-2 block text-sm font-semibold leading-6 text-white"
              >{{ $t("reenter_new_password") }} *</label
            >
          </div>
          <div class="align-items-center mb-3 flex flex-wrap gap-2">
            <input
              id="repeatPassword"
              v-model="state.repeatPassword"
              name="repeatPassword"
              type="password"
              required="true"
              class="block w-full rounded-md border-0 py-1.5 text-slate-800 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-focus sm:text-sm sm:leading-6"
            />
          </div>

          <inline-message
            v-if="
              state.repeatPassword && state.newPassword !== state.repeatPassword
            "
            severity="error"
            class="bg-red-600 bg-opacity-20 text-white ring-red-800"
            >{{ $t("password_no_match") }}</inline-message
          >
          <inline-message
            v-if="state.newPassword.length > 0 && state.newPassword.length < 8"
            severity="error"
            class="bg-red-600 bg-opacity-20 text-white ring-red-800"
            >{{ $t("password_length") }}</inline-message
          >
        </div>

        <div>
          <Button
            severity="primary"
            :loading="UPDATE_USER_LOADING"
            class="flex w-full justify-center rounded-md bg-cyan-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-focus"
            @click="resetPassword"
          >
            {{ $t("save") }}
          </Button>
        </div>
      </form>
    </div>
  </div>
</template>
