import HttpClient from "../utils/HttpClient";
import { type Province } from "@/api/prisma-interfaces";
const PROVINCES_URL = "4/provinces";

export interface ProvinceDto {
  id?: string;
  name: string;
}

export interface ProvinceResponse {
  provinces: Province[];
}

class ProvinceService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient = new HttpClient()) {
    this.httpClient = httpClient;
  }

  async getProvincesForPublic() {
    const response = await this.httpClient.get<{ provinces: Province[] }>(
      `/${PROVINCES_URL}/public`,
    );
    return response.provinces;
  }

  async getProvinces(
    take?: number,
    skip?: number,
  ): Promise<{ provinces: Province[]; total: number }> {
    const response = await this.httpClient.get<{
      provinces: Province[];
      total: number;
    }>(
      skip
        ? `/${PROVINCES_URL}?take=${take ? take : "20"}&skip=${skip}`
        : `/${PROVINCES_URL}?take=${take ? take : "20"}`,
    );
    return response;
  }

  async getProvince(id: string): Promise<Province> {
    const response = await this.httpClient.get<Province>(
      `/${PROVINCES_URL}/${id}`,
    );
    return response;
  }

  async createProvince(province: ProvinceDto): Promise<Province> {
    const response = await this.httpClient.post<Province>(
      `/admin/${PROVINCES_URL}`,
      { province },
    );
    return response;
  }

  async updateProvince(data: { province: ProvinceDto }): Promise<Province> {
    const response = await this.httpClient.put<Province>(
      `/admin/${PROVINCES_URL}`,
      {
        province: {
          id: data.province.id,
          name: data.province.name,
        },
      },
    );
    return response;
  }

  async deleteProvince(id: string): Promise<void> {
    await this.httpClient.delete(`/admin/${PROVINCES_URL}/${id}`);
  }
}

export default new ProvinceService();
