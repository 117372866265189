<script setup lang="ts">
import InputText from "primevue/inputtext";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
defineProps<{ name: string }>();

defineEmits<{
  (event: "update:name", value: string): void;
}>();
</script>

<template>
  <form>
    <span class="block pl-2 text-sm font-semibold">{{ t("name") }} *</span>
    <input-text
      :model-value="name"
      :placeholder="t('name')"
      class="w-full border-0 ring-slate-300"
      @update:model-value="$emit('update:name', $event || '')"
    />
  </form>
</template>
