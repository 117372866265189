import HttpClient from "../utils/HttpClient";
import { type Bulletin } from "@/api/prisma-interfaces";
const BULLETINS_URL = "4/bulletins";

export interface BulletinDto
  extends Omit<
    Bulletin,
    "id" | "oldId" | "createdAt" | "updatedAt" | "image" | "imageUrl"
  > {
  id?: Bulletin["id"];
}

class BulletinService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient = new HttpClient()) {
    this.httpClient = httpClient;
  }

  async getBulletins(
    take?: number,
    skip?: number,
  ): Promise<{ bulletins: Bulletin[]; total: number }> {
    const response = await this.httpClient.get<{
      bulletins: Bulletin[];
      total: number;
    }>(
      `/admin/${BULLETINS_URL}?take=${take ? take : "20"}${
        skip ? `&skip=${skip}` : ""
      }`,
    );
    return response;
  }

  async getBulletin(id: string): Promise<Bulletin> {
    const response = await this.httpClient.get<Bulletin>(
      `/admin/${BULLETINS_URL}/${id}`,
    );
    return response;
  }

  async getRelatedBulletinsByIds(
    menuId: string,
    schoolId: string,
    districtId: string,
  ): Promise<Bulletin[]> {
    const response = await this.httpClient.get<Bulletin[]>(
      `/${BULLETINS_URL}?menuId=${menuId}&schoolId=${schoolId}&districtId=${districtId}`,
    );
    return response;
  }

  async createBulletin(bulletin: BulletinDto): Promise<Bulletin> {
    const response = await this.httpClient.post<Bulletin>(
      `/admin/${BULLETINS_URL}`,
      bulletin,
    );
    return response;
  }

  async updateBulletin(bulletin: BulletinDto): Promise<Bulletin> {
    const response = await this.httpClient.put<Bulletin>(
      `/admin/${BULLETINS_URL}`,
      bulletin,
    );
    return response;
  }

  async updateBulletinImage(id: string, image: FormData): Promise<Bulletin> {
    const response = await this.httpClient.put<Bulletin>(
      `/admin/${BULLETINS_URL}/${id}/image`,
      image,
    );
    return response;
  }

  async deleteBulletin(id: string): Promise<void> {
    return await this.httpClient.delete(`/admin/${BULLETINS_URL}/${id}`);
  }
}

export default new BulletinService();
