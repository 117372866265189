<script setup lang="ts">
import { useSchoolStore } from "@/stores/SchoolStore";
import { computed, ref, watch } from "vue";
import Button from "primevue/button";
import InputNumber from "primevue/inputnumber";
import type { School } from "@/api/prisma-interfaces";
import { useFeedbackToast } from "@/composables/useFeedbackToast";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  school: School;
}>();

const schoolStore = useSchoolStore();
const { useSuccessToast, useErrorToast } = useFeedbackToast();

const SCHOOL = computed(() => props.school);

watch(
  () => props.school,
  (newSchool) => {
    latitude.value = newSchool.Location?.latitude;
    longitude.value = newSchool.Location?.longitude;
  },
);

const latitude = ref(SCHOOL.value.Location?.latitude);
const longitude = ref(SCHOOL.value.Location?.longitude);

const UPDATE_SCHOOL_LOCATION = async () => {
  try {
    if (!latitude.value) {
      useErrorToast(t("empty_field_", { item: t("latitude") }));
      return;
    }
    if (!longitude.value) {
      useErrorToast(t("empty_field_", { item: t("longitude") }));
      return;
    }
    await schoolStore.UPDATE_SCHOOL_LOCATION(
      SCHOOL.value.id,
      latitude.value,
      longitude.value,
    );
    useSuccessToast(t("update_", { item: t("school") }));
  } catch (error) {
    useErrorToast(t("update_", { item: t("school") }));
  }
};

const UPDATE_SCHOOL_LOCATION_LOADING = computed(
  () => schoolStore.$state.loading.UPDATE_SCHOOL_LOCATION,
);
</script>

<template>
  <div class="flex flex-col gap-5">
    <div>
      <span class="block pl-2 text-sm font-semibold">
        {{ t("latitude") }} *
      </span>
      <input-number
        v-model="latitude"
        class="border-1 w-full text-slate-800 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-focus sm:text-sm sm:leading-6"
        :placeholder="$t('latitude')"
        show-buttons
        increment-button-class="border border-slate-300  border-l-0"
        decrement-button-class="border border-slate-300 border-t-0 border-l-0"
        :max-fraction-digits="20"
      />
    </div>
    <div>
      <span class="block pl-2 text-sm font-semibold">
        {{ t("longitude") }} *
      </span>
      <input-number
        v-model="longitude"
        class="border-1 w-full text-slate-800 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-focus sm:text-sm sm:leading-6"
        :placeholder="$t('longitude')"
        show-buttons
        increment-button-class="border border-slate-300  border-l-0"
        decrement-button-class="border border-slate-300 border-t-0 border-l-0"
        :max-fraction-digits="20"
      />
    </div>
  </div>
  <div class="mt-5 flex justify-end">
    <Button
      class="flex justify-center font-semibold"
      :loading="UPDATE_SCHOOL_LOCATION_LOADING"
      @click="UPDATE_SCHOOL_LOCATION"
    >
      <div class="flex justify-center">
        <span>{{ t("save") }}</span>
      </div>
    </Button>
  </div>
</template>
