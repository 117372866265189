<script setup lang="ts">
import { useBulletinStore } from "@/stores/BulletinStore";
import { computed } from "vue";
import type { Bulletin } from "@/api/prisma-interfaces";
import DataTable, { type DataTableRowClickEvent } from "primevue/datatable";
import Column from "primevue/column";
import { useRouter } from "vue-router";

const props = defineProps<{ bulletin: Bulletin }>();

const router = useRouter();
const bulletinStore = useBulletinStore();

const BULLETIN_CONNECTIONS = computed(() => {
  return bulletinStore.CONNECTIONS(props.bulletin);
});

function routeToDistrict(event: DataTableRowClickEvent) {
  router.push("/admin/district/" + event.data.id);
}

function routeToSchool(event: DataTableRowClickEvent) {
  router.push("/admin/school/" + event.data.id);
}

function routeToMenu(event: DataTableRowClickEvent) {
  router.push("/admin/menu/" + event.data.id);
}

const hasConnections = () => {
  return (
    BULLETIN_CONNECTIONS.value &&
    (BULLETIN_CONNECTIONS.value.districts?.length ||
      BULLETIN_CONNECTIONS.value.schools?.length ||
      BULLETIN_CONNECTIONS.value.menus?.length)
  );
};
</script>

<template>
  <div v-if="hasConnections()">
    <data-table
      v-if="
        BULLETIN_CONNECTIONS.districts &&
        BULLETIN_CONNECTIONS.districts.length > 0
      "
      :value="BULLETIN_CONNECTIONS.districts"
      :row-class="() => 'cursor-pointer'"
      @row-click="routeToDistrict"
    >
      <Column
        field="name"
        :header="$t('district', 2)"
        sortable
        header-style="background-color: #F9FAFB"
        class="border-b-slate-200"
      />
    </data-table>

    <data-table
      v-if="
        BULLETIN_CONNECTIONS.schools && BULLETIN_CONNECTIONS.schools.length > 0
      "
      :value="BULLETIN_CONNECTIONS.schools"
      :row-class="() => 'cursor-pointer'"
      @row-click="routeToSchool"
    >
      <Column
        field="name"
        :header="$t('school', 2)"
        sortable
        header-style="background-color: #F9FAFB"
        class="border-b-slate-200"
      />
    </data-table>

    <data-table
      v-if="BULLETIN_CONNECTIONS.menus && BULLETIN_CONNECTIONS.menus.length > 0"
      :value="BULLETIN_CONNECTIONS.menus"
      :row-class="() => 'cursor-pointer'"
      @row-click="routeToMenu"
    >
      <Column
        field="name"
        :header="$t('menu', 2)"
        sortable
        header-style="background-color: #F9FAFB"
        class="border-b-slate-200"
      />
    </data-table>
  </div>
  <div v-else>
    <p class="block font-medium leading-6 text-gray-900">
      {{ $t("no_connections_found") }}
    </p>
  </div>
</template>
