import WeekStateService, {
  type WeekStateDto,
} from "@/services/WeekStateService";
import type { WeekState } from "@/api/prisma-interfaces";
import { acceptHMRUpdate, defineStore } from "pinia";
import { getErrorMessage } from "@/utils/ErrorHandler";

export interface WeekStateState {
  weekStates: WeekState[];
  loading: { [key: string]: boolean };
  error?: { [key: string]: any } | null;
}

export const FETCH_WEEK_STATES_IN_YEAR = "FETCH_WEEK_STATES_IN_YEAR";
export const FETCH_WEEK_STATE = "FETCH_WEEK_STATE";
export const FETCH_CERTAIN_WEEK_STATE_BY_MENU_ID =
  "FETCH_CERTAIN_WEEK_STATE_BY_MENU_ID";
export const UPDATE_WEEK_STATE = "UPDATE_WEEK_STATE";
export const DELETE_WEEK_STATE = "DELETE_WEEK_STATE";
export const CREATE_WEEK_STATE = "CREATE_WEEK_STATE";
export const ADD_HOLIDAYS = "ADD_HOLIDAYS";

export const WEEK_STATES = "WEEK_STATES";

export const useWeekStateStore = defineStore("weekState", {
  state: (): WeekStateState => ({
    weekStates: [],
    loading: {},
    error: null,
  }),
  actions: {
    async [FETCH_WEEK_STATES_IN_YEAR](menuId: string, year: number) {
      this.setLoading(FETCH_WEEK_STATES_IN_YEAR, true);
      try {
        const weekStates = await WeekStateService.getYearWeekStates(
          menuId,
          year,
        );
        this.weekStates = weekStates;
        return weekStates;
      } catch (error) {
        this.setError(FETCH_WEEK_STATES_IN_YEAR, getErrorMessage(error));
      } finally {
        this.setLoading(FETCH_WEEK_STATES_IN_YEAR, false);
      }
    },

    async [FETCH_WEEK_STATE](menuId: string, id: string) {
      this.setLoading(FETCH_WEEK_STATE, true);
      try {
        const weekState = await WeekStateService.getWeekStateById(menuId, id);
        return weekState;
      } catch (error) {
        this.setError(FETCH_WEEK_STATE, getErrorMessage(error));
      } finally {
        this.setLoading(FETCH_WEEK_STATE, false);
      }
    },

    async [FETCH_CERTAIN_WEEK_STATE_BY_MENU_ID](
      menuId: string,
      week: number,
      year?: number,
    ) {
      this.setLoading(FETCH_CERTAIN_WEEK_STATE_BY_MENU_ID, true);
      try {
        const weekState = await WeekStateService.getCertainWeekStateByMenuId(
          menuId,
          week,
          year,
        );
        return weekState;
      } catch (error) {
        this.setError(
          FETCH_CERTAIN_WEEK_STATE_BY_MENU_ID,
          getErrorMessage(error),
        );
      } finally {
        this.setLoading(FETCH_CERTAIN_WEEK_STATE_BY_MENU_ID, false);
      }
    },

    async [UPDATE_WEEK_STATE](weekState: WeekStateDto) {
      this.setLoading(UPDATE_WEEK_STATE, true);
      try {
        const updatedWeekState = await WeekStateService.updateWeekState({
          weekState: {
            id: weekState.id,
            menuId: weekState.menuId,
            year: weekState.year,
            week: weekState.week,
            state: weekState.state,
          },
        });
        const index = this.weekStates.findIndex(
          (ws) => ws.id === updatedWeekState.id,
        );
        if (index !== -1) {
          this.weekStates.splice(index, 1, updatedWeekState);
        }
      } catch (error) {
        this.setError(UPDATE_WEEK_STATE, getErrorMessage(error));
      } finally {
        this.setLoading(UPDATE_WEEK_STATE, false);
      }
    },

    async [DELETE_WEEK_STATE](menuId: string, id: string) {
      this.setLoading(DELETE_WEEK_STATE, true);
      try {
        await WeekStateService.deleteWeekState(menuId, id);
        const index = this.weekStates.findIndex((ws) => ws.id === id);
        if (index !== -1) {
          this.weekStates.splice(index, 1);
        }
      } catch (error) {
        this.setError(DELETE_WEEK_STATE, getErrorMessage(error));
      } finally {
        this.setLoading(DELETE_WEEK_STATE, false);
      }
    },

    async [CREATE_WEEK_STATE](weekState: WeekStateDto) {
      this.setLoading(CREATE_WEEK_STATE, true);
      try {
        const createdWeekState =
          await WeekStateService.createWeekState(weekState);
        this.weekStates.push(createdWeekState);
        return createdWeekState;
      } catch (error) {
        this.setError(CREATE_WEEK_STATE, getErrorMessage(error));
      } finally {
        this.setLoading(CREATE_WEEK_STATE, false);
      }
    },

    async [ADD_HOLIDAYS](
      menuId: string,
      startDate: Date,
      endDate: Date,
      reason: string,
    ) {
      this.setLoading(ADD_HOLIDAYS, true);
      try {
        const weekStates = await WeekStateService.addHoldays(
          menuId,
          startDate,
          endDate,
          reason,
        );
        this.weekStates.push(...weekStates);
        return weekStates;
      } catch (error) {
        this.setError(ADD_HOLIDAYS, getErrorMessage(error));
      } finally {
        this.setLoading(ADD_HOLIDAYS, false);
      }
    },

    setLoading(action: string, value: boolean) {
      if (value) {
        this.setError(action, null);
      }
      this.loading[action] = value;
    },

    setError(action: string, value: any) {
      this.error = { ...this.error, [action]: value };
      if (value) {
        throw value;
      }
    },
  },
  getters: {
    [WEEK_STATES](state: WeekStateState): WeekState[] {
      return state.weekStates;
    },
  },
});

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWeekStateStore, import.meta.hot));
}
