import HttpClient from "../utils/HttpClient";
import { type District, type Province } from "@/api/prisma-interfaces";
const DISTRICTS_URL = "4/districts";

export interface DistrictDto
  extends Omit<
    District,
    "id" | "urlName" | "createdAt" | "updatedAt" | "oldId" | "feedbackAllowed"
  > {
  id?: District["id"];
}

class DistrictService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient = new HttpClient()) {
    this.httpClient = httpClient;
  }

  async getDistricts(
    take?: number,
    skip?: number,
  ): Promise<{ districts: District[]; total: number }> {
    const response = await this.httpClient.get<{
      districts: District[];
      total: number;
    }>(
      `/admin/${DISTRICTS_URL}?take=${take ? take : "20"}${
        skip ? `&skip=${skip}` : ""
      }`,
    );
    return response;
  }

  async getDistrictsByProvinceId(
    provinceId: string,
  ): Promise<{ districts: District[]; province: Province }> {
    const response = await this.httpClient.get<{
      districts: District[];
      province: Province;
    }>(`/${DISTRICTS_URL}?province=${provinceId}`);
    return response;
  }

  async getDistrictsByProvinceUrlName(
    provinceUrlName: string,
  ): Promise<{ districts: District[]; province: Province }> {
    const response = await this.httpClient.get<{
      districts: District[];
      province: Province;
    }>(`/${DISTRICTS_URL}/${provinceUrlName}`);
    return response;
  }

  async getDistrictsCount(): Promise<number> {
    const response = await this.httpClient.get<{ count: number }>(
      `/admin/${DISTRICTS_URL}/count`,
    );
    return response.count;
  }

  async getDistrict(id: string): Promise<District> {
    const response = await this.httpClient.get<District>(
      `/admin/${DISTRICTS_URL}/${id}`,
    );
    return response;
  }

  async createDistrict(district: DistrictDto): Promise<District> {
    const response = await this.httpClient.post<District>(
      `/admin/${DISTRICTS_URL}`,
      district,
    );
    return response;
  }

  async updateDistrict(district: DistrictDto): Promise<District> {
    const response = await this.httpClient.put<District>(
      `/admin/${DISTRICTS_URL}`,
      {
        id: district.id,
        name: district.name,
        provinceId: district.provinceId,
        menuId: district.menuId,
        bulletinId: district.bulletinId,
        customerId: district.customerId,
      },
    );
    return response;
  }

  async updateDistrictImage(id: string, image: FormData): Promise<District> {
    const response = await this.httpClient.put<District>(
      `/admin/${DISTRICTS_URL}/${id}/image`,
      image,
    );
    return response;
  }

  async deleteDistrict(id: string): Promise<void> {
    return await this.httpClient.delete(`/admin/${DISTRICTS_URL}/${id}`);
  }
}

export default new DistrictService();
