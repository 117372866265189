<script setup lang="ts">
import { computed, ref, toRef, watch } from "vue";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import type { DayDto } from "@/services/DayService";
import type { Meal } from "@/api/prisma-interfaces";
import MealAttributesPicker from "@/components/shared/MealAttributesPicker.vue";
import MealAttributeBubble from "@/components/shared/MealAttributeBubble.vue";
import { ChevronDownIcon } from "@heroicons/vue/20/solid";
import { TrashIcon } from "@heroicons/vue/24/outline";
import { watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { useFeedbackToast } from "@/composables/useFeedbackToast";

const props = defineProps<{
  menuId: string;
  day: DayDto | null;
  dayName: string;
  date: Date;
  noOfItems: number;
}>();

const { locale, t } = useI18n();
const { useErrorToast } = useFeedbackToast();

const emit = defineEmits<{
  (event: "update:day", value: DayDto): void;
}>();

const tempDay = ref<DayDto>({
  menuId: props.menuId,
  weekStateId: "",
  date: props.date,
  Meals: [],
  cancelled: null,
});

const dayRef = props.day ? toRef(props, "day") : tempDay;

const resetTempDay = () => {
  tempDay.value = {
    menuId: props.menuId,
    weekStateId: "",
    date: props.date,
    Meals: [],
    cancelled: null,
  };
};

const noOfItemsRef = ref(0);

watch(
  () => props.date,
  (newDate) => {
    resetTempDay();
    const totalMeals = dayRef.value?.Meals?.length || 0;
    noOfItemsRef.value = Math.max(totalMeals, props.noOfItems);
    if (newDate && dayRef.value) {
      dayRef.value.date = newDate;
    }
  },
  { immediate: true },
);

watch(
  () => props.menuId,
  (newMenuId) => {
    if (newMenuId && dayRef.value) {
      dayRef.value.menuId = newMenuId;
    }
  },
);

watch(
  () => props.day,
  (newDay) => {
    if (newDay) {
      dayRef.value = newDay;
    } else {
      dayRef.value = {
        menuId: props.menuId,
        weekStateId: "",
        date: props.date,
        Meals: [],
        cancelled: null,
      };
    }
  },
);

const dayStatus = [
  { id: "0", value: t("open") },
  { id: "1", value: t("closed") },
];
const selectedStatus = computed<{ id: string; value: string }>({
  get: () => {
    return dayRef.value?.cancelled === null ? dayStatus[0] : dayStatus[1];
  },
  set: (value) => {
    if (dayRef.value) {
      value.id === "1"
        ? (dayRef.value.cancelled = t("day_closed"))
        : (dayRef.value.cancelled = null);
      emit("update:day", dayRef.value);
    }
  },
});

watchEffect(() => {
  const totalMeals = dayRef.value?.Meals?.length || 0;

  noOfItemsRef.value = Math.max(
    totalMeals,
    props.noOfItems,
    noOfItemsRef.value,
  );
});

const changeName = (name: string, index: number) => {
  if (!dayRef.value || !dayRef.value.Meals) {
    return;
  }
  if (dayRef.value.Meals[index]) {
    dayRef.value.Meals[index].name = name;
    dayRef.value.Meals[index].order = index;
  } else {
    const meal: Meal = {
      name,
      order: index,
      mealAttributeIds: [],
      id: "",
      createdAt: new Date(),
      updatedAt: null,
      dayId: props.day?.id || "",
    };

    for (let i = dayRef.value?.Meals.length || 0; i < index; i++) {
      dayRef.value?.Meals.push({
        name: "",
        order: i,
        mealAttributeIds: [],
        id: "",
        createdAt: new Date(),
        updatedAt: null,
        dayId: props.day?.id || "",
      });
    }
    dayRef.value?.Meals.push(meal);
  }
  emit("update:day", dayRef.value);
};

const changeCancelled = (cancelled: string) => {
  if (dayRef.value) {
    dayRef.value.cancelled = cancelled;
    emit("update:day", dayRef.value);
  }
};

const clearMeal = (index: number) => {
  if (dayRef.value?.Meals && dayRef.value.Meals[index]) {
    dayRef.value.Meals[index].name = "";
    dayRef.value.Meals[index].MealAttributes = [];
    dayRef.value.Meals[index].mealAttributeIds = [];
    emit("update:day", dayRef.value);
  }
};

let shouldAnimate = ref(false);
const addAlternative = () => {
  // Activate animation briefly. (Fix to avoid it also animating when we change week.)
  shouldAnimate.value = true;
  setTimeout(() => {
    shouldAnimate.value = false;
  }, 0);
  noOfItemsRef.value++;
};
</script>

<template>
  <div class="px-[35px] lg:px-[50px]">
    <div class="border-t border-slate-200 py-2">
      <div
        class="grid gap-2 rounded-lg p-3"
        :class="selectedStatus.id === '1' ? 'bg-neutral-100' : ''"
        style="grid-template-columns: 1fr repeat(4, minmax(0, 1fr))"
      >
        <div class="col-span-1">
          <div class="flex flex-col">
            <span class="block text-sm font-bold text-slate-800">{{
              props.dayName
            }}</span>
            <span class="block text-sm text-slate-600">
              {{ props.date.toISOString().split("T")[0] }}
            </span>
          </div>
        </div>

        <div class="col-span-3"></div>

        <div class="col-span-1">
          <div class="flex justify-end">
            <Dropdown
              v-model="selectedStatus"
              :options="dayStatus"
              :style="{
                backgroundColor: selectedStatus.id === '1' ? '#B91C1C' : '',
              }"
              option-label="value"
            >
              <template #value>
                <span :class="{ 'text-white': selectedStatus.id === '1' }">{{
                  selectedStatus.value
                }}</span>
              </template>
              <template #dropdownicon>
                <ChevronDownIcon
                  class="h-5 w-5 text-slate-800"
                  :class="{ 'text-white': selectedStatus.id === '1' }"
                />
              </template>
            </Dropdown>
          </div>
        </div>

        <div class="col-span-4 grid grid-cols-4"></div>
      </div>

      <!-- content -->
      <div :class="selectedStatus.id === '1' ? 'bg-neutral-100' : ''">
        <!-- first grid start -->

        <div class="grid-template-custom grid" style="font-size: 1rem">
          <div class="col-span-1"></div>
          <div class="col-span-1 flex flex-col">
            <div v-if="selectedStatus.id === '0'">
              <TransitionGroup name="meal-alternative" :css="shouldAnimate">
                <div v-for="(itemNo, j) in noOfItemsRef" :key="j">
                  <!-- overflow-hidden needed for grid grow animation. Tiny padding to avoid clipping.
                  When alone like this, animation seemed smoother: -->
                  <div class="overflow-hidden px-[2px]">
                    <div class="mb-5 flex flex-col">
                      <div class="flex w-full flex-col">
                        <span class="text-xs font-semibold text-slate-800"
                          >{{ t("alternative") }} {{ itemNo }}
                        </span>

                        <div class="flex flex-row items-center">
                          <input-text
                            :id="`alt-${itemNo}`"
                            :model-value="
                              dayRef?.Meals ? dayRef.Meals[j]?.name || '' : ''
                            "
                            class="w-full text-ellipsis border-0 text-slate-800 ring-slate-300"
                            @update:model-value="
                              ($event) => {
                                changeName($event ? $event : '', j);
                              }
                            "
                          />

                          <div class="flex flex-row items-center">
                            <meal-attributes-picker
                              :key="j"
                              :meal-attributes="
                                dayRef?.Meals
                                  ? dayRef.Meals[j]?.MealAttributes || []
                                  : []
                              "
                              @update:meal-attributes="
                                ($event) => {
                                  if (dayRef?.Meals && dayRef.Meals[j]) {
                                    dayRef.Meals[j].MealAttributes = $event;
                                    dayRef.Meals[j].mealAttributeIds =
                                      $event.map((a) => a.id);
                                    emit('update:day', dayRef);
                                  } else {
                                    useErrorToast(t('empty_meal_no_attribute'));
                                  }
                                }
                              "
                            />
                            <button
                              v-tooltip.top="t('clear_alternative')"
                              style="min-height: 30px"
                              @click="clearMeal(j)"
                            >
                              <TrashIcon class="mx-2 h-5 w-5 text-slate-800" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="mt-4 flex flex-row flex-wrap">
                        <div
                          v-for="attribute in dayRef?.Meals
                            ? dayRef?.Meals[j]?.MealAttributes
                            : []"
                          :key="attribute?.id"
                          class="mb-2 flex flex-row"
                        >
                          <div class="mr-1 flex items-center px-1">
                            <MealAttributeBubble
                              v-tooltip.top="
                                locale === 'sv'
                                  ? attribute.sv || attribute.name
                                  : attribute.en || attribute.name
                              "
                              small
                              :name="
                                locale === 'sv'
                                  ? attribute.sv || attribute.name
                                  : attribute.en || attribute.name
                              "
                              :img-buf="attribute.image?.data as Buffer"
                              @click="
                                {
                                  if (dayRef?.Meals) {
                                    dayRef.Meals[j].MealAttributes =
                                      dayRef.Meals[j].MealAttributes?.filter(
                                        (attr) => attr.id !== attribute.id,
                                      );
                                    dayRef.Meals[j].mealAttributeIds =
                                      dayRef.Meals[j].mealAttributeIds?.filter(
                                        (attrId) => attrId !== attribute.id,
                                      );
                                    emit('update:day', dayRef);
                                  }
                                }
                              "
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TransitionGroup>
              <button
                class="cursor-pointer text-xs font-semibold text-slate-800"
                @click="addAlternative"
              >
                {{ t("add_alternative") }} +
              </button>
            </div>
            <div v-else>
              <span class="text-xs font-semibold text-slate-800">{{
                t("closed_due_to")
              }}</span>
              <input-text
                :model-value="dayRef?.cancelled"
                :placeholder="t('day_closed')"
                class="mb-2 w-full text-ellipsis border-0 text-slate-800 ring-slate-300"
                @update:model-value="
                  ($event) => {
                    if ($event) {
                      changeCancelled($event);
                    }
                  }
                "
              />
            </div>
          </div>
          <div class="col-span-1"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
/* Custom utility for min-max middle column */
.grid-template-custom {
  grid-template-columns: minmax(5px, 1fr) minmax(15rem, min(90%, 60rem)) 1fr;
}

/* Animation for revealing newly inserted meal alternative. Uses Vue standard transition classes.
Browser does not yet support transition from height 0 to 'auto',
so trick found online is to use grid and animate grid-template-rows instead. 
Did not get grid-template-rows to work as a css-transition, so therefore it is a css-animation instead. */

@keyframes expand-row {
  from {
    grid-template-rows: 0fr;
  }
  to {
    grid-template-rows: 1fr;
  }
}

.meal-alternative-enter-active {
  display: grid;
  animation: expand-row 0.38s ease;
  transition:
    opacity 0.38s ease 40ms,
    transform 0.38s ease 15ms;
}

.meal-alternative-leave-active {
  display: grid;
  animation: expand-row 0.27s ease-in 0.01s reverse;
  animation-fill-mode: forwards;
  transition: opacity 0.2s ease;
  /*     transform 0.2s ease 8ms;
 */
}

.meal-alternative-enter-from {
  opacity: 0;
  transform: translateX(15px);
}

.meal-alternative-leave-to {
  opacity: 0;
}

/* Disable moving animation for users with reduced motion setting turned on in their browser/OS */
@media (prefers-reduced-motion: reduce) {
  .meal-alternative-enter-active {
    animation: none;
    transition: opacity 0.3s ease;
  }
  .meal-alternative-leave-active {
    animation: none;
    transition: none;
  }
}
</style>
