import MenuService, { type MenuDto } from "@/services/MenuService";
import type { District, Menu, School, User } from "@/api/prisma-interfaces";
import { acceptHMRUpdate, defineStore } from "pinia";
import UserService from "@/services/UserService";
import { getErrorMessage } from "@/utils/ErrorHandler";

export interface MenuState {
  menus: Menu[];
  loading: { [key: string]: boolean };
  count: number;
  error?: { [key: string]: any } | null;
}

export interface MenuConnections {
  districts?: District[];
  schools?: School[];
  users?: User[];
}

// Actions
export const FETCH_MENUS = "FETCH_MENUS";
export const UPDATE_MENU = "UPDATE_MENU";
export const DELETE_MENU = "DELETE_MENU";
export const CREATE_MENU = "CREATE_MENU";
export const GET_MENU = "GET_MENU";
export const GET_MENUS_BY_SCHOOL_IDS = "GET_MENUS_BY_SCHOOL_IDS";
export const GET_PUBLIC_MENU = "GET_PUBLIC_MENU";
export const GET_PUBLIC_MENU_BY_SCHOOL_URL_NAME =
  "GET_PUBLIC_MENU_BY_SCHOOL_URL_NAME";
export const GET_MENUS_BY_DISTRICT_ID = "GET_MENUS_BY_DISTRICT_ID";
export const GET_IMPORT_PREVIEW = "GET_IMPORT_PREVIEW";
export const IMPORT_FROM_MENU = "IMPORT_FROM_MENU";
export const IMPORT_FROM_FILE = "IMPORT_FROM_FILE";
export const EXPORT_TO_FILE = "EXPORT_TO_FILE";

// Getters
export const MENUS = "MENUS";
export const CONNECTIONS = "CONNECTIONS";

export const useMenuStore = defineStore("menu", {
  state: (): MenuState => ({
    menus: [],
    loading: {},
    count: 0,
    error: null,
  }),
  actions: {
    async [FETCH_MENUS](take?: number, skip?: number) {
      this.setLoading(FETCH_MENUS, true);
      try {
        const page = await MenuService.getMenus(take, skip);
        this.menus = page.menus;
        this.count = page.total;
        return page;
      } catch (error) {
        this.setError(FETCH_MENUS, getErrorMessage(error));
      } finally {
        this.setLoading(FETCH_MENUS, false);
      }
    },
    async [UPDATE_MENU](menu: MenuDto) {
      this.setLoading(UPDATE_MENU, true);
      try {
        const updatedMenu = await MenuService.updateMenu({
          menu: {
            id: menu.id,
            name: menu.name,
            numberOfItems: menu.numberOfItems,
            bulletinId: menu.bulletinId,
          },
        });
        const index = this.menus.findIndex((m) => m.id === updatedMenu.id);
        if (index !== -1) {
          this.menus.splice(index, 1, updatedMenu);
        }
      } catch (error) {
        this.setError(UPDATE_MENU, getErrorMessage(error));
      } finally {
        this.setLoading(UPDATE_MENU, false);
      }
    },
    async [DELETE_MENU](menuId: string) {
      this.setLoading(DELETE_MENU, true);
      try {
        await MenuService.deleteMenu(menuId);
        const index = this.menus.findIndex((m) => m.id === menuId);
        if (index !== -1) {
          this.menus.splice(index, 1);
        }
      } catch (error) {
        this.setError(DELETE_MENU, getErrorMessage(error));
      } finally {
        this.setLoading(DELETE_MENU, false);
      }
    },
    async [CREATE_MENU](menu: MenuDto) {
      this.setLoading(CREATE_MENU, true);
      try {
        const createdMenu = await MenuService.createMenu({
          name: menu.name,
          numberOfItems: menu.numberOfItems,
          bulletinId: menu.bulletinId,
        });
        this.menus.push(createdMenu);
        return createdMenu;
      } catch (error) {
        this.setError(CREATE_MENU, getErrorMessage(error));
      } finally {
        this.setLoading(CREATE_MENU, false);
      }
    },
    async [GET_MENU](menuId: string) {
      this.setLoading(GET_MENU, true);
      try {
        const menu = await MenuService.getMenuById(menuId);
        return menu;
      } catch (error) {
        this.setError(GET_MENU, getErrorMessage(error));
      } finally {
        this.setLoading(GET_MENU, false);
      }
    },
    async [GET_MENUS_BY_SCHOOL_IDS](menuIds: string[]) {
      this.setLoading(GET_MENUS_BY_SCHOOL_IDS, true);
      try {
        const menus = await MenuService.getMenusBySchoolIds(menuIds);
        return menus;
      } catch (error) {
        this.setError(GET_MENUS_BY_SCHOOL_IDS, getErrorMessage(error));
      } finally {
        this.setLoading(GET_MENUS_BY_SCHOOL_IDS, false);
      }
    },
    async [GET_PUBLIC_MENU](schoolId: string, year: number, week: number) {
      this.setLoading(GET_PUBLIC_MENU, true);
      try {
        const menu = await MenuService.getMenuForPublic(schoolId, year, week);
        return menu;
      } catch (error) {
        this.setError(GET_PUBLIC_MENU, getErrorMessage(error));
      } finally {
        this.setLoading(GET_PUBLIC_MENU, false);
      }
    },
    async [GET_PUBLIC_MENU_BY_SCHOOL_URL_NAME](
      schoolUrlName: string,
      year: number,
      week: number,
    ) {
      this.setLoading(GET_PUBLIC_MENU_BY_SCHOOL_URL_NAME, true);
      try {
        const menu = await MenuService.getMenuForPublicBySchoolUrlName(
          schoolUrlName,
          year,
          week,
        );
        return menu;
      } catch (error) {
        this.setError(
          GET_PUBLIC_MENU_BY_SCHOOL_URL_NAME,
          getErrorMessage(error),
        );
      } finally {
        this.setLoading(GET_PUBLIC_MENU_BY_SCHOOL_URL_NAME, false);
      }
    },
    async [GET_MENUS_BY_DISTRICT_ID](districtId: string) {
      this.setLoading(GET_MENUS_BY_DISTRICT_ID, true);
      try {
        const menus = await MenuService.getMenusByDistrictId(districtId);
        return menus;
      } catch (error) {
        this.setError(GET_MENUS_BY_DISTRICT_ID, getErrorMessage(error));
      } finally {
        this.setLoading(GET_MENUS_BY_DISTRICT_ID, false);
      }
    },
    async [GET_IMPORT_PREVIEW](menuId: string, year: number) {
      this.setLoading(GET_IMPORT_PREVIEW, true);
      try {
        const preview = await MenuService.getImportPreview(menuId, year);
        const weeks = preview.filter(
          (w) => w.Days?.length && w.Days.length > 0,
        );
        return weeks;
      } catch (error) {
        this.setError(GET_IMPORT_PREVIEW, getErrorMessage(error));
      } finally {
        this.setLoading(GET_IMPORT_PREVIEW, false);
      }
    },
    async [IMPORT_FROM_MENU](
      menuId: string,
      weekIds: string[],
      dayIds: string[],
    ) {
      this.setLoading(IMPORT_FROM_MENU, true);
      try {
        const menu = await MenuService.importFromMenu(menuId, weekIds, dayIds);
        const index = this.menus.findIndex((m) => m.id === menu.id);
        if (index !== -1) {
          this.menus.splice(index, 1, menu);
        }
        return menu;
      } catch (error) {
        this.setError(IMPORT_FROM_MENU, getErrorMessage(error));
      } finally {
        this.setLoading(IMPORT_FROM_MENU, false);
      }
    },
    async [IMPORT_FROM_FILE](menuId: string, file: File, encoding: string) {
      this.setLoading(IMPORT_FROM_FILE, true);
      try {
        const menu = await MenuService.importFromFile(menuId, file, encoding);
        const index = this.menus.findIndex((m) => m.id === menu.id);
        if (index !== -1) {
          this.menus.splice(index, 1, menu);
        }
        return menu;
      } catch (error) {
        this.setError(IMPORT_FROM_FILE, getErrorMessage(error));
      } finally {
        this.setLoading(IMPORT_FROM_FILE, false);
      }
    },
    async [EXPORT_TO_FILE](menuId: string, encoding: string, year: number) {
      this.setLoading(EXPORT_TO_FILE, true);
      try {
        const blob = await MenuService.exportToFile(menuId, encoding, year);
        return blob;
      } catch (error) {
        this.setError(EXPORT_TO_FILE, getErrorMessage(error));
      } finally {
        this.setLoading(EXPORT_TO_FILE, false);
      }
    },
    setLoading(action: string, value: boolean) {
      if (value) {
        this.setError(action, null);
      }
      this.loading[action] = value;
    },
    setError(action: string, value: any) {
      this.error = { ...this.error, [action]: value };
      if (value) {
        throw value;
      }
    },
  },
  getters: {
    [MENUS](state: MenuState) {
      return state.menus;
    },
    [CONNECTIONS](): (menu: Menu) => Promise<MenuConnections> {
      return async (menu: Menu) => {
        const users = await UserService.getUsersByMenuId(menu.id);
        return {
          districts: menu.Districts,
          schools: menu.Schools,
          users,
        };
      };
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useMenuStore, import.meta.hot));
}
