<script setup lang="ts">
import Tran from "../../i18n/translation";
import Dropdown, { type DropdownChangeEvent } from "primevue/dropdown";
import { ref } from "vue";

const supportedLocales = Tran.supportedLocales;

const dropdownModel = ref(
  supportedLocales.find((l) => l.value === Tran.guessDefaultLocale()),
);

const switchLanguage = async (event: DropdownChangeEvent) => {
  // Temporarily disable aria-live elements otherwise screenreader
  // starts speaking just because the language changed within those elements.
  // E.g because of public search bar the screen reader said "No results" when switching language
  const politeElements = document.querySelectorAll('[aria-live="polite"]');
  politeElements.forEach((el) => {
    el.classList.add("lang-switch-hide-aria-live");
  });
  try {
    // Actual language switch
    const newLocale = event.value.value;
    await Tran.switchLanguage(newLocale);
  } finally {
    // Re-enable aria-live elements
    politeElements.forEach((el) => {
      el.classList.remove("lang-switch-hide-aria-live");
    });
  }
};
</script>
<template>
  <div>
    <Dropdown
      v-model="dropdownModel"
      :options="supportedLocales"
      :aria-label="$t('choose_language')"
      option-label="label"
      @change="switchLanguage"
    >
      <template #value="{ value }">
        <div class="flex flex-row gap-2">
          <img :src="value.icon" class="h-6 w-6" aria-hidden="true" />
          <span class="sr-only md:not-sr-only">{{ value.label }}</span>
        </div>
      </template>
      <template #option="{ option }">
        <div class="flex flex-row gap-2">
          <img :src="option.icon" class="h-6 w-6" aria-hidden="true" />
          <span class="sr-only md:not-sr-only">{{ option.label }}</span>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<style>
.lang-switch-hide-aria-live {
  display: none;
}
</style>
