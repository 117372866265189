<script lang="ts" setup>
import { useUserStore } from "@/stores/UserStore";
import { computed, ref, watch } from "vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import { Role, type User } from "@/api/prisma-interfaces";
import UserDetailAccess from "./Tabs/UserDetailAccess.vue";
import UserDetailConnections from "./Tabs/UserDetailConnections.vue";

import UserDetailGeneral from "./Tabs/UserDetailGeneral.vue";
import { useRoute } from "vue-router";

defineEmits(["userUpdated", "userDeleted"]);

const route = useRoute();
const userStore = useUserStore();

watch(
  route,
  async (to) => {
    if (to.name !== "UserDetail") return;
    const user = await userStore.GET_USER(to.params.id as string);
    USER.value = user || null;
  },
  { immediate: true },
);

const hasAccess = computed(() => {
  return (
    USER.value?.id === userStore.CURRENT_USER?.id ||
    (userStore.CURRENT_USER?.canEditUsers &&
      userStore.CURRENT_USER.connectedUserIds.includes(USER.value?.id || "")) ||
    userStore.CURRENT_USER?.role === Role.superAdmin
  );
});

defineProps<{
  user: User;
}>();

const USER = ref<User | null>(null);
</script>

<template>
  <div v-if="USER" class="w-full">
    <TabView>
      <TabPanel v-if="hasAccess">
        <template #header>
          <span class="text-sm font-semibold text-slate-800">{{
            $t("general")
          }}</span>
        </template>
        <user-detail-general :user="USER" />
      </TabPanel>
      <TabPanel v-if="hasAccess">
        <template #header>
          <span class="text-sm font-semibold text-slate-800">{{
            $t("access")
          }}</span>
        </template>
        <user-detail-access
          :user="USER"
          class="overflow-auto"
          style="height: calc(100vh - 275px)"
        />
      </TabPanel>
      <TabPanel>
        <template #header>
          <span class="text-sm font-semibold text-slate-800">{{
            $t("connections")
          }}</span>
        </template>
        <user-detail-connections
          :user="USER"
          class="overflow-auto"
          style="height: calc(100vh - 275px)"
        />
      </TabPanel>
    </TabView>
  </div>
</template>
