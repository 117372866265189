import HttpClient from "@/utils/HttpClient";
import { type RequestedSchool } from "@/api/prisma-interfaces";

const REQUESTED_SCHOOLS_URL = "requested-schools";

class RequestedSchoolsService {
  private httpClient: HttpClient;
  constructor(httpClient: HttpClient = new HttpClient()) {
    this.httpClient = httpClient;
  }

  async getRequestedSchools(
    take?: number,
    skip?: number,
  ): Promise<{ requestedSchools: RequestedSchool[]; total: number }> {
    const response = await this.httpClient.get<{
      requestedSchools: RequestedSchool[];
      total: number;
    }>(
      `/admin/4/${REQUESTED_SCHOOLS_URL}?take=${take ? take : "20"}${
        skip ? `&skip=${skip}` : ""
      }`,
    );
    return response;
  }
}

export default new RequestedSchoolsService();
