<script lang="ts" setup>
import { useUserStore } from "@/stores/UserStore";
import { computed, inject } from "vue";
import UserForm from "../UserForm.vue";
import type { User } from "@/api/prisma-interfaces";
import Button from "primevue/button";
import { useConfirm } from "primevue/useconfirm";
import { useFeedbackToast } from "@/composables/useFeedbackToast";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const confirm = useConfirm();
const { t } = useI18n();
const router = useRouter();

const { useSuccessToast, useErrorToast } = useFeedbackToast();

const emit = defineEmits(["userUpdated", "userDeleted"]);

const userUpdated = () => {
  emit("userUpdated");
};

const userDeleted = () => {
  emit("userDeleted");
};

const resetSelectedUser = inject("resetSelectedUser", () => {});

const userStore = useUserStore();

const UPDATE_USER = async () => {
  try {
    await userStore.UPDATE_USER(USER.value);
    userUpdated();
    useSuccessToast(t("update_", { item: t("user") }));
  } catch (error) {
    useErrorToast(t("update_", { item: t("user") }));
  }
};

const DELETE_USER = async () => {
  if (!props.user?.id) return;
  try {
    await userStore.DELETE_USER(props.user.id);
    userDeleted();
    useSuccessToast(t("delete_", { item: t("user") }));
    resetSelectedUser();
    router.replace({ name: "UserView" });
  } catch (error) {
    useErrorToast(t("delete_", { item: t("user") }));
  }
};

const USER_RESET_PASSWORD = async () => {
  if (!props.user?.email) return;
  try {
    await userStore.FORGOT_PASSWORD(props.user.email);
    useSuccessToast(t("send_recovery_mail"));
  } catch (error) {
    useErrorToast(t("send_recovery_mail"));
  }
};

const WELCOME_EMAIL = async () => {
  if (!props.user?.id) return;
  try {
    await userStore.WELCOME_EMAIL(props.user.id);
    useSuccessToast(t("send_welcome_mail"));
  } catch (error) {
    useErrorToast(t("send_welcome_mail"));
  }
};

const UPDATED_EMAIL = async () => {
  if (!props.user?.id) return;
  try {
    await userStore.UPDATED_EMAIL(props.user.id);
    useSuccessToast(t("send_mail_updated"));
  } catch (error) {
    useErrorToast(t("send_mail_updated"));
  }
};

const UPDATE_USER_LOADING = computed(
  () => userStore.$state.loading.UPDATE_USER,
);

const DELETE_USER_LOADING = computed(
  () => userStore.$state.loading.DELETE_USER,
);

const USER_RESET_PASSWORD_LOADING = computed(
  () => userStore.$state.loading.FORGOT_PASSWORD,
);

const WELCOME_EMAIL_LOADING = computed(
  () => userStore.$state.loading.WELCOME_EMAIL,
);

const UPDATED_EMAIL_LOADING = computed(
  () => userStore.$state.loading.UPDATED_EMAIL,
);

const props = defineProps<{
  user: User;
}>();

const USER = computed(() => ({ ...props.user, password: "" }));

const confirmDeleteUser = () => {
  confirm.require({
    message: t("delete_confirm_", { item: USER.value.name }),
    header: t("delete_", { item: t("user") }),
    rejectLabel: t("cancel"),
    acceptLabel: t("delete"),
    acceptClass: "bg-red-700 hover:bg-red-600 ring-red-700 focus:ring-red-700",
    accept: () => {
      DELETE_USER();
    },
    reject: () => {},
  });
};
</script>

<template>
  <user-form
    :user="USER"
    @update:full-name="USER.fullName = $event"
    @update:email="USER.email = $event"
  />

  <div class="mt-5 flex justify-between">
    <div class="mt-2 flex justify-start space-x-4">
      <Button
        class="flex justify-center font-semibold"
        severity="secondary"
        outlined
        :loading="USER_RESET_PASSWORD_LOADING"
        @click="USER_RESET_PASSWORD"
      >
        <div class="flex justify-center text-slate-800">
          <span>{{ t("send_recovery_mail") }}</span>
        </div>
      </Button>
      <Button
        class="flex justify-center font-semibold"
        severity="secondary"
        outlined
        :loading="WELCOME_EMAIL_LOADING"
        @click="WELCOME_EMAIL"
      >
        <div class="flex justify-center text-slate-800">
          <span>{{ t("send_welcome_mail") }}</span>
        </div>
      </Button>
      <Button
        class="flex justify-center font-semibold"
        severity="secondary"
        outlined
        :loading="UPDATED_EMAIL_LOADING"
        @click="UPDATED_EMAIL"
      >
        <div class="flex justify-center text-slate-800">
          <span>{{ t("send_mail_updated") }}</span>
        </div>
      </Button>
    </div>

    <div class="mt-2 flex justify-end space-x-4">
      <Button
        class="flex justify-center bg-red-700 font-semibold"
        severity="danger"
        :loading="DELETE_USER_LOADING"
        @click="confirmDeleteUser"
      >
        <div class="flex justify-center">
          <span>{{ t("delete") }}</span>
        </div>
      </Button>
      <Button
        class="flex justify-center font-semibold"
        :loading="UPDATE_USER_LOADING"
        @click="UPDATE_USER"
      >
        <div class="flex justify-center">
          <span>{{ t("save") }}</span>
        </div>
      </Button>
    </div>
  </div>
</template>
