<script lang="ts" setup>
import { useMealAttributesStore } from "@/stores/MealAttributesStore";
import { watch, ref } from "vue";
import { useRoute } from "vue-router";
import { Role, type MealAttribute } from "@/api/prisma-interfaces";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import MealAttributesDetailGeneral from "./Tabs/MealAttributesDetailGeneral.vue";
import MealAttributesDetailPicture from "./Tabs/MealAttributesDetailPicture.vue";
import { useUserStore } from "@/stores/UserStore";
import type { MealAttributeDto } from "@/services/MealAttributesService";

defineEmits(["mealAttributesUpdated", "mealAttributesDeleted"]);

const route = useRoute();
const mealAttributesStore = useMealAttributesStore();
const userStore = useUserStore();

const MEAL_ATTRIBUTE = ref<MealAttribute | null>(null);

watch(
  route,
  async (to) => {
    if (to.name !== "MealAttributesDetail") return;
    const mealAttributes = await mealAttributesStore.FETCH_MEAL_ATTRIBUTE(
      to.params.id as string,
    );
    MEAL_ATTRIBUTE.value = mealAttributes || null;
  },
  { immediate: true },
);

defineProps<{
  mealAttribute: MealAttributeDto;
}>();
</script>

<template>
  <div v-if="MEAL_ATTRIBUTE" class="w-full">
    <TabView>
      <TabPanel v-if="userStore.CURRENT_USER?.role === Role.superAdmin">
        <template #header>
          <span class="text-sm font-semibold text-slate-800">{{
            $t("general")
          }}</span>
        </template>
        <meal-attributes-detail-general :meal-attribute="MEAL_ATTRIBUTE" />
      </TabPanel>
      <TabPanel v-if="userStore.CURRENT_USER?.role === Role.superAdmin">
        <template #header>
          <span class="text-sm font-semibold text-slate-800">{{
            $t("image")
          }}</span>
        </template>
        <meal-attributes-detail-picture :meal-attribute="MEAL_ATTRIBUTE" />
      </TabPanel>
    </TabView>
  </div>
</template>
