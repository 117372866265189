import { type District, type Province } from "@/api/prisma-interfaces";
import ProvinceService, { type ProvinceDto } from "@/services/ProvinceService";
import { acceptHMRUpdate, defineStore } from "pinia";
import { useDistrictStore, DISTRICTS_BY_PROVINCE_ID } from "./DistrictStore";
import { getErrorMessage } from "@/utils/ErrorHandler";

export interface ProvinceState {
  provinces: Province[];
  loading: { [key: string]: boolean };
  count: number;
  error?: { [key: string]: any } | null;
}

// Actions
export const FETCH_PROVINCES = "FETCH_PROVINCES";
export const GET_PROVINCE = "GET_PROVINCE";
export const UPDATE_PROVINCE = "UPDATE_PROVINCE";
export const DELETE_PROVINCE = "DELETE_PROVINCE";
export const CREATE_PROVINCE = "CREATE_PROVINCE";
export const FETCH_PROVINCES_FOR_PUBLIC = "FETCH_PROVINCES_FOR_PUBLIC";

// Getters
export const PROVINCES = "PROVINCES";

export const useProvinceStore = defineStore("province", {
  state: (): ProvinceState => ({
    provinces: [],
    loading: {},
    count: 0,
    error: null,
  }),
  actions: {
    async [FETCH_PROVINCES_FOR_PUBLIC]() {
      this.setLoading(FETCH_PROVINCES_FOR_PUBLIC, true);
      try {
        const provinces = await ProvinceService.getProvincesForPublic();
        this.provinces = provinces;
      } catch (error) {
        this.setError(FETCH_PROVINCES_FOR_PUBLIC, getErrorMessage(error));
      } finally {
        this.setLoading(FETCH_PROVINCES_FOR_PUBLIC, false);
      }
    },
    async [FETCH_PROVINCES](take?: number, skip?: number) {
      this.setLoading(FETCH_PROVINCES, true);
      try {
        const page = await ProvinceService.getProvinces(take, skip);
        this.provinces = page.provinces;
        this.count = page.total;
        return page;
      } catch (error) {
        this.setError(FETCH_PROVINCES, getErrorMessage(error));
      } finally {
        this.setLoading(FETCH_PROVINCES, false);
      }
    },
    async [GET_PROVINCE](id: string) {
      this.setLoading(GET_PROVINCE, true);
      try {
        const province = await ProvinceService.getProvince(id);
        return province;
      } catch (error) {
        this.setError(GET_PROVINCE, getErrorMessage(error));
      } finally {
        this.setLoading(GET_PROVINCE, false);
      }
    },
    async [UPDATE_PROVINCE](province: ProvinceDto) {
      this.setLoading(UPDATE_PROVINCE, true);
      try {
        const updatedProvince = await ProvinceService.updateProvince({
          province,
        });
        const index = this.provinces.findIndex(
          (p) => p.id === updatedProvince.id,
        );
        if (index !== -1) {
          this.provinces.splice(index, 1, updatedProvince);
        }
      } catch (error) {
        this.setError(UPDATE_PROVINCE, getErrorMessage(error));
      } finally {
        this.setLoading(UPDATE_PROVINCE, false);
      }
    },
    async [DELETE_PROVINCE](provinceId: string) {
      this.setLoading(DELETE_PROVINCE, true);
      try {
        await ProvinceService.deleteProvince(provinceId);
        const index = this.provinces.findIndex((p) => p.id === provinceId);
        if (index !== -1) {
          this.provinces.splice(index, 1);
        }
      } catch (error) {
        this.setError(DELETE_PROVINCE, getErrorMessage(error));
      } finally {
        this.setLoading(DELETE_PROVINCE, false);
      }
    },
    async [CREATE_PROVINCE](province: ProvinceDto) {
      this.setLoading(CREATE_PROVINCE, true);
      try {
        const createdProvince = await ProvinceService.createProvince(province);
        this.provinces.unshift(createdProvince);
        return createdProvince;
      } catch (error) {
        this.setError(CREATE_PROVINCE, getErrorMessage(error));
      } finally {
        this.setLoading(CREATE_PROVINCE, false);
      }
    },
    setLoading(action: string, value: boolean) {
      if (value) {
        this.setError(action, null);
      }
      this.loading[action] = value;
    },
    setError(action: string, value: any) {
      this.error = { ...this.error, [action]: value };
      if (value) {
        throw value;
      }
    },
  },
  getters: {
    [PROVINCES](state: ProvinceState): Province[] {
      return state.provinces;
    },
    [DISTRICTS_BY_PROVINCE_ID](
      state: ProvinceState,
    ): (provinceId: string) => District[] {
      const districtsStore = useDistrictStore();
      return (provinceId: string) => {
        const province = state.provinces.find((p) => p.id === provinceId);
        if (!province) return [];
        return districtsStore.districts.filter(
          (d) => d.provinceId === province.id,
        );
      };
    },
  },
});

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProvinceStore, import.meta.hot));
}
