import HttpClient from "@/utils/HttpClient";
import type { WeekState } from "@/api/prisma-interfaces";

export interface WeekStateDto
  extends Omit<WeekState, "id" | "createdAt" | "updatedAt" | "oldId"> {
  id?: WeekState["id"];
}

class WeekStateService {
  private httpClient: HttpClient;

  constructor(httpClient: HttpClient = new HttpClient()) {
    this.httpClient = httpClient;
  }

  async getYearWeekStates(menuId: string, year: number): Promise<WeekState[]> {
    const response = await this.httpClient.get<WeekState[]>(
      `/admin/4/menu/${menuId}/weeks?year=${year}`,
    );
    return response;
  }

  async getWeekStateById(menuId: string, id: string): Promise<WeekState> {
    const response = await this.httpClient.get<WeekState>(
      `/admin/4/menu/${menuId}/weeks/${id}`,
    );
    return response;
  }

  async getCertainWeekStateByMenuId(
    menuId: string,
    week: number,
    year?: number,
  ): Promise<WeekState> {
    const response = await this.httpClient.get<WeekState>(
      year
        ? `/admin/4/menu/${menuId}/meals?week=${week}&year=${year}`
        : `/admin/4/menu/${menuId}/meals?week=${week}`,
    );
    return response;
  }

  async createWeekState(weekState: WeekStateDto): Promise<WeekState> {
    const response = await this.httpClient.post<WeekState>(
      `/admin/4/menu/${weekState.menuId}/weeks`,
      { weekState },
    );
    return response;
  }

  async updateWeekState(data: { weekState: WeekStateDto }): Promise<WeekState> {
    const response = await this.httpClient.put<WeekState>(
      `/admin/4/menu/${data.weekState.menuId}/weeks`,
      data,
    );
    return response;
  }

  async deleteWeekState(menuId: string, id: string): Promise<void> {
    await this.httpClient.delete(`/admin/4/menu/${menuId}/weeks/${id}`);
  }

  async addHoldays(
    menuId: string,
    startDate: Date,
    endDate: Date,
    reason: string,
  ): Promise<WeekState[]> {
    const response = await this.httpClient.post<WeekState[]>(
      `/admin/4/menu/${menuId}/weeks/holidays`,
      {
        startDate,
        endDate,
        reason,
      },
    );
    return response;
  }
}

export default new WeekStateService();
